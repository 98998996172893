import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import ConquestSpeed from './speed';

const ConquestEnemy = props => {
  const [sector, setSector] = useState(1);

  useEffect(() => {
    const enemySector = JSON.parse(localStorage.getItem("enemySector"));
    enemySector && setSector(enemySector);
  }, []);

  const changeSector = (v) => {
    setSector(v);
    localStorage.setItem("enemySector", JSON.stringify(v));
  }

  const SectorBtn = (props) => <button
    type="button" onClick={() => changeSector(props.val)} className={classNames('px-3 btn btn-outline-light', sector === props.val && 'active')}
  >{props.val}</button>;

  return (
    <div className="sw-conquest-enemy">
      <div className="sw-conquest-enemy__header">
        <div className="sw-conquest-enemy__description">
          <div className="sw-conquest-enemy__modifiers">
            {props.modifiers && props.modifiers.map((m, i) =>
              <div key={i}>
                <strong>{m.name}:</strong> {m.text}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sw-conquest-enemy__speed">
        <div className="sw-conquest-enemy__filters">
          <div className="form-group-label mb-0 ml-0 mr-2">Speed by sector</div>
          <div className="btn-group btn-group-sm" role="group" aria-label="Select sector">
            <SectorBtn val={1} />
            <SectorBtn val={2} />
            <SectorBtn val={3} />
            <SectorBtn val={4} />
            <SectorBtn val={5} />
          </div>
        </div>
        <ConquestSpeed
          toons={props.toons} min={props.min} sector={sector}
          max={props.max} prepared={props.prepared}
        />
      </div>
    </div>
  );
}

export default ConquestEnemy;