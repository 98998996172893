import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import Section from '../../components/section';
import SubMenu from '../../components/submenu';
import { sith, rancor, krayt } from './data/videos';

const menuLinks = [
  { label: 'Featured' },
  { label: 'Rancor' },
  { label: 'Sith' }
];

const SithRaid = () => {
  const [activeTab, selectTab] = useState(0);

  useEffect(() => {
    document.title = 'Raids - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      Hop onto a Speeder Bike on Endor, dip your toes into the sands of Tatooine in the Krayt Dragon raid, Conquer the Challenge Tier of The Rancor Pit raid or boost your Sith raid damage with these video guides.
    </div>
  </div>;

  return (
    <Page title="Raids" intro={intro}>

      <SubMenu links={menuLinks} theme='light' active={activeTab} onClick={selectTab} breakpoint={300} />

      {activeTab === 0 && <Section title="Featured" videos={krayt} />}
      {activeTab === 1 && <Section title="Challenge Rancor Pit" videos={rancor} />}
      {activeTab === 2 && <Section title="Heroic Sith" videos={sith} />}

    </Page>
  );
}

export default SithRaid;
