const day4 = [
  {
    title: `Sector 3 - Battles`,
    teams: [
      {
        id: 401,
        name: '2x',
        videoThumb: 'ncdA62rgwS0?t=117&si=e6nsp8kHlg7SMzBR',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 402,
        name: '4x',
        videoThumb: 'ncdA62rgwS0?t=165&si=IO0rQrit30QUP9wV',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
    ]
  },
  {
    title: `Sector 3 - Final Boss`,
    boss: 'enoch',
    teams: [
      {
        id: 403,
        name: 'No Support',
        videoThumb: 'His-MU1Fpmc?t=543&si=CJqrWWEffdskAr4y',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'ben', name: 'Old Ben' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      }
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Retaliate', skip: true },
      { name: 'Geonosians', skip: true },
      { name: 'Evasion Up', current: 50, target: 50 },
      { name: 'Acolyte', current: 9, target: 15 },
    ]
  },



  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 404,
        name: '4x',
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        id: 405,
        name: '4x',
        videoThumb: 'sgycYTd2Zbo?t=119&si=HWZRgNteM14DBkeH',
        feats: [
          { name: 'Tenacity Up', qty: 5 }
        ],
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC' },
          { image: 'rex', name: 'Rex', main: true },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' },
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Blight', skip: true },
      { name: 'Tenacity Up', current: 40, target: 50 },
      { name: 'Kelleran', current: 4, target: 15 },
      { name: 'Nightsisters', current: 0, target: 30 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Imperial Remnants', skip: true },
      { name: 'Gungans', skip: true },
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', current: 200, target: 250 },
      { name: 'Nightsisters', current: 16, target: 20 },
      { name: 'Master QGJ', current: 6, target: 15 },
    ]
  }
];

export {
  day4
}