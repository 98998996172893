import React from 'react';

const DatacronAccountItem = (props) => {
  const { name, isDefault, onDelete, onLoad, loadedAccount } = props

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='font-weight-bold flex-grow-1'>{isDefault ? 'Default' : name}</div>
        {!isDefault &&
          <div className='mx-2'>
            <button className="btn btn-danger btn-sm p-2" onClick={() => onDelete(name)} disabled={loadedAccount === name}>
              <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className='d-block'>
                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
              </svg>
            </button>
          </div>
        }
        <div className='ml-2'>
          <button className="btn btn-success btn-sm py-2 px-4 d-flex align-items-center" onClick={() => onLoad(name)} disabled={loadedAccount === name}>
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className='d-block mr-2'>
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
            </svg>
            Load
          </button>
        </div>
      </div>
      <hr className='border-info' />
    </>
  );
}

export default DatacronAccountItem;