import React from 'react';
import ToonDrag from './toonDrag';
import './_style.scss';

const ToonOffense = props => {

  return (
    <div className="sw-zone__offense">
      <ToonDrag
        name={props.name} assigned={props.assigned} onRemove={props.onRemove}
        image={props.image} onDrop={props.onDrop} ggImage={props.ggImage}
      />
    </div>
  );
}

export default ToonOffense;