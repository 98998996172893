import React from 'react';
import classNames from 'classnames';
import './_style.scss';

const Toon = props => {
  return (
    <div className={classNames('sw-toon', props.listItem && 'sw-toon--list', props.inlineName && 'sw-toon--inline-name')}>
      <div className={classNames('sw-toon__mask sw-icon-mask', props.listItem && 'sw-icon-mask--list', props.main && 'sw-icon-mask--main')}>
        {props.image ?
          <img src={props.ggImage ? props.ggImage : `/images/toons/${props.image}.png`} alt="" className={classNames('sw-toon__image', props.listItem && 'sw-toon__image--list')} />
        :
          <div className="sw-toon__empty">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
              <path fill="#474b52" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            </svg>
          </div>
        }
      </div>
      {props.name &&
        <div className={classNames('sw-toon__name', props.listItem && 'sw-toon__name--list', props.inlineName && 'sw-toon__name--inline-name', props.inlineName === 'auto' && 'sw-toon__name--inline-name--auto', props.main && 'sw-toon__name--main')}>{props.name}</div>
      }
      {props.icon &&
        <img src={`/icons/${props.icon}.png`} alt="" className={classNames('sw-toon__icon')} />
      }
    </div>
  );
}

export default Toon;