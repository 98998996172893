import React, { useState } from 'react';
import classNames from 'classnames';

import SwTypeAhead from '../typeahead';
import Toon from '../toon';

const renderOption = (option) => (
  <div>
    <Toon name={option.name} image={option.base_id} ggImage={option.ggImage} listItem />
  </div>
);

const AddToon = props => {
  const [toonSearch, showSearch] = useState(false);

  return (
    <div className="sw-zone__add">
      {props.max > props.toons.length &&
        <button className={classNames('btn btn-sm', props.addToonTheme ? `btn-${props.addToonTheme}` : 'btn-outline-secondary', props.zone === 'offense' && 'ml-2')} onClick={() => showSearch(true)}>{props.addToonLabel ? props.addToonLabel : 'Add Defense'}</button>
      }

      {props.max > props.toons.length && toonSearch &&
        <div className="sw-zone__backdrop" onClick={() => showSearch(false)}>
          <div className="sw-zone__search" onClick={(e) => e.stopPropagation()}>
          <div className="sw-zone__add-header">
            {props.title}
            <button className="btn btn-sm btn-link text-dark" onClick={() => showSearch(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
            <SwTypeAhead
              onChange={props.onSearchChange} placeholder="Search or select" 
              emptyLabel="No characters found" autoFocus open
              options={props.options} labelKey="name" id={`${props.zone}SearchToons`} 
              renderOption={renderOption} dropup={props.dropup}
            />
          </div>
        </div>
      }
    </div>
  );
}

export default AddToon;