import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import ConquestTeams from '../../components/conquest';
import ConquestMenu from './menu';
import { sectors } from './data/sectors';
import SubMenu from '../../components/submenu';

const menuLinks = [
  { label: 'Global' },
  { label: 'Sector 1' },
  { label: 'Sector 2' },
  { label: 'Sector 3' },
  { label: 'Sector 4' },
  { label: 'Sector 5' }
];

const intro = <div>
  <p>Team suggestions for achieving conquest sector feats. In total you can skip up to 34 keycards to secure the red crate.</p>
  <p>Key characters for feats are marked in <span className='badge rounded-pill badge-primary mx-1'>Yellow</span></p>
</div>;

const ConquestFeats = () => {
  const [selectedDay, setDay] = useState(0);
  const [active, setActive] = useState(null);

  useEffect(() => {
    document.title = 'Conquest Sector Feats - SWGOH-4 Life';
    const sectorFeats = JSON.parse(localStorage.getItem("sectorFeats"));
    sectorFeats === null ? setDay(0) : setDay(sectorFeats);
  }, []);

  const onChangeDay = (d) => {
    setDay(d);
    setActive(null);
    localStorage.setItem("sectorFeats", JSON.stringify(d));
  }

  const onChangePanel = (i) => {
    const panel = active === i ? null : i;
    setActive(panel);
  }

  return (
    <Page title="Conquest Feats" intro={intro} menu={<ConquestMenu />}>
      <div className='text-center mb-3 mb-md-4'>
        <SubMenu links={menuLinks} active={selectedDay} onClick={onChangeDay} theme='light' breakpoint={768} />
        <h2 className="h2 mb-0">{selectedDay === 0 ? 'Global Feats' : `Sector ${selectedDay} Feats`}</h2>
      </div>
      {sectors && sectors[selectedDay].map((feat, i) =>
        <ConquestTeams
          squads={feat.teams} key={i} title={feat.title}
          progress={feat.progress} text={feat.text} boss={feat.boss}
          active={active === i} feats setActive={() => onChangePanel(i)}
          keycards={feat.keycards} dataDisk={feat.dataDisk}
        />
      )}
    </Page>
  );
}

export default ConquestFeats;
