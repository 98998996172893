const day8 = [
  {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 801,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
      {
        id: 802,
        name: '2x',
        videoThumb: '',
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'shaak-ti', name: 'Shaak Ti' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Decay', skip: true },
      { name: 'Gungans', skip: true },
      { name: 'Tenacity Down', complete: true },
      { name: 'Morgan', current: 8, target: 10 }
    ]
  },


  {
    title: `Sector 3 - Datacron Farming`,
    teams: [
      {
        id: 803,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Retaliate', skip: true },
      { name: 'Geonosians', skip: true },
      { name: 'Evasion Up', complete: true },
      { name: 'Acolyte', current: 13, target: 15 },
    ]
  },




  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Imperial Remnants', skip: true },
      { name: 'Gungans', skip: true },
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true },
      { name: 'Master QGJ', current: 8, target: 15 },
    ]
  }
];

export {
  day8
}