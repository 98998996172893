import React from 'react';

const DatacronNewAccount = (props) => {
  const { setAccountName, onAddConfirm, onAddCancel, accountName, nameError } = props

  return (
    <>
      <label htmlFor="accountName">Account Name</label>
      <div className='d-flex'>
        <div className="input-group">
          <input type="text" className="form-control" id="accountName" onChange={setAccountName} />
          <div className="input-group-append">
            <button className="btn btn-sm btn-success" disabled={(!accountName || nameError)} onClick={onAddConfirm}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </button>
          </div>
        </div>
        <button className="btn btn-sm btn-link text-light" onClick={onAddCancel}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
      </div>
      {nameError &&
        <div className='alert alert-danger mt-3'>
          Account with the name of {accountName} already exists!
        </div>
      }
    </>
  );
}

export default DatacronNewAccount;