import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const PlannerTutorial = () => {
  const [hideBanner, setHide] = useState(true);

  useEffect(() => {
    const hideBanner = JSON.parse(localStorage.getItem("hideBanner"));
    hideBanner === null ? setHide(false) : setHide(hideBanner);
  }, []);

  const onHideBanner = () => {
    setHide(true);
    localStorage.setItem("hideBanner", JSON.stringify(true));
  }

  return (
    <div className={classNames('sw-banner', !hideBanner && 'sw-banner--show')}>
      <div className="bg-primary text-dark">
        <div className="container py-3">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="lead font-weight-bold mb-1 mb-md-0">New to the GAC Planner?</div>
            </div>
            <div className="col-auto ml-md-auto">
              <button className="btn btn-outline-dark" onClick={onHideBanner}>
                <div className="d-flex align-items-center justify-content-center">
                  <span>Hide</span>
                </div>
              </button>
            </div>
            <div className="col-auto pl-0">
              <a
                href="https://youtu.be/cY-KUYXK4Og" rel="noopener noreferrer"
                target="_blank" className="btn btn-dark"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <span className="icon-youtube"></span>
                  <span className="ml-2">Watch Tutorial</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PlannerTutorial;
