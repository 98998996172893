import React, { useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import './_style.scss';
import classNames from 'classnames';

const SwTypeAhead = props => {
  const ref = useRef();

  const onSelect = (value) => {
    props.onChange && props.onChange(value);
    ref.current.blur();
    !props.isSelect && ref.current.clear();
  }

  const list = props.options.filter(o => o.noCounters !== true);

  return (
    <div className={classNames('sw-typeahead', props.isSelect && props.selected.length > 0 && 'sw-typeahead--selected')}>
      <Typeahead
        onChange={onSelect} placeholder={props.placeholder} 
        clearButton={props.clearButton} options={list}
        selected={props.selected}
        labelKey={props.labelKey} id={props.id} ref={ref} open={props.open}
        renderMenuItemChildren={props.renderOption} autoFocus={props.autoFocus}
        emptyLabel={props.emptyLabel} maxHeight={props.maxHeight ? props.maxHeight : '400px'} dropup={props.dropup}
      />
    </div>
  );
}

export default SwTypeAhead;