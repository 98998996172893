import React from 'react'
import SwTypeAhead from '../../../components/typeahead'
import { renderSelected, renderOption } from './utils'

const AddDatacronSelect = (props) => {
  const { id, selected, onChange, options, label } = props

  return (
    <div className="sw-dc-modal__select">
      <SwTypeAhead
        options={options}
        id={id}
        selected={selected}
        onChange={onChange}
        placeholder={label}
        isSelect
        clearButton
        renderOption={renderOption}
        maxHeight='200px'
      />
      {selected.length > 0 && renderSelected(selected)}
    </div>
  );
}

export default AddDatacronSelect