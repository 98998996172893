import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import ConquestMenu from './menu';
import Video from '../../components/video';
import { sectors } from './data/videos';
import SubMenu from '../../components/submenu';

const menuLinks = [
  { label: 'Sector 1' },
  { label: 'Sector 2' },
  { label: 'Sector 3' },
  { label: 'Sector 4' },
  { label: 'Sector 5' },
  { label: 'Guides' }
];

const Conquest = () => {
  const [selectedTab, setTab] = useState(0);

  useEffect(() => {
    document.title = 'The Conquest - SWGOH-4 Life';
    const sectorFeats = JSON.parse(localStorage.getItem("conquestGuides"));
    sectorFeats === null ? setTab(0) : setTab(sectorFeats);
  }, []);

  const onChangeTab = (d) => {
    setTab(d);
    localStorage.setItem("conquestGuides", JSON.stringify(d));
  }

  const intro = <div className="row">
    <div className="col-12">
      A library of video guides for Conquest volume 16.
    </div>
  </div>;

  return (
    <Page title="The Conquest" intro={intro} menu={<ConquestMenu />}>

      <SubMenu links={menuLinks} active={selectedTab} onClick={onChangeTab} theme='light' breakpoint={768} />
      <h2 className="mb-5 mb-md-6 sw-heading">{selectedTab === 5 ? 'Conquest Guides' : `Sector ${selectedTab + 1}`}</h2>

      <div className="sw-feats">
        <div className="row">
          {sectors && sectors[`sector${selectedTab + 1}`].length > 0 ? sectors[`sector${selectedTab + 1}`].map((video, i) =>
            <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
              <Video
                id={video.id} name={video.name} desc={video.desc}
                url={video.url} image={video.image}
              />
            </div>
          )
            :
            <div className="px-3">
              <div className='text-center mb-5'>
                <div className="alert alert-dark mt-3 d-inline-flex">Video guides for Sector {selectedTab + 1} coming soon!</div>
              </div>
            </div>
          }
        </div>
      </div>

    </Page>
  );
}

export default Conquest;
