import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Toon from '../toon';
import Squad from '../squad';
import ConquestCountersSets from './sets';
import { disks } from '../../pages/conquest/data/disks';
import SubMenu from '../../components/submenu';

const menuLinks = [
  { label: 'Overzealous', id: 'overzealous' },
  { label: 'Stoneskin', id: 'stoneskin' },
  { label: 'Gone in 30s', id: 'gone-30' },
  { label: 'Self-destruct', id: 'self-destruct' }
];

const ConquestCounters = props => {
  const [tab, setTab] = useState(0);
  const [selected, setSelection] = useState('overzealous');
  const [counters, setCounters] = useState([]);
  const [diskSet, setDiskSet] = useState(null);

  useEffect(() => {
    const activeCounter = JSON.parse(localStorage.getItem("counquestCounterTab"));
    if(activeCounter === null) {
      setTab(0);
      loadSet(selected, props.toons);
      setSelection(menuLinks[0].id);
      localStorage.setItem("counquestCounterTab", JSON.stringify(0));
    } else {     
      let counterId = activeCounter;
      setTab(counterId);
      setSelection(menuLinks[counterId].id);
      loadSet(menuLinks[counterId].id, props.toons);
      localStorage.setItem("counquestCounterTab", JSON.stringify(counterId));
    }
  }, [selected, props.toons]);

  const loadSet = (id, toons) => {
    const set = disks.find(e => e.id === id);
    const squads = toons.find(t => t.id === id);
    setDiskSet(set);
    setCounters(squads ? squads.counters : []);
  }

  const onChangeDay = (d) => {
    setTab(d);
    setSelection(menuLinks[d].id);
    loadSet(menuLinks[d].id, props.toons);
    localStorage.setItem("counquestCounterTab", JSON.stringify(d));
  }

  return (
    <div className="sw-conquest-counters text-center">      
      <h3 className="mb-5 h2">{props.title} Counters</h3>
      {props.hasVideo &&
        <SubMenu links={menuLinks} active={tab} onClick={onChangeDay} theme='light' breakpoint={576} />
      }
      {props.hasVideo && diskSet && <ConquestCountersSets set={diskSet} />}

      <div className={classNames(!props.hasVideo && "d-flex justify-content-center")}>

        {props.hasVideo && counters && counters.length > 0 ? counters.map((t, i) =>
          <div className="sw-conquest-teams__battle" key={i}>
            <Squad toons={t.toons} videoThumb={t.video} noWrap noMargin />
          </div>)
          :
          props.hasVideo && <div className="alert alert-dark d-inline-flex">No counters with this data disk set available. Check out other data disk sets for suggested counters.</div>
        }

        {!props.hasVideo && props.toons && props.toons.map((t, i) =>
          <div className="sw-conquest-counters__toon sw-conquest-counters__toon--leader" key={i}>
            <div className="sw-conquest-counters__image">
              <Toon image={t.image} />
            </div>
          </div>
        )}
      </div>        
      {!props.hasVideo && <div className="alert alert-dark mt-3 d-inline-flex">Detailed counter guide coming soon!</div>}
    </div>
  );
}

export default ConquestCounters;