import classNames from 'classnames';
import React from 'react';

const OmicronsCell = (props) => {
  const { header, value, isSort, order, isHeader, onSort } = props

  return (
    <div
      onClick={onSort}
      className={
        classNames(
          'sw-omi-row__cell', isSort && 'sw-omi-row__cell--active',
          isHeader && 'sw-omi-row__cell--header',
          isHeader && isSort && 'sw-omi-row__cell--header--active',
          isHeader && order === 'ASC' && 'sw-omi-row__cell--header--reverse'
        )
      }
    >
      <span className={classNames('badge d-md-none', isSort ? 'badge-success' : 'badge-info')}>
        {header}
        {isSort && <span className={classNames('sw-omi-row__sort', order === 'ASC' && 'sw-omi-row__sort--reverse')} />}
      </span>
      <div>{value}</div>
    </div>
  );
}

export default OmicronsCell;