export const set17Data = [
  {
    "id": "JEDIMASTERKENOBI-ls-revive-jedi-stun-special-unit-plo-1",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_globiwan.png",
    "ggImage": true,
    "order": 1,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Defense"
    ],
    "l3": {
      "id": "ls-revive",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    },
    "l9": {
      "id": "unit-plo",
      "important": true
    }
  },
  {
    "id": "QUIGONJINN-ls-special-tm-jedi-stun-special-unit-eeth-2",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_quigon.png",
    "ggImage": true,
    "order": 2,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Critical Damage"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    },
    "l9": {
      "id": "unit-eeth",
      "important": true
    }
  },
  {
    "id": "MOFFGIDEONS3-ds-tm-defeat-imperialremnant-crit-avoid-unit-gideon-3",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_moffgideons3.png",
    "ggImage": true,
    "order": 3,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Defense"
    ],
    "l3": {
      "id": "ds-tm-defeat",
      "important": true
    },
    "l6": {
      "id": "imperialremnant-crit-avoid",
      "important": true
    },
    "l9": {
      "id": "unit-gideon",
      "important": true
    }
  },
  {
    "id": "MONMOTHMA-ls-special-tm-rogueone-stats-unit-pao-4",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_monmothma.png",
    "ggImage": true,
    "order": 4,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Tenacity"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "rogueone-stats",
      "important": true
    },
    "l9": {
      "id": "unit-pao",
      "important": true
    }
  },
  {
    "id": "SAWGERRERA-ls-special-tm-rogueone-stats-unit-luthen-5",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_sawgerrera.png",
    "ggImage": true,
    "order": 5,
    "complete": false,
    "hide": false,
    "stats": [
      "Defense",
      "Armor Penetration"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "rogueone-stats",
      "important": true
    },
    "l9": {
      "id": "unit-luthen",
      "important": true
    }
  },
  {
    "id": "ADMIRALRADDUS-ls-special-tm-rogueone-stats-unit-k2-6",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_admiralraddus.png",
    "ggImage": true,
    "order": 6,
    "complete": false,
    "hide": false,
    "stats": [
      "Defense",
      "Tenacity"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "rogueone-stats",
      "important": true
    },
    "l9": {
      "id": "unit-k2",
      "important": true
    }
  },
  {
    "id": "GRANDMASTERLUKE-ls-special-tm-jedi-stun-special-undefined-7",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_luke_jml.png",
    "ggImage": true,
    "order": 7,
    "complete": false,
    "hide": false,
    "stats": [
      "Defense",
      "Critical Damage"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    }
  },
  {
    "id": "GENERALSKYWALKER-ls-special-tm-jedi-stun-special-undefined-8",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_generalanakin.png",
    "ggImage": true,
    "order": 8,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Defense"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    }
  },
  {
    "id": "STARKILLER-ls-revive-jedi-stun-special-undefined-9",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_starkiller.png",
    "ggImage": true,
    "order": 9,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Critical Damage"
    ],
    "l3": {
      "id": "ls-revive",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    }
  },
  {
    "id": "MOTHERTALZIN-ds-tm-defeat-nightsister-max-protection-unit-zombie-10",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_nightsisters_talzin.png",
    "ggImage": true,
    "order": 10,
    "complete": false,
    "hide": false,
    "stats": [
      "Potency",
      "Defense"
    ],
    "l3": {
      "id": "ds-tm-defeat",
      "important": true
    },
    "l6": {
      "id": "nightsister-max-protection",
      "important": true
    },
    "l9": {
      "id": "unit-zombie",
      "important": true
    }
  },
  {
    "id": "LORDVADER-ds-buff-special-imperialremnant-bonus-turn-unit-stormtrooper-11",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_lordvader.png",
    "ggImage": true,
    "order": 11,
    "complete": false,
    "hide": false,
    "stats": [
      "Defense",
      "Armor Penetration"
    ],
    "l3": {
      "id": "ds-buff-special",
      "important": true
    },
    "l6": {
      "id": "imperialremnant-bonus-turn",
      "important": true
    },
    "l9": {
      "id": "unit-stormtrooper",
      "important": true
    }
  },
  {
    "id": "VEERS-ds-buff-special-imperialremnant-alert-unit-dark-trooper-12",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_veers.png",
    "ggImage": true,
    "order": 12,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Critical Damage"
    ],
    "l3": {
      "id": "ds-buff-special",
      "important": true
    },
    "l6": {
      "id": "imperialremnant-alert",
      "important": true
    },
    "l9": {
      "id": "unit-dark-trooper",
      "important": true
    }
  },
  {
    "id": "CAPTAINENOCH-ds-hp-resist-imperialremnant-alert-unit-peridea-13",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_captainenoch.png",
    "ggImage": true,
    "order": 13,
    "complete": false,
    "hide": false,
    "stats": [
      "Defense",
      "Armor Penetration"
    ],
    "l3": {
      "id": "ds-hp-resist",
      "important": true
    },
    "l6": {
      "id": "imperialremnant-alert",
      "important": true
    },
    "l9": {
      "id": "unit-peridea",
      "important": true
    }
  },
  {
    "id": "QUEENAMIDALA-ls-special-tm-jedi-stun-special-unit-master-qgj-14",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_queenamidala.png",
    "ggImage": true,
    "order": 14,
    "complete": false,
    "hide": false,
    "stats": [
      "Armor Penetration",
      "Defense"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    },
    "l9": {
      "id": "unit-master-qgj",
      "important": true
    }
  },
  {
    "id": "BADBATCHHUNTER-ls-special-tm-jedi-stun-special-unit-shaak-ti-15",
    "unit": "https://game-assets.swgoh.gg/textures/tex.charui_bb_hunter.png",
    "ggImage": true,
    "order": 15,
    "complete": false,
    "hide": false,
    "stats": [
      "Defense",
      "Armor Penetration"
    ],
    "l3": {
      "id": "ls-special-tm",
      "important": true
    },
    "l6": {
      "id": "jedi-stun-special",
      "important": true
    },
    "l9": {
      "id": "unit-shaak-ti",
      "important": true
    }
  }
]