import React from 'react';

import Squad from '../../components/squad';
import TbAlternative from './tbAlternative';
import './_style.scss';

const TbSquad = (props) => {
  const { loadouts } = props

  return (
    loadouts.map((l, i) =>
      <div className='col-12' key={i}>
        <div className='sw-tb-squad sw-conquest-teams'>
          <div className='sw-tb-squad__toons'>
            <Squad toons={l.toons} video={l.video} name={l.title} inlineName='auto' />
          </div>
          {l.alternatives && <TbAlternative loadouts={l.alternatives} />}
        </div>
      </div>
    )
  );
}

export default TbSquad;