export const sectorOne = {
  title: 'Sector 1',
  planets: [
    {
      title: 'Coruscant',
      path: 'ls',
      loadouts: [
        {
          title: 'Mace & Kit',
          video: 'rR5JBVxwdL8?t=982&si=oK_NW1xupve4gOIl',
          toons: [
            { image: 'mace', name: 'AUTO', main: true },
            { image: 'kit', main: true },
            { image: 'jmk' },
            { image: 'kam' },
            { image: 'ahsoka' }
          ],
          alternatives: [
            {
              videoThumb: 'vtOoPROo0Ho?t=793&si=AwE_B8yuaR0xfxFr',
              toons: [
                { image: 'mace', main: true },
                { image: 'kit', main: true },
                { image: 'jmk' },
                { image: 'kam' },
                { image: 'gk' }
              ]
            }
          ]
        },
        {
          title: 'Jedi',
          video: 'rR5JBVxwdL8?t=909&si=Ei7pGI4d6IGMiTrZ',
          toons: [
            { image: 'jml', name: 'AUTO' },
            { image: 'jkl' },
            { image: 'jkr' },
            { image: 'hermit' },
            { image: 'jedi-cal' }
          ],
          alternatives: [
            {
              videoThumb: 'xeOFxgNC-eI?si=yPyTcLv0-6nZswFT&t=1678',
              toons: [
                { image: 'jml' },
                { image: 'jkl' },
                { image: 'jkr' },
                { image: 'gmy' },
                { image: 'shaak-ti' }
              ]
            },
            {
              videoThumb: 'wvU3g6gjpcs?t=492&si=eNh0KSupBD45c-v_',
              toons: [
                { image: 'jml' },
                { image: 'shaak-ti' },
                { image: 'ben' },
                { image: 'ahsoka' },
                { image: 'jka' }
              ]
            }
          ]
        },
        {
          title: 'LS',
          video: 'rR5JBVxwdL8?t=778&si=OqS15dqcyngfefWc',
          toons: [
            { image: 'padme', name: 'AUTO' },
            { image: 'hunter' },
            { image: 'bb-echo' },
            { image: 'wrecker' },
            { image: 'tech' }
          ],
          alternatives: [
            {
              videoThumb: 'cuQf5cD_HoE?t=58&si=m2RJMb0Av9c9m_iM',
              toons: [
                { image: 'amidala', name: 'AUTO' },
                { image: 'kam' },
                { image: 'padawan-obi' },
                { image: 'master-qgj' },
                { image: 'shaak-ti' }
              ]
            },
            {
              videoThumb: 'xeOFxgNC-eI?si=JTK-ctfPZDv27Q6N&t=1988',
              toons: [
                { image: 'hunter' },
                { image: 'bb-echo' },
                { image: 'wrecker' },
                { image: 'omega' },
                { image: 'tech' }
              ]
            }
          ]
        },
        {
          title: 'LS',
          video: 'rR5JBVxwdL8?t=863&si=Jg5o43E8rsZKVE3v',
          toons: [
            { image: 'gl-leia', name: 'AUTO' },
            { image: 'r2' },
            { image: 'cls' },
            { image: 'drogan' },
            { image: 'captain-rex' }
          ],
          alternatives: [
            {
              videoThumb: 'xeOFxgNC-eI?si=74eBBBtcKx4gqfcG&t=2220',
              toons: [
                { image: 'rey' },
                { image: 'cal' },
                { image: 'jedi-cal' },
                { image: 'ben-solo' },
                { image: 'visas' }
              ]
            },
            {
              videoThumb: 'vtOoPROo0Ho?t=1110&si=vLGMLFDUkdGvLQw5',
              toons: [
                { image: 'mothma' },
                { image: 'kyle' },
                { image: 'pao' },
                { image: 'hoth-scout' },
                { image: 'cassian' }
              ]
            }
          ]
        },
        {
          title: 'Fleet',
          video: 'rR5JBVxwdL8?t=1075&si=xG6q1kHznsHrYq1L',
          toons: [
            { image: 'profundity', name: 'AUTO' },
            { image: 'milf' },
            { image: 'rebel-ywing' },
            { image: 'outrider', main: true },
            { image: 'biggs-xwing' }
          ],
          alternatives: [
            {
              videoThumb: 'vtOoPROo0Ho?t=1262&si=wXtCGRLqT-a9dtdi',
              toons: [
                { image: 'negotiator' },
                { image: 'ani-eta' },
                { image: 'btl-ywing' },
                { image: 'outrider', main: true },
                { image: 'plo-starfighter' }
              ]
            },
            {
              videoThumb: 'wvU3g6gjpcs?t=1521&si=EAtIoZdaGxeFizRM',
              toons: [
                { image: 'homeone' },
                { image: 'milf' },
                { image: 'rebel-ywing' },
                { image: 'outrider', main: true },
                { image: 'cassian-uwing' }
              ]
            }
          ]
        }
      ]
    },
    {
      title: 'Corellia',
      path: 'mix',
      loadouts: [
        {
          title: `Qi'ra & Young Han`,
          video: 'rR5JBVxwdL8?t=534&si=3AmtNAy_QwnLzXT7',
          toons: [
            { image: 'qira', name: 'AUTO', main: true },
            { image: 'young-han', main: true },
            { image: 'l3' },
            { image: 'rey' },
            { image: 'cat' },
          ],
          alternatives: [
            {
              videoThumb: 'vtOoPROo0Ho?t=1502&si=kzzqR7CIJ_61A0Mb',
              toons: [
                { image: 'qira', main: true },
                { image: 'young-han', main: true },
                { image: 'l3' },
                { image: 'rey' },
                { image: 'vandor-chewie' }
              ]
            },
            {
              videoThumb: 'CXmBvll3_SU?t=281&si=tYAC6qnOxdmcKxBO',
              toons: [
                { image: 'qira', main: true },
                { image: 'young-han', main: true },
                { image: 'l3' },
                { image: 'nest' },
                { image: 'vandor-chewie' }
              ]
            },
            {
              toons: [
                { image: 'qira', main: true },
                { image: 'young-han', main: true },
                { image: 'l3' },
                { image: 'cat' },
                { image: 'vandor-chewie' }
              ],
            }
          ]
        },
        {
          title: 'Aphra',
          video: 'rR5JBVxwdL8?t=642&si=iOO_oB6f-BQXkavu',
          toons: [
            { image: 'aphra', name: 'AUTO', main: true },
            { image: 'bt-1' },
            { image: 'trip' },
            { image: 'vader' },
            { image: 'krrsantan' }
          ]
        },
        {
          title: 'Jabba',
          video: 'rR5JBVxwdL8?t=688&si=DakjD0zV_oO-jeDB',
          toons: [
            { image: 'jabba', name: 'AUTO', main: true },
            { image: 'boba' },
            { image: 'boussh-leia' },
            { image: 'skiff-lando' },
            { image: 'embo' }
          ]
        },
        {
          title: 'Mix',
          video: 'rR5JBVxwdL8?t=585&si=urmG1fXccar5TWsZ',
          toons: [
            { image: 'see', name: 'AUTO' },
            { image: 'malak' },
            { image: 'malgus' },
            { image: 'nihilus' },
            { image: 'dr' }
          ],
          alternatives: [
            {
              videoThumb: 'CXmBvll3_SU?t=789&si=Fv4wHUkioXF1nZs8',
              toons: [
                { image: 'admiralraddus' },
                { image: 'jyn' },
                { image: 'cassian' },
                { image: 'bistan' },
                { image: 'pathfinder' }
              ]
            },
            {
              videoThumb: 'vtOoPROo0Ho?t=1707&si=coXQpXxXFIX9MaN3',
              toons: [
                { image: 'palpatine' },
                { image: 'mara' },
                { image: 'thrawn' },
                { image: 'tarkin' },
                { image: 'shoretrooper' }
              ]
            }
          ]
        },
        {
          title: 'Fleet',
          video: 'rR5JBVxwdL8?t=726&si=b24Nz-HQ8YuP_z-4',
          toons: [
            { image: 'executor', name: 'AUTO' },
            { image: 'houndstooth' },
            { image: 'razor-crest' },
            { image: 'lando-falcon', main: true },
            { image: 'xanadu-blood' }
          ],
          alternatives: [
            {
              videoThumb: 'CXmBvll3_SU?t=1115&si=frZKjePW_38iR3bT',
              toons: [
                { image: 'malevolence' },
                { image: 'hyena' },
                { image: 'vulture' },
                { image: 'lando-falcon', main: true },
                { image: 'spy-starfighter' }
              ]
            }
          ]
        }
      ]
    },
    {
      title: 'Mustafar',
      path: 'ds',
      loadouts: [
        {
          title: `Lord Vader`,
          video: 'vtOoPROo0Ho?t=215&si=lLF6NnsPMfhgDd88',
          toons: [
            { image: 'lv', name: 'AUTO', main: true }
          ]
        },
        {
          title: 'Top-Right',
          video: 'rR5JBVxwdL8?t=276&si=jamc-atKKgSDHSpT',
          toons: [
            { image: 'talzin', name: 'AUTO' },
            { image: 'merrin' },
            { image: 'zombie' },
            { image: 'asajj' },
            { image: 'daka' }
          ],
          alternatives: [
            {
              videoThumb: 'vtOoPROo0Ho?t=237&si=EwBMY2ISWSsbSvYU',
              toons: [
                { image: 'slkr' },
                { image: 'hux' },
                { image: 'kru' },
                { image: 'foo' },
                { image: 'fost' }
              ]
            }
          ]
        },
        {
          title: 'Bottom-Right',
          video: 'rR5JBVxwdL8?t=162&si=_AgKy24tNmoCDmx1',
          toons: [
            { image: 'trench', name: 'AUTO' },
            { image: 'wat' },
            { image: 'dooku' },
            { image: 'nute' },
            { image: 'gba' }
          ],
          alternatives: [
            {
              videoThumb: 'cNBvWc03ErI?t=850&si=M-jsHcuwRcv7foV4',
              toons: [
                { image: 'see' },
                { image: 'malak' },
                { image: 'fallen-basti' },
                { image: 'dooku' },
                { image: 'malgus' }
              ]
            }
          ]
        },
        {
          title: 'Bottom-Left',
          video: 'rR5JBVxwdL8?t=102&si=0JKeTS2rgs6AkAQt',
          toons: [
            { image: 'reva', name: 'AUTO' },
            { image: 'gi' },
            { image: 'fifth-brother' },
            { image: 'seventh-sister' },
            { image: 'eighth-brother' }
          ],
          alternatives: [
            {
              videoThumb: 'vtOoPROo0Ho?t=507&si=FtojBWYsa6sethyz',
              toons: [
                { image: 'gi' },
                { image: 'fifth-brother' },
                { image: 'seventh-sister' },
                { image: 'ninth-sister' },
                { image: 'eighth-brother' }
              ]
            }
          ]
        },
        {
          title: 'Fleet',
          video: 'rR5JBVxwdL8?t=339&si=1w5Q-RjQgBbJVsju',
          toons: [
            { image: 'leviathan', name: 'AUTO' },
            { image: 'scythe', main: true },
            { image: 'mark-vi-interceptor' },
            { image: 'b-28' },
            { image: 'tie-defender' }
          ],
          alternatives: [
            {
              videoThumb: 'uHcSSLVyH1E?t=288&si=VOPcqWEd9huhPPwW',
              toons: [
                { image: 'executrix' },
                { image: 'scythe', main: true },
                { image: 'mark-vi-interceptor' },
                { image: 'tie-advanced' },
                { image: 'tie-defender' }
              ]
            },
            {
              videoThumb: 'uHcSSLVyH1E?t=104&si=hQg77b4y-c9Q9lFV',
              toons: [
                { image: 'executrix' },
                { image: 'scythe', main: true },
                { image: 'sith-fighter' },
                { image: 'tie-advanced' },
                { image: 'tie-defender' }
              ]
            },
          ]
        }
      ]
    }
  ]
}