import React from 'react'
import DatacronIcon from '../icon'

export const getSelectList = (data) => {
  let buldList = {
    level3: [],
    level6: [],
    level9: []
  }
  data.forEach(datacron => {
    const getType = datacron.id.split('-')
    const getName = getType.slice(1).join('-')
    const bonusIcon = `/images/datacrons/${getType[0]}.png`
    const unitIcon = `/images/toons/${getName}.png`

    if (datacron.id.startsWith('ds') || datacron.id.startsWith('ls')) {
      buldList.level3.push({ ...datacron, icon: bonusIcon })
    } else if (datacron.id.startsWith('unit')) {
      buldList.level9.push({ ...datacron, icon: unitIcon })
    } else {
      buldList.level6.push({ ...datacron, icon: bonusIcon })
    }
  })
  return buldList
}

export const formatList = (data) => {
  let formatData = []
  data.forEach(datacron => {
    formatData.push({ ...datacron, value: datacron.id })
  })
  return formatData
}

export const renderOption = (option) => (
  <div className='sw-dc-modal__option'>
    <DatacronIcon
      src={option.icon}
      label={
        <div className='sw-dc-option'>
          <strong className='sw-dc-option__label'>{option.label}</strong>
          <div className='mt-1 sw-dc-option__full'>{option.full}</div>
        </div>
      }
      hideTooltip
    />
  </div>
)

export const renderSelected = (selected) => (
  <div className='sw-dc-modal__selected text-dark'>{renderOption(selected[0])}</div>
)