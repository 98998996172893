export const capitals = [
  {
    base_id: "CAPITALCHIMAERA",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_chimaera.png",
    name: "Chimaera"
  },
  {
    base_id: "CAPITALJEDICRUISER",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_venator.png",
    name: "Endurance"
  },
  {
    base_id: "CAPITALEXECUTOR",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_executor.png",
    name: "Executor"
  },
  {
    base_id: "CAPITALSTARDESTROYER",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_stardestroyer.png",
    name: "Executrix"
  },
  {
    base_id: "CAPITALFINALIZER",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_finalizer.png",
    name: "Finalizer"
  },
  {
    base_id: "CAPITALMONCALAMARICRUISER",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_moncalamarilibertycruiser.png",
    name: "Home One"
  },
  {
    base_id: "CAPITALMALEVOLENCE",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_malevolence.png",
    name: "Malevolence"
  },
  {
    base_id: "CAPITALLEVIATHAN",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_leviathan.png",
    name: "Leviathan"
  },
  {
    base_id: "CAPITALNEGOTIATOR",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_negotiator.png",
    name: "Negotiator"
  },
  {
    base_id: "CAPITALPROFUNDITY",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_profundity.png",
    name: "Profundity"
  },
  {
    base_id: "CAPITALRADDUS",
    ggImage: "https://game-assets.swgoh.gg/textures/tex.charui_raddus.png",
    name: "Raddus"
  }
];

export const toons = [
  {
    "name": "0-0-0", "base_id": "TRIPLEZERO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_triplezero.png"
  },
  {
    "name": "50R-T", "base_id": "50RT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_50rt.png"
  }, {
    "name": "Aayla Secura",
    "base_id": "AAYLASECURA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_aaylasecura.png"
  }, {
    "name": "Admiral Ackbar",
    "base_id": "ADMIRALACKBAR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ackbaradmiral.png"
  }, {
    "name": "Admiral Piett",
    "base_id": "ADMIRALPIETT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_piett.png"
  }, {
    "name": "Admiral Raddus",
    "base_id": "ADMIRALRADDUS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_admiralraddus.png"
  }, {
    "name": "Admiral Trench",
    "base_id": "TRENCH",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_admiraltrench.png"
  }, {
    "name": "Ahsoka Tano",
    "base_id": "AHSOKATANO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ahsoka.png"
  }, {
    "name": "Ahsoka Tano (Fulcrum)",
    "base_id": "FULCRUMAHSOKA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ahsokaadult.png"
  }, {
    "name": "Amilyn Holdo",
    "base_id": "AMILYNHOLDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_holdo.png"
  }, {
    "name": "ARC Trooper",
    "base_id": "ARCTROOPER501ST",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperclone_arc.png"
  }, {
    "name": "Asajj Ventress",
    "base_id": "ASAJVENTRESS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ventress.png"
  }, {
    "name": "Aurra Sing",
    "base_id": "AURRA_SING",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_aurrasing.png"
  }, {
    "name": "B1 Battle Droid",
    "base_id": "B1BATTLEDROIDV2",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_b1.png"
  }, {
    "name": "B2 Super Battle Droid",
    "base_id": "B2SUPERBATTLEDROID",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_b2.png"
  }, {
    "name": "Barriss Offee",
    "base_id": "BARRISSOFFEE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_barriss_light.png"
  }, {
    "name": "Bastila Shan",
    "base_id": "BASTILASHAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bastilashan.png"
  }, {
    "name": "Bastila Shan (Fallen)",
    "base_id": "BASTILASHANDARK",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bastilashan_dark.png"
  }, {
    "name": "Baylan Skoll",
    "base_id": "BAYLANSKOLL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_baylanskoll.png"
  },{
    "name": "Baze Malbus",
    "base_id": "BAZEMALBUS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bazemalbus.png"
  }, {
    "name": "BB-8",
    "base_id": "BB8",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bb8.png"
  }, {
    "name": "Ben Solo",
    "base_id": "BENSOLO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bensolo.png"
  }, {
    "name": "Biggs Darklighter",
    "base_id": "BIGGSDARKLIGHTER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rebelpilot_biggs.png"
  }, {
    "name": "Bistan",
    "base_id": "BISTAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bistan.png"
  }, {
    "name": "Bo-Katan (Mand'alor)",
    "base_id": "MANDALORBOKATAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_mandalorbokatan.png"
  }, {
    "name": "Bo-Katan Kryze",
    "base_id": "BOKATAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bokatan.png"
  }, {
    "name": "Boba Fett",
    "base_id": "BOBAFETT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bobafett.png"
  },
  { "name": "Boba Fett, Scion of Jango", "base_id": "BOBAFETTSCION", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bobafettold.png" },
  {
    "name": "Bodhi Rook",
    "base_id": "BODHIROOK",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bodhi.png"
  }, {
    "name": "Boss Nass",
    "base_id": "BOSSNASS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bossnass.png"
  },{
    "name": "Bossk",
    "base_id": "BOSSK",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bossk.png"
  }, {
    "name": "Boushh (Leia Organa)",
    "base_id": "BOUSHH",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_boushh.png"
  },{
    "name": "BT-1",
    "base_id": "BT1",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bt1.png"
  }, {
    "name": "C-3PO",
    "base_id": "C3POLEGENDARY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_c3p0.png"
  }, {
    "name": "Cad Bane",
    "base_id": "CADBANE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_cadbane.png"
  },
  {"name":"Cal Kestis","base_id":"CALKESTIS","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_calkestis.png" },
  {
    "name": "Canderous Ordo",
    "base_id": "CANDEROUSORDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_canderous.png"
  },{
    "name": "Captain Drogan",
    "base_id": "CAPTAINDROGAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_captaindrogan.png"
  },
   {
    "name": "Captain Han Solo",
    "base_id": "HOTHHAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_hoth_han.png"
  }, {
    "name": "Captain Phasma",
    "base_id": "PHASMA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_phasma.png"
  }, {
    "name": "Captain Rex",
    "base_id": "CAPTAINREX",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_captainrex.png"
  }, {
    "name": "Captain Tarpals",
    "base_id": "CAPTAINTARPALS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_captaintarpals.png"
  },{
    "name": "Captain Enoch",
    "base_id": "CAPTAINENOCH",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_captainenoch.png"
  },  {
    "name": "Cara Dune",
    "base_id": "CARADUNE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_caradune.png"
  }, {
    "name": "Carth Onasi",
    "base_id": "CARTHONASI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_carth.png"
  }, {
    "name": "Cassian Andor",
    "base_id": "CASSIANANDOR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_cassian.png"
  }, {
    "name": "CC-2224 \"Cody\"",
    "base_id": "CC2224",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperclone_cody.png"
  }, 
  {"name":"Cere Junda","base_id":"CEREJUNDA","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_cerejunda.png"},
  {
    "name": "Chewbacca",
    "base_id": "CHEWBACCALEGENDARY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_chewbacca_ot.png"
  }, {
    "name": "Chief Chirpa",
    "base_id": "CHIEFCHIRPA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_chirpa.png"
  }, {
    "name": "Chief Nebit",
    "base_id": "CHIEFNEBIT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jawa_nebit.png"
  }, {
    "name": "Chirrut Îmwe",
    "base_id": "CHIRRUTIMWE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_chirrut.png"
  }, {
    "name": "Chopper",
    "base_id": "CHOPPERS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_chopper.png"
  }, {
    "name": "Clone Sergeant - Phase I",
    "base_id": "CLONESERGEANTPHASEI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperclonegreen.png"
  }, {
    "name": "Clone Wars Chewbacca",
    "base_id": "CLONEWARSCHEWBACCA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_chewbacca.png"
  }, {
    "name": "Colonel Starck",
    "base_id": "COLONELSTARCK",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_colonel_stark.png"
  }, {
    "name": "Commander Ahsoka Tano",
    "base_id": "COMMANDERAHSOKA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_commanderahsokatano.png"
  }, {
    "name": "Commander Luke Skywalker",
    "base_id": "COMMANDERLUKESKYWALKER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_lukebespin.png"
  }, {
    "name": "Coruscant Underworld Police",
    "base_id": "CORUSCANTUNDERWORLDPOLICE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_coruscantpolice.png"
  }, {
    "name": "Count Dooku",
    "base_id": "COUNTDOOKU",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_dooku.png"
  }, {
    "name": "CT-21-0408 \"Echo\"",
    "base_id": "CT210408",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperclone_echo.png"
  }, {
    "name": "CT-5555 \"Fives\"",
    "base_id": "CT5555",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperclone_fives.png"
  }, {
    "name": "CT-7567 \"Rex\"",
    "base_id": "CT7567",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperclone_rex.png"
  }, {
    "name": "Dark Trooper Moff Gideon",
    "base_id": "MOFFGIDEONS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_moffgideons3.png"
  },{
    "name": "Dark Trooper",
    "base_id": "DARKTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_darktrooper.png"
  }, {
    "name": "Darth Bane",
    "base_id": "DARTHBANE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_darthbane.png"
  }, {
    "name": "Darth Malak",
    "base_id": "DARTHMALAK",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_darthmalak.png"
  }, {
    "name": "Darth Malgus",
    "base_id": "DARTHMALGUS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_darthmalgus.png"
  }, {
    "name": "Darth Maul",
    "base_id": "MAUL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_maul.png"
  }, {
    "name": "Darth Nihilus",
    "base_id": "DARTHNIHILUS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nihilus.png"
  }, {
    "name": "Darth Revan",
    "base_id": "DARTHREVAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sithrevan.png"
  }, {
    "name": "Darth Sidious",
    "base_id": "DARTHSIDIOUS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sidious.png"
  }, {
    "name": "Darth Sion",
    "base_id": "DARTHSION",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sion.png"
  },
  { "name": "Darth Talon", "base_id": "DARTHTALON", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_darthtalon.png" },
  {
    "name": "Darth Traya",
    "base_id": "DARTHTRAYA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_traya.png"
  }, {
    "name": "Darth Vader",
    "base_id": "VADER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_vader.png"
  },
  { "name": "Dash Rendar", "base_id": "DASHRENDAR", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_dashrendar.png" },
  {
    "name": "Dathcha",
    "base_id": "DATHCHA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jawa_dathcha.png"
  }, {
    "name": "Death Trooper",
    "base_id": "DEATHTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperdeath.png"
  },{
    "name": "Death Trooper (Peridea)",
    "base_id": "DEATHTROOPERPERIDEA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_deathtrooperperidea.png"
  }, {
    "name": "Dengar",
    "base_id": "DENGAR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_dengar.png"
  }, {
    "name": "Director Krennic",
    "base_id": "DIRECTORKRENNIC",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_krennic.png"
  },{
    "name": "Doctor Aphra",
    "base_id": "DOCTORAPHRA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_doctoraphra.png"
  }, {
    "name": "Droideka",
    "base_id": "DROIDEKA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_droideka.png"
  }, {
    "name": "Echo",
    "base_id": "BADBATCHECHO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bb_echo.png"
  }, {
    "name": "Eeth Koth",
    "base_id": "EETHKOTH",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_eethkoth.png"
  }, {
    "name": "Eighth Brother",
    "base_id": "EIGHTHBROTHER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_eighthbrother.png"
  }, {
    "name": "Embo",
    "base_id": "EMBO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_embo.png"
  }, {
    "name": "Emperor Palpatine",
    "base_id": "EMPERORPALPATINE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_palpatineemperor.png"
  }, {
    "name": "Enfys Nest",
    "base_id": "ENFYSNEST",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_enfys.png"
  }, {
    "name": "Ewok Elder",
    "base_id": "EWOKELDER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_chief.png"
  }, {
    "name": "Ewok Scout",
    "base_id": "EWOKSCOUT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_scout.png"
  }, {
    "name": "Ezra Bridger",
    "base_id": "EZRABRIDGERS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ezra_s3.png"
  },{
    "name": "Ezra Bridger (Exile)",
    "base_id": "EZRABRIDGEREXILE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ezraexile.png"
  },
  { "name": "Fennec Shand", "base_id": "FENNECSHAND", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_fennec.png" },
  { "name": "Fifth Brother", "base_id": "FIFTHBROTHER", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_fifthbrother.png" },
  {
    "name": "Finn",
    "base_id": "FINN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_finnjakku.png"
  }, {
    "name": "First Order Executioner",
    "base_id": "FIRSTORDEREXECUTIONER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_firstorder_executioner.png"
  }, {
    "name": "First Order Officer",
    "base_id": "FIRSTORDEROFFICERMALE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_firstorderofficer.png"
  }, {
    "name": "First Order SF TIE Pilot",
    "base_id": "FIRSTORDERSPECIALFORCESPILOT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_firstorder_pilot.png"
  }, {
    "name": "First Order Stormtrooper",
    "base_id": "FIRSTORDERTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_firstordertrooper.png"
  }, {
    "name": "First Order TIE Pilot",
    "base_id": "FIRSTORDERTIEPILOT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_firstordertiepilot.png"
  }, {
    "name": "Gamorrean Guard",
    "base_id": "GAMORREANGUARD",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_gamorreanguard.png"
  }, {
    "name": "Gar Saxon",
    "base_id": "GARSAXON",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_gar_saxon.png"
  }, {
    "name": "Garazeb \"Zeb\" Orrelios",
    "base_id": "ZEBS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_zeb_s3.png"
  }, {
    "name": "General Grievous",
    "base_id": "GRIEVOUS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_grievous.png"
  }, {
    "name": "General Hux",
    "base_id": "GENERALHUX",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_generalhux.png"
  }, {
    "name": "General Kenobi",
    "base_id": "GENERALKENOBI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_obiwangeneral.png"
  }, {
    "name": "General Skywalker",
    "base_id": "GENERALSKYWALKER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_generalanakin.png"
  }, {
    "name": "General Veers",
    "base_id": "VEERS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_veers.png"
  }, {
    "name": "Geonosian Brood Alpha",
    "base_id": "GEONOSIANBROODALPHA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_geonosian_broodalpha.png"
  }, {
    "name": "Geonosian Soldier",
    "base_id": "GEONOSIANSOLDIER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_geonosian_soldier.png"
  }, {
    "name": "Geonosian Spy",
    "base_id": "GEONOSIANSPY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_geonosian_spy.png"
  }, {
    "name": "Grand Admiral Thrawn",
    "base_id": "GRANDADMIRALTHRAWN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_thrawn.png"
  }, {
    "name": "Grand Inquisitor",
    "base_id": "GRANDINQUISITOR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_grandinquisitor.png"
  }, {
    "name": "Grand Master Yoda",
    "base_id": "GRANDMASTERYODA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_yodagrandmaster.png"
  }, {
    "name": "Grand Moff Tarkin",
    "base_id": "GRANDMOFFTARKIN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tarkinadmiral.png"
  }, {
    "name": "Great Mothers",
    "base_id": "GREATMOTHERS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_greatmothers.png"
  }, {
    "name": "Greedo",
    "base_id": "GREEDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_greedo.png"
  }, {
    "name": "Greef Karga",
    "base_id": "GREEFKARGA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_greefkarga.png"
  }, {
    "name": "Gungan Boomadier",
    "base_id": "BOOMADIER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_gunganboomadier.png"
  }, {
    "name": "Gungan Phalanx",
    "base_id": "GUNGANPHALANX",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_gunganphalanx.png"
  }, {
    "name": "Han Solo",
    "base_id": "HANSOLO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_han.png"
  }, {
    "name": "Hera Syndulla",
    "base_id": "HERASYNDULLAS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_hera_s3.png"
  }, {
    "name": "Hermit Yoda",
    "base_id": "HERMITYODA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_yodahermit.png"
  }, {
    "name": "HK-47",
    "base_id": "HK47",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_hk47.png"
  }, {
    "name": "Hoth Rebel Scout",
    "base_id": "HOTHREBELSCOUT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rebelhothscout.png"
  }, {
    "name": "Hondo Ohnaka",
    "base_id": "HONDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_hondoohnaka.png"
  }, {
    "name": "Hoth Rebel Soldier",
    "base_id": "HOTHREBELSOLDIER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rebelhoth.png"
  }, {
    "name": "Hunter",
    "base_id": "BADBATCHHUNTER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bb_hunter.png"
  }, {
    "name": "Iden Versio",
    "base_id": "IDENVERSIOEMPIRE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_idenversioempire.png"
  }, {
    "name": "IG-100 MagnaGuard",
    "base_id": "MAGNAGUARD",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_magnaguard.png"
  }, {
    "name": "IG-11",
    "base_id": "IG11",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ig11_nurse.png"
  },{
    "name": "IG-12 & Grogu",
    "base_id": "IG12",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ig12.png"
  }, {
    "name": "IG-86 Sentinel Droid",
    "base_id": "IG86SENTINELDROID",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ig86.png"
  }, {
    "name": "IG-88",
    "base_id": "IG88",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ig88.png"
  }, {
    "name": "Ima-Gun Di",
    "base_id": "IMAGUNDI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_imagundi.png"
  }, {
    "name": "Imperial Probe Droid",
    "base_id": "IMPERIALPROBEDROID",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_probedroid.png"
  }, {
    "name": "Imperial Super Commando",
    "base_id": "IMPERIALSUPERCOMMANDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_imperial_super_commando.png"
  }, {
    "name": "Jabba the Hutt",
    "base_id": "JABBATHEHUTT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jabbathehutt.png"
  }, {
    "name": "Jango Fett",
    "base_id": "JANGOFETT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jangofett.png"
  }, {
    "name": "Jar Jar",
    "base_id": "JARJARBINKS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jarjarbinks.png"
  }, {
    "name": "Jawa",
    "base_id": "JAWA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jawa_jawa.png"
  }, {
    "name": "Jawa Engineer",
    "base_id": "JAWAENGINEER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jawa_engineer.png"
  }, {
    "name": "Jawa Scavenger",
    "base_id": "JAWASCAVENGER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jawa_scavenger.png"
  }, {
    "name": "Jedi Consular",
    "base_id": "JEDIKNIGHTCONSULAR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jedi_consular_03.png"
  }, {
    "name": "Jedi Knight Anakin",
    "base_id": "ANAKINKNIGHT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_anakinknight.png"
  },{
    "name": "Jedi Knight Cal Kestis",
    "base_id": "JEDIKNIGHTCAL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jediknightcal.png"
  }, {
    "name": "Jedi Knight Guardian",
    "base_id": "JEDIKNIGHTGUARDIAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jedi_guardian_01.png"
  }, {
    "name": "Jedi Knight Luke Skywalker",
    "base_id": "JEDIKNIGHTLUKE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_luke_jediknight.png"
  }, {
    "name": "Jedi Knight Revan",
    "base_id": "JEDIKNIGHTREVAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jedirevan.png"
  }, {
    "name": "Jedi Master Kenobi",
    "base_id": "JEDIMASTERKENOBI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_globiwan.png"
  }, {
    "name": "Jedi Master Luke Skywalker",
    "base_id": "GRANDMASTERLUKE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_luke_jml.png"
  }, {
    "name": "Jolee Bindo",
    "base_id": "JOLEEBINDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_joleebindo.png"
  }, {
    "name": "Juhani",
    "base_id": "JUHANI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_juhani.png"
  }, {
    "name": "Jyn Erso",
    "base_id": "JYNERSO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_jyn.png"
  }, {
    "name": "K-2SO",
    "base_id": "K2SO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_k2so.png"
  }, {
    "name": "Kanan Jarrus",
    "base_id": "KANANJARRUSS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kanan_s3.png"
  }, {
    "name": "Kelleran Beq",
    "base_id": "KELLERANBEQ",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kelleranbeq.png"
  }, {
    "name": "Ki-Adi-Mundi",
    "base_id": "KIADIMUNDI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kiadimundi.png"
  }, {
    "name": "Kit Fisto",
    "base_id": "KITFISTO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kitfisto.png"
  }, {
    "name": "Krrsantan",
    "base_id": "KRRSANTAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_krrsantan.png"
  }, {
    "name": "Kuiil",
    "base_id": "KUIIL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kuiil.png"
  },
  { "name": "Kyle Katarn", "base_id": "KYLEKATARN", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kylekatarn.png" },
  {
    "name": "Kylo Ren",
    "base_id": "KYLOREN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kyloren.png"
  }, {
    "name": "Kylo Ren (Unmasked)",
    "base_id": "KYLORENUNMASKED",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kylo_unmasked.png"
  }, {
    "name": "L3-37",
    "base_id": "L3_37",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_l337.png"
  }, {
    "name": "Lando Calrissian",
    "base_id": "ADMINISTRATORLANDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_landobespin.png"
  }, {
    "name": "Leia Organa",
    "base_id": "GLLEIA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_leiaendor.png"
  },
  {
    "name": "Lobot",
    "base_id": "LOBOT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_lobot.png"
  }, {
    "name": "Logray",
    "base_id": "LOGRAY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_logray.png"
  }, {
    "name": "Lord Vader",
    "base_id": "LORDVADER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_lordvader.png"
  }, {
    "name": "Luke Skywalker (Farmboy)",
    "base_id": "LUKESKYWALKER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_luke_ep4.png"
  }, {
    "name": "Luminara Unduli",
    "base_id": "LUMINARAUNDULI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_luminara.png"
  }, {
    "name": "Luthen Rael",
    "base_id": "LUTHENRAEL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_luthenrael.png"
  }, {
    "name": "Mace Windu",
    "base_id": "MACEWINDU",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_macewindu.png"
  }, {
    "name": "Magmatrooper",
    "base_id": "MAGMATROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperstorm_magma.png"
  },
  { "name": "Mara Jade, The Emperor's Hand", "base_id": "MARAJADE", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_marajade.png" },
  {
    "name": "Marrok",
    "base_id": "MARROK",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_marrok.png"
  }, {
    "name": "Master Qui-Gon",
    "base_id": "MASTERQUIGON",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_masterquigon.png"
  }, 
  {
    "name": "Maul",
    "base_id": "MAULS7",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_maul_cyborg.png"
  }, 
  {"name":"Merrin","base_id":"MERRIN","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_merrin.png"},  
  {
    "name": "Mission Vao",
    "base_id": "MISSIONVAO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_mission.png"
  }, {
    "name": "Mob Enforcer",
    "base_id": "HUMANTHUG",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_thug_female_01.png"
  }, {
    "name": "Moff Gideon",
    "base_id": "MOFFGIDEONS1",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_moffgideon.png"
  }, {
    "name": "Mon Mothma",
    "base_id": "MONMOTHMA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_monmothma.png"
  }, {
    "name": "Mother Talzin",
    "base_id": "MOTHERTALZIN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nightsisters_talzin.png"
  }, {
    "name": "Morgan Elsbeth",
    "base_id": "MORGANELSBETH",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_morganelsbeth.png"
  },{
    "name": "Nightsister Acolyte",
    "base_id": "NIGHTSISTERACOLYTE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nightsister_acolyte.png"
  }, {
    "name": "Nightsister Initiate",
    "base_id": "NIGHTSISTERINITIATE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nightsister_initiate.png"
  }, {
    "name": "Nightsister Spirit",
    "base_id": "NIGHTSISTERSPIRIT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nightsisters_wraith.png"
  }, {
    "name": "Nightsister Zombie",
    "base_id": "NIGHTSISTERZOMBIE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nightsisters_zombie.png"
  }, {
    "name": "Night Trooper",
    "base_id": "NIGHTTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nighttrooper_a.png"
  },{
    "name": "Ninth Sister",
    "base_id": "NINTHSISTER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ninthsister.png"
  }, {
    "name": "Nute Gunray",
    "base_id": "NUTEGUNRAY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nutegunray.png"
  }, {
    "name": "Obi-Wan Kenobi (Old Ben)",
    "base_id": "OLDBENKENOBI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_obiwanep4.png"
  }, {
    "name": "Old Daka",
    "base_id": "DAKA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_daka.png"
  }, {
    "name": "Omega",
    "base_id": "BADBATCHOMEGA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_badbatchomega.png"
  }, {
    "name": "Padawan Obi-Wan",
    "base_id": "PADAWANOBIWAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_padawanobiwan.png"
  }, {
    "name": "Padawan Sabine Wren",
    "base_id": "PADAWANSABINE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_padawansabine.png"
  },{
    "name": "Padmé Amidala",
    "base_id": "PADMEAMIDALA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_padme_geonosis.png"
  }, {
    "name": "Pao",
    "base_id": "PAO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_pao.png"
  }, {
    "name": "Paz Vizsla",
    "base_id": "PAZVIZSLA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_pazvizsla.png"
  }, {
    "name": "Paploo",
    "base_id": "PAPLOO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_paploo.png"
  },{
    "name": "Plo Koon",
    "base_id": "PLOKOON",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_plokoon.png"
  }, {
    "name": "Poe Dameron",
    "base_id": "POE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_poe.png"
  }, {
    "name": "Poggle the Lesser",
    "base_id": "POGGLETHELESSER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_geonosian_poggle.png"
  }, {
    "name": "Princess Kneesaa",
    "base_id": "PRINCESSKNEESAA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_princesskneesaa.png"
  }, {
    "name": "Princess Leia",
    "base_id": "PRINCESSLEIA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_leia_princess.png"
  }, {
    "name": "Qi'ra",
    "base_id": "QIRA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_qira.png"
  }, {
    "name": "Qui-Gon Jinn",
    "base_id": "QUIGONJINN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_quigon.png"
  }, {
    "name": "Queen Amidala",
    "base_id": "QUEENAMIDALA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_queenamidala.png"
  }, {
    "name": "R2-D2",
    "base_id": "R2D2_LEGENDARY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_astromech_r2d2.png"
  }, {
    "name": "Range Trooper",
    "base_id": "RANGETROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperranger.png"
  }, {
    "name": "Rebel Officer Leia Organa",
    "base_id": "HOTHLEIA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_leiahoth.png"
  }, {
    "name": "Resistance Hero Finn",
    "base_id": "EPIXFINN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_finn.png"
  }, {
    "name": "Resistance Hero Poe",
    "base_id": "EPIXPOE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_poe_tros.png"
  }, {
    "name": "Resistance Pilot",
    "base_id": "RESISTANCEPILOT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_resistancepilot.png"
  }, {
    "name": "Resistance Trooper",
    "base_id": "RESISTANCETROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_resistancetrooper.png"
  }, {
    "name": "Rey",
    "base_id": "GLREY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rey_tros.png"
  }, {
    "name": "Rey (Jedi Training)",
    "base_id": "REYJEDITRAINING",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rey_tlj.png"
  }, {
    "name": "Rey (Scavenger)",
    "base_id": "REY",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_reyjakku.png"
  }, {
    "name": "Rose Tico",
    "base_id": "ROSETICO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rose.png"
  }, {
    "name": "Royal Guard",
    "base_id": "ROYALGUARD",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_royalguard.png"
  }, {
    "name": "Sabine Wren",
    "base_id": "SABINEWRENS3",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sabine_s3.png"
  }, {
    "name": "Sana Starros",
    "base_id": "SANASTARROS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sanastarros.png"
  },{
    "name": "Savage Opress",
    "base_id": "SAVAGEOPRESS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_savageopress.png"
  }, 
  {"name":"Saw Gerrera","base_id":"SAWGERRERA","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_sawgerrera.png"},
  {
    "name": "Scarif Rebel Pathfinder",
    "base_id": "SCARIFREBEL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rebel_scarif.png"
  }, 
  
  {"name":"Scout Trooper","base_id":"SCOUTTROOPER_V3","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_trooperscout.png"},
  {
    "name": "Second Sister",
    "base_id": "SECONDSISTER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_secondsister.png"
  }, {
    "name": "Seventh Sister",
    "base_id": "SEVENTHSISTER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_seventhsister.png"
  }, {
    "name": "Shin Hati",
    "base_id": "SHINHATI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_shinhati.png"
  },  {
    "name": "Shaak Ti",
    "base_id": "SHAAKTI",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_shaakti.png"
  }, {
    "name": "Shoretrooper",
    "base_id": "SHORETROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_troopershore.png"
  }, {
    "name": "Sith Assassin",
    "base_id": "SITHASSASSIN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sithassassin.png"
  }, {
    "name": "Sith Empire Trooper",
    "base_id": "SITHTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sithtrooper.png"
  }, {
    "name": "Sith Eternal Emperor",
    "base_id": "SITHPALPATINE",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_espalpatine_pre.png"
  }, {
    "name": "Sith Marauder",
    "base_id": "SITHMARAUDER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_sithmarauder.png"
  }, {
    "name": "Sith Trooper",
    "base_id": "FOSITHTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_firstorder_sithtrooper.png"
  }, {
    "name": "Skiff Guard (Lando Calrissian)",
    "base_id": "UNDERCOVERLANDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_undercoverlando.png"
  }, {
    "name": "Snowtrooper",
    "base_id": "SNOWTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_troopersnow.png"
  },
  { "name": "STAP", "base_id": "STAP", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_stap.png" },
  { "name": "Starkiller", "base_id": "STARKILLER", "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_starkiller.png" },
  {
    "name": "Stormtrooper",
    "base_id": "STORMTROOPER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperstorm.png"
  }, {
    "name": "Stormtrooper Han",
    "base_id": "STORMTROOPERHAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_trooperstorm_han.png"
  }, {
    "name": "Sun Fac",
    "base_id": "SUNFAC",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_geonosian_sunfac.png"
  }, {
    "name": "Supreme Leader Kylo Ren",
    "base_id": "SUPREMELEADERKYLOREN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_kyloren_tros.png"
  }, {
    "name": "T3-M4",
    "base_id": "T3_M4",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_t3m4.png"
  }, {
    "name": "Talia",
    "base_id": "TALIA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_nightsister_talia.png"
  }, 
  {"name":"Tarfful","base_id":"TARFFUL","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_tarfful.png"},
  {"name":"Taron Malicos","base_id":"TARONMALICOS","ggImage":"https://game-assets.swgoh.gg/textures/tex.charui_taronmalicos.png"},
  {
    "name": "Tech",
    "base_id": "BADBATCHTECH",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bb_tech.png"
  }, {
    "name": "Teebo",
    "base_id": "TEEBO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_teebo.png"
  }, {
    "name": "The Armorer",
    "base_id": "ARMORER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_armorer.png"
  }, {
    "name": "The Mandalorian",
    "base_id": "THEMANDALORIAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_mandalorian.png"
  }, {
    "name": "The Mandalorian (Beskar Armor)",
    "base_id": "THEMANDALORIANBESKARARMOR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_mandobeskar.png"
  }, {
    "name": "Third Sister",
    "base_id": "THIRDSISTER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_thirdsister.png"
  },{
    "name": "Threepio & Chewie",
    "base_id": "C3POCHEWBACCA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_chewbacca_c3po.png"
  }, {
    "name": "TIE Fighter Pilot",
    "base_id": "TIEFIGHTERPILOT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tiepilot.png"
  }, {
    "name": "Tusken Chieftain",
    "base_id": "TUSKENCHIEFTAIN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tuskenchieftain.png"
  }, {
    "name": "Tusken Raider",
    "base_id": "TUSKENRAIDER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tuskenraider.png"
  }, {
    "name": "Tusken Shaman",
    "base_id": "TUSKENSHAMAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tuskenshaman.png"
  }, {
    "name": "Tusken Warrior",
    "base_id": "TUSKENHUNTRESS",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tuskenhuntress.png"
  }, {
    "name": "Ugnaught",
    "base_id": "UGNAUGHT",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ugnaught.png"
  }, {
    "name": "URoRRuR'R'R",
    "base_id": "URORRURRR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_urorrurrr.png"
  }, {
    "name": "Vandor Chewbacca",
    "base_id": "YOUNGCHEWBACCA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_chewbacca_vandor.png"
  }, {
    "name": "Veteran Smuggler Chewbacca",
    "base_id": "SMUGGLERCHEWBACCA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tfa_chewbacca.png"
  }, {
    "name": "Veteran Smuggler Han Solo",
    "base_id": "SMUGGLERHAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_tfa_han.png"
  }, {
    "name": "Visas Marr",
    "base_id": "VISASMARR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_visas.png"
  }, {
    "name": "Wampa",
    "base_id": "WAMPA",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_wampa.png"
  }, {
    "name": "Wat Tambor",
    "base_id": "WATTAMBOR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_wattambor.png"
  }, {
    "name": "Wedge Antilles",
    "base_id": "WEDGEANTILLES",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_rebelpilot_wedge.png"
  }, {
    "name": "Wicket",
    "base_id": "WICKET",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_ewok_wicket.png"
  }, {
    "name": "Wrecker",
    "base_id": "BADBATCHWRECKER",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_bb_wrecker.png"
  }, {
    "name": "Young Han Solo",
    "base_id": "YOUNGHAN",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_han_young.png"
  }, {
    "name": "Young Lando Calrissian",
    "base_id": "YOUNGLANDO",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_younglando.png"
  }, {
    "name": "Zaalbar",
    "base_id": "ZAALBAR",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_zaalbar.png"
  }, {
    "name": "Zam Wesell",
    "base_id": "ZAMWESELL",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_zamwesell.png"
  }, {
    "name": "Zorii Bliss",
    "base_id": "ZORIIBLISS_V2",
    "ggImage": "https://game-assets.swgoh.gg/textures/tex.charui_zoriibliss.png"
  }
];