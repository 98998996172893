const sector2 = [

  {
    title: 'Bonus Turn',
    text: 'Gain Bonus Turn 50 times',
    keycards: 5,
    teams: [
      {
        videoThumb: 'YWLK52JUuxc?t=192&si=Q92PaR11ooJYa4Uf',
        feats: [
          { name: 'Bonus Turn', qty: 10 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'vader', name: 'Darth Vader', main: true },
          { image: 'han', name: 'Han', main: true },
          { image: 'mara', name: 'Mara' },
          { image: 'fallen-basti', name: 'Fallen Basti' },
          { image: 'malak', name: 'Malak'},
        ]
      },
      {
        videoThumb: 'YWLK52JUuxc?t=84&si=NGiQ_N50nuAWEP1W',
        feats: [
          { name: 'Bonus Turn', qty: 10 },
          { name: 'Breach', qty: 5 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul', main: true },
          { image: 'wat', name: 'Wat', main: true },
          { image: 'paz', name: 'Paz' },
          { image: 'sabine', name: 'Sabine'},
          { image: 'canderous', name: 'Candy' },
        ]
      }
    ]
  },
  {
    title: 'Morgan',
    text: 'Defeat 35 enemies with Morgan',
    keycards: 5,
    teams: [
      {
        name: 'Skip This?',
        videoThumb: '',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        toons: [
          { image: 'morgan', name: 'Morgan' }
        ]
      },
      {
        name: 'Cheese',
        videoThumb: '4r7OwYlnFQU?t=739&si=fGX695GG5n7IJWph',
        feats: [
          { name: 'Morgan', qty: 30 },
        ],
        toons: [
          { image: 'rex', name: 'Rex' },
          { image: 'malak', name: 'Malak' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'chewie', name: 'Chewie' },
          { image: 'wat', name: 'Wat' }
        ]
      },
    ]
  },
  {
    title: `Alert`,
    text: `Gain Alert 20 times`,
    keycards: 5,
    teams: [
      {
        name: 'Cheese',
        videoThumb: '4r7OwYlnFQU?t=553&si=gWmKp9flyL5U6Ho_',
        feats: [
          { name: 'Alert', qty: 12 },
        ],
        toons: [
          { image: 'rex', name: 'Rex' },
          { image: 'malak', name: 'Malak' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'chewie', name: 'Chewie' },
          { image: 'wat', name: 'Wat' }
        ]
      },
      {
        videoThumb: 'YWLK52JUuxc?t=348&si=HK1RG73MJFI8zvIg',
        feats: [
          { name: 'Alert', qty: 2 },
        ],
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'tfp', name: 'Tie Pilot' },
          { image: 'death-trooper', name: 'Death Trooper' }
        ]
      },
      {
        videoThumb: 'YWLK52JUuxc?t=424&si=bmPd5MMnX8k7byEr',
        feats: [
          { name: 'Alert', qty: 2 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' }
        ]
      },
    ]
  },
  {
    title: 'Breach',
    text: 'Attempt to inflict Breach 50 times',
    keycards: 5,
    teams: [   
      {
        name: 'Cheese',
        videoThumb: 'YWLK52JUuxc?t=489&si=gDS8_k0SVTUaPAE9',
        feats: [
          { name: 'Breach', qty: 50 },
          { name: 'Bonus Turn', qty: 2 },
        ],
        toons: [          
          { image: 'traya', name: 'Traya' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'malak', name: 'Malak' },
          { image: 'capt-han', name: 'Cap Han' },
          { image: 'han', name: 'Han' },
        ]
      },
      {
        videoThumb: 'YWLK52JUuxc?t=262&si=I8sBkH7NRzRqh-9U',
        feats: [
          { name: 'Breach', qty: 10 },
          { name: 'Bonus Turn', qty: 2 },
        ],
        global: [
          { name: 'Master QGJ' }
        ],
        toons: [          
          { image: 'jml', name: 'Master Luke', main: true },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'han', name: 'Han' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        videoThumb: 'YWLK52JUuxc?t=84&si=NGiQ_N50nuAWEP1W',
        feats: [
          { name: 'Bonus Turn', qty: 10 },
          { name: 'Breach', qty: 5 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'wat', name: 'Wat'},
          { image: 'paz', name: 'Paz', main: true },
          { image: 'sabine', name: 'Sabine'},
          { image: 'canderous', name: 'Candy' },
        ]
      }
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Hux with Death Trooper (Peridea) surviving and with Master Qui-Gon and Qui-Gon Jinn in your sqaud',
    keycards: 7,
    teams: [
      {
        name: 'Peridea',
        videoThumb: 'HbVl6AnlkwY?t=107&si=8ugNDfB8YkuoDXIq',
        feats: [
          { name: 'Alert', qty: 2 },
        ],
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' }
        ]
      },
      {
        videoThumb: 'HbVl6AnlkwY?t=203&si=YNLrOWT9MbzNtKNG',
        feats: [
          { name: 'Alert', qty: 2 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'peridea', name: 'Peridea', main: true },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: 'HbVl6AnlkwY?t=333&si=d89s8WooSKJRaivc',
        toons: [
          { image: 'traya', name: 'Traya' },
          { image: 'malak', name: 'Malak', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'peridea', name: 'Peridea', main: true },
        ]
      },
      {
        name: 'Qui-Gon',
        videoThumb: 'HbVl6AnlkwY?t=543&si=oNslF13teTJikqOY',
        feats: [
          { name: 'Breach', qty: 10 },
          { name: 'Bonus Turn', qty: 2 },
        ],
        global: [
          { name: 'Master QGJ' }
        ],
        toons: [          
          { image: 'jml', name: 'Master Luke'},
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'qgj', name: 'QGJ', main: true },
          { image: 'han', name: 'Han' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: 'HbVl6AnlkwY?t=475&si=Nmo_9P9KZBilJ6VY',
        toons: [
          { image: 'traya', name: 'Traya' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'qgj', name: 'QGJ', main: true },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'malak', name: 'Malak', main: true },
        ]
      },
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Iden with Tarpals defeating an enemy and Dark Side only',
    keycards: 9,
    teams: [
      {
        name: 'Tarpals',
        videoThumb: 'HbVl6AnlkwY?t=710&si=jhJvmHXvhiCM3pt_',
        global: [
          { name: 'Gungans' },
        ],
        toons: [          
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'jar-jar', name: 'Jar Jar' },
          { image: 'tarpals', name: 'Tarpals', main: true },
        ]
      },
      {
        name: 'Dark Side',
        videoThumb: 'HbVl6AnlkwY?t=768&si=pHWVJuZzZYCCh6s-',
        feats: [
          { name: 'Bonus Turn', qty: 5 },
        ],
        toons: [
          { image: 'gg', name: 'GG' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'nute', name: 'Nute' },
          { image: 'wat', name: 'Wat' },
        ]
      },
    ]
  }
];

export {
  sector2
}