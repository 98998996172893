import React, { useState, useEffect } from 'react';
import DatacronNewAccount from './new';
import DatacronAccountsModal from './modal';
import DatacronAccountItem from './item';

const DatacronAccounts = (props) => {
  const { onClose, onAccountLoad, loadedAccount } = props
  const [addAccount, toggleAddAccount] = useState(false)
  const [accountName, setAccountName] = useState('')
  const [nameError, setNameError] = useState('')
  const [accountList, setAccountList] = useState([])

  const onAddCancel = () => {
    setAccountName('')
    toggleAddAccount(false)
  }

  const onAddConfirm = () => {
    let updateList = []
    const getList = localStorage.getItem('dcFarmingListAccounts')
    if (getList && getList !== 'undefined') {
      updateList = [...JSON.parse(getList), accountName]
    } else {
      updateList = [accountName]
    }
    localStorage.setItem('dcFarmingListAccounts', JSON.stringify(updateList))
    setAccountList(updateList)
    onAddCancel()
  }

  const onAddInputChange = (val) => {
    setAccountName(val)
    const checkName = accountList.find(a => a === val)
    if(checkName) {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  const onAccountDelete = (val) => {
    const removeName = accountList.filter(a => a !== val)
    localStorage.setItem('dcFarmingListAccounts', JSON.stringify(removeName))
    localStorage.removeItem(`dcFarmingList13${val}`)
    localStorage.removeItem(`dcFarmingList12${val}`)
    setAccountList(removeName)
  }

  useEffect(() => {
    const getList = localStorage.getItem('dcFarmingListAccounts')
    if (getList && getList !== 'undefined') {
      setAccountList(JSON.parse(getList))
    }
  }, [])

  return (
    <DatacronAccountsModal onClose={onClose} toggleAddAccount={() => toggleAddAccount(true)}>
      {addAccount ?
        <>
          <DatacronNewAccount
            setAccountName={(e) => onAddInputChange(e.target.value)}
            onAddConfirm={onAddConfirm}
            onAddCancel={onAddCancel}
            accountName={accountName}
            nameError={nameError}
          />
        </>
        :
        <>
          <DatacronAccountItem name='Default' isDefault onLoad={onAccountLoad} loadedAccount={loadedAccount} />
          {accountList && accountList.length > 0 && accountList.map((a, i) =>
            <DatacronAccountItem name={a} key={`${a}_${i}`} loadedAccount={loadedAccount} onLoad={onAccountLoad} onDelete={onAccountDelete}  />
          )}
        </>
      }
    </DatacronAccountsModal >
  );
}

export default DatacronAccounts;