const list = [
    { name: 'Chimaera', value: 'chimaera', base_id: 'CAPITALCHIMAERA' },
    { name: 'Endurance', value: 'endurance', base_id: 'CAPITALJEDICRUISER', noCounters: true },
    { 
        name: 'Executor', value: 'executor', base_id: 'CAPITALEXECUTOR', 
        ggImage: 'https://game-assets.swgoh.gg/textures/tex.charui_executor.png' 
    },
    { name: 'Executrix', value: 'executrix', base_id: 'CAPITALSTARDESTROYER' },
    { name: 'Finalizer', value: 'finalizer', base_id: 'CAPITALFINALIZER' },
    { 
        name: 'Leviathan', value: 'leviathan', base_id: 'CAPITALLEVIATHAN', 
        ggImage: 'https://game-assets.swgoh.gg/textures/tex.charui_leviathan.png' 
    },
    { name: 'Home One', value: 'homeone', base_id: 'CAPITALMONCALAMARICRUISER' },
    { name: 'Malevolence', value: 'malevolence', base_id: 'CAPITALMALEVOLENCE' },
    { name: 'Negotiator', value: 'negotiator', base_id: 'CAPITALNEGOTIATOR' },
    { name: 'Profundity', value: 'profundity', base_id: 'CAPITALPROFUNDITY' },
    { name: 'Raddus', value: 'raddus', base_id: 'CAPITALRADDUS' },
];

export {
    list
}