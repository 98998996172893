const sector0 = [
  {
    title: 'Challenge Path',
    text: 'Defeat 250 enemies on the golden Challenge Path',
    keycards: 15,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  },
  {
    title: 'Nightsisters',
    text: 'Win 20 battles with a full squad of Nightsisters',
    keycards: 15,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=298&si=09dPrfS3ltfnFVzo',
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'morgan', name: 'Morgan' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
    ]
  },
  {
    title: 'Stagger',
    text: 'Attempt to inflict stagger 100 times',
    keycards: 15,
    teams: [
      {
        videoThumb: 'VSOm6nRcj4g?t=299&si=kRazp1E3TtTCsseP',
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper', main: true },
          { image: 'mara', name: 'Mara', main: true },
          { image: 'krennic', name: 'Krennic', main: true },
        ]
      }
    ]
  },
  {
    title: 'Imperial Remnants',
    text: 'Win 20 battles with a full squad of Imperial Remnants',
    keycards: 15,
    teams: [
      {
        videoThumb: '4r7OwYlnFQU?t=211&si=W1no7VDJ7BX1fWa0',
        toons: [
          { image: 'gideon', name: 'Moff Gideon' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'dark-trooper', name: 'Dark Trooper' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'tfp', name: 'Tie Pilot' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'dark-trooper', name: 'Dark Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'enoch', name: 'Enoch' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'tfp', name: 'Tie Pilot' },
          { image: 'stormtrooper', name: 'Stormtrooper' }
        ]
      }
    ]
  },
  {
    title: 'Gungans',
    text: 'Win 15 battles with at least 4 Gungans in your squad',
    keycards: 1,
    dataDisk: 'Massively Overpowered',
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=118&si=cpSC_iBWQTKXSPWr',
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=141&si=p5cZSWDcFysehgUA',
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=198&si=-SrLl7otiRV-sLMc',
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      },
    ]
  },
  {
    title: 'Master QGJ',
    text: 'Win 15 battles with Master Qui-Gon surviving',
    keycards: 1,
    dataDisk: 'Deployable Cooling Systems',
    teams: [
      {
        videoThumb: '4r7OwYlnFQU?t=272&si=9_S7gDro8Z1VeSZA',
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'shaak-ti', name: 'Shaak Ti' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jolee', name: 'Jolee' }
        ]
      }
    ]
  },
  {
    title: `Massively Overpowered`,
    text: 'Gain Massively Overpowered 20 times',
    keycards: 15,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  },
  {
    title: `Deployable Cooling Systems`,
    text: 'Complete the battle with Deployable Cooling Systems active',
    keycards: 15,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  }
];

export {
  sector0
}