const day10 = [
  {
    title: `Sector 3 - Datacron Farming`,
    teams: [
      {
        id: 1001,
        name: '1x',
        videoThumb: '',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Retaliate', skip: true },
      { name: 'Geonosians', skip: true },
      { name: 'Evasion Up', complete: true },
      { name: 'Acolyte', current: 15, target: 15 },
    ]
  },

  {
    title: `Sector 4 - Datacron Farming`,
    teams: [
      {
        id: 1002,
        name: '2x',
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        id: 1003,
        name: '2x',
        videoThumb: 'sgycYTd2Zbo?t=302&si=yM8VDC95F5cLkqrV',
        feats: [
          { name: 'Blight', qty: 5 },
          { name: 'Nightsisters', qty: 5 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Blight', skip: true },
      { name: 'Tenacity Up', complete: true },
      { name: 'Kelleran', current: 10, target: 15 },
      { name: 'Nightsisters', current: 25, target: 30 }
    ]
  },

  {
    title: `Sector 5 - Datacron Farming`,
    teams: [
      {
        id: 1004,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=378&si=Nrcy93gMlcTW1Ueh',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 1005,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=333&si=qha9YFIPipIyUiYe',
        feats: [
          { name: 'Plague', qty: 10 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Accuracy Up', complete: true },
      { name: 'Geo Spy', current: 10, target: 15 },
      { name: 'Plague', current: 60, target: 100 },
      { name: 'Peridea', current: 8, target: 25 }
    ]
  },


  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true },
      { name: 'Imperial Remnants', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Master QGJ', current: 11, target: 15 },
    ]
  }
];

export {
  day10
}