import React from 'react';
import { Link } from 'react-router-dom';

import './_style.scss';

const Thumb = props => {
  return (
    <div className="sw-thumb">
      <Link to={props.to} className="sw-thumb__link" rel="noopener noreferrer"
      >
        <div className="sw-thumb__mask sw-video-mask">
          <img src={`/images/thumbs/${props.image}.jpg`} alt="" className="sw-thumb__image" />
        </div>
        <div className="sw-thumb__name">{props.name}</div>
        <div className="sw-thumb__desc">{props.desc}</div>
      </Link>
    </div>
  );
}

export default Thumb;