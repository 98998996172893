const list = [
  { name: 'Barriss', value: 'barriss' },
  { name: 'Bo-Katan Kryze', value: 'bokatan' },
  { name: 'Bossk', value: 'bossk' },
  { name: 'Carth Onasi', value: 'carth' },
  { name: 'Chief Chirpa', value: 'chirpa' },
  { name: 'Chief Nebit', value: 'nebit' },
  { name: 'Count Dooku', value: 'dooku' },
  { name: 'CT-7567 "Rex"', value: 'rex' },
  { name: 'Darth Revan', value: 'dr' },
  { name: 'Darth Vader', value: 'vader' },
  { name: 'Emperor Palpatine', value: 'palpatine' },
  { name: 'General Grievous', value: 'gg' },
  { name: 'General Hux', value: 'hux' },
  { name: 'General Kenobi', value: 'gk', toDo: true },
  { name: 'General Skywalker', value: 'gas' },
  { name: 'General Veers', value: 'veers' },
  { name: 'Geonosian Brood Alpha', value: 'gba' },
  { name: 'Grand Admiral Thrawn', value: 'thrawn', toDo: true },
  { name: 'Grand Inquisitor', value: 'ginq' },
  { name: 'Grand Master Yoda', value: 'gmy' },
  { name: 'Hera Syndulla', value: 'hera' },
  { name: 'Hunter', value: 'hunter' },
  { name: 'Jango Fett', value: 'jango' },
  { name: 'Maul', value: 'mando-maul' },
  { name: 'Mon Mothma', value: 'mothma' },
  { name: 'Mother Talzin', value: 'talzin' },
  { name: 'Nute Gunray', value: 'nute' },
  { name: 'Padme Amidala', value: 'padme' },
  { name: 'URoRRuR\'R\'R', value: 'uror' },
  { name: 'Rey (Jedi Training)', value: 'rjt' },
]

const enemies = [
  {
    title: 'Grand Inquisitor',
    image: 'ginq',
    modifiers: [
      {
        name: 'Dark Pursuit',
        text: 'Allies apply Purge whenever they use an ability.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'seventh-sister', speed: 186, bonus: 25 },
      { image: 'second-sister', speed: 186, bonus: 25 },
      { image: 'fifth-brother', speed: 186, bonus: 25 },
      { image: 'eighth-brother', speed: 186, bonus: 25 },
      { image: 'ginq', speed: 183, bonus: 25 },
      { image: 'ninth-sister', speed: 167, bonus: 25 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'jMicw9JcoxU?t=72'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'jMicw9JcoxU?t=135'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'jMicw9JcoxU?t=211'
          },
          {
            name: 'Hunter',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'wrecker' }, { image: 'shaak-ti' }],
            video: 'jMicw9JcoxU?t=286'
          },
          {
            name: 'Maul',
            toons: [{ image: 'maul' }, { image: 'armorer' }, { image: 'bokatan' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'jMicw9JcoxU?t=340'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: 'jMicw9JcoxU?t=389'
          },
          {
            name: 'Beskar Mando',
            toons: [{ image: 'bam' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'jMicw9JcoxU?t=431'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'jMicw9JcoxU?t=504'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'nihilus' }, { image: 'sion' }, { image: 'talon' }, { image: 'set' }],
            video: 'jMicw9JcoxU?t=576'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'jMicw9JcoxU?t=651'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'jMicw9JcoxU?t=697'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'jMicw9JcoxU?t=741'
          },
          {
            name: 'Rey (JT)',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: '3po' }, { image: 'l3' }],
            video: 'jMicw9JcoxU?t=791'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'gideon' }, { image: 'dark-trooper' }, { image: 'range' }],
            video: 'jMicw9JcoxU?t=840'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'jMicw9JcoxU?t=874'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }],
            video: 'jMicw9JcoxU?t=917'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'jMicw9JcoxU?t=969'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'jMicw9JcoxU?t=1011'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'iaesbnxDhb8?t=128'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'iaesbnxDhb8?t=159'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'iaesbnxDhb8?t=180'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'iaesbnxDhb8?t=219'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'iaesbnxDhb8?t=250'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'iaesbnxDhb8?t=295'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'iaesbnxDhb8?t=326'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'zombie' }, { image: 'daka' }, { image: 'spirit' }],
            video: 'iaesbnxDhb8?t=362'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'iaesbnxDhb8?t=399'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'iaesbnxDhb8?t=450'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'iaesbnxDhb8?t=492'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: 'iaesbnxDhb8?t=528'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: 'iaesbnxDhb8?t=572'
          },
          {
            name: 'Thrawn',
            toons: [{ image: 'thrawn' }, { image: 'vader' }, { image: 'shoretrooper' }, { image: 'palpatine' }, { image: 'tarkin' }],
            video: 'iaesbnxDhb8?t=620'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'sith-trooper' }, { image: 'fost' }, { image: 'kru' }],
            video: 'iaesbnxDhb8?t=681'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'iaesbnxDhb8?t=716'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Barriss',
    image: 'barriss',
    modifiers: [
      {
        name: 'Unleashed',
        text: 'Starkiller\'s Omicron is activated.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'mara', speed: 192, bonus: 70 },
      { image: 'starkiller', speed: 162, bonus: 70 },
      { image: 'visas', speed: 146, bonus: 70 },
      { image: 'vader', speed: 141, bonus: 70 },
      { image: 'barriss', speed: 136, bonus: 70 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '7qIwbSTqSdA?t=85'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: '7qIwbSTqSdA?t=155'
          },
          {
            name: 'Maul',
            toons: [{ image: 'maul' }, { image: 'armorer' }, { image: 'bokatan' }, { image: 'canderous' }, { image: 'wat' }],
            video: '7qIwbSTqSdA?t=202'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: '7qIwbSTqSdA?t=268'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: '7qIwbSTqSdA?t=318'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'nihilus' }, { image: 'sion' }, { image: 'talon' }, { image: 'set' }],
            video: '7qIwbSTqSdA?t=374'
          },
          {
            name: 'Mothma',
            toons: [{ image: 'mothma' }, { image: 'kyle' }, { image: 'pao' }, { image: 'hoth-scout' }, { image: 'cara' }],
            video: '7qIwbSTqSdA?t=440'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: '7qIwbSTqSdA?t=484'
          },
          {
            name: 'Rey (JT)',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: '3po' }, { image: 'l3' }],
            video: '7qIwbSTqSdA?t=514'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'gideon' }, { image: 'dark-trooper' }, { image: 'range' }],
            video: '7qIwbSTqSdA?t=562'
          },
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'mara' }, { image: 'thrawn' }, { image: 'shoretrooper' }, { image: 'tarkin' }],
            video: '7qIwbSTqSdA?t=594'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: '7qIwbSTqSdA?t=651'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: '7qIwbSTqSdA?t=708'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: '7qIwbSTqSdA?t=751'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'jsiJmUO98B4?t=67'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'jsiJmUO98B4?t=101'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'jsiJmUO98B4?t=141'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'jsiJmUO98B4?t=187'
          },
          {
            name: 'Thrawn',
            toons: [{ image: 'thrawn' }, { image: 'vader' }, { image: 'tarkin' }, { image: 'rg' }, { image: 'shoretrooper' }],
            video: 'jsiJmUO98B4?t=220'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'jsiJmUO98B4?t=270'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'jsiJmUO98B4?t=315'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'jsiJmUO98B4?t=358'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'zombie' }, { image: 'daka' }, { image: 'spirit' }],
            video: 'jsiJmUO98B4?t=399'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: '3po' }, { image: 'l3' }],
            video: 'jsiJmUO98B4?t=451'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'jsiJmUO98B4?t=506'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'jsiJmUO98B4?t=554'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'qgj' }, { image: 'mace' }],
            video: 'jsiJmUO98B4?t=595'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'jsiJmUO98B4?t=639'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'General Hux',
    image: 'hux',
    modifiers: [
      {
        name: 'Blind Fury',
        text: 'Grants allies stacking 35% critical damage whenever they gain advantage.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: true, two: false, three: false, four: false },
    toons: [
      // { image: 'foo', speed: 165, bonus: 0 },
      { image: 'hux', speed: 161, bonus: 0 },
      { image: 'sith-trooper', speed: 156, bonus: 0 },
      { image: 'kru', speed: 152, bonus: 0 },
      { image: 'fosftp', speed: 150, bonus: 0 },
      { image: 'fotp', speed: 148, bonus: 0 },
      //{ image: 'kylo', speed: 148, bonus: 0 },
      { image: 'phasma', speed: 141, bonus: 0 },
      //{ image: 'fost', speed: 136, bonus: 0 },
      //{ image: 'fox', speed: 131, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: 'cassian' }],
            video: 'btjWeeF5D7g?t=87'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'bokatan' }, { image: 'vet-chewie' }],
            video: 'btjWeeF5D7g?t=134'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'btjWeeF5D7g?t=197'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'phasma' }, { image: 'foo' }, { image: 'hux' }, { image: 'kru' }],
            video: 'btjWeeF5D7g?t=232'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'qgj' }, { image: 'mace' }],
            video: 'btjWeeF5D7g?t=296'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'btjWeeF5D7g?t=370'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: 'btjWeeF5D7g?t=437'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: 'holdo' }],
            video: 'btjWeeF5D7g?t=491'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'btjWeeF5D7g?t=552'
          },
          {
            name: 'Geos',
            toons: [{ image: 'gba' }, { image: 'geo-spy' }, { image: 'geo-soldier' }, { image: 'sun-fac' }, { image: 'poggle' }],
            video: 'btjWeeF5D7g?t=590'
          }
        ]
      }
    ]
  },
  {
    title: 'Hera Syndulla',
    image: 'hera',
    modifiers: [
      {
        name: 'The Odds',
        text: 'Gives allies chance to attack again whenever they score a critical hit. Allies can\'t be countered.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'chopper', speed: 172, bonus: 0 },
      { image: 'sabine', speed: 171, bonus: 0 },
      { image: 'ezra', speed: 163, bonus: 0 },
      { image: 'hera', speed: 152, bonus: 0 },
      { image: 'zeb', speed: 135, bonus: 0 },
      { image: 'kanan', speed: 115, bonus: 0 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: '/uP9VgV3RTC4?t=109'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: '/uP9VgV3RTC4?t=136'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: '/uP9VgV3RTC4?t=165'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: '/uP9VgV3RTC4?t=209'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: '/uP9VgV3RTC4?t=243'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: '/uP9VgV3RTC4?t=289'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: '/uP9VgV3RTC4?t=323'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: '/uP9VgV3RTC4?t=384'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '/uP9VgV3RTC4?t=436'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: '/uP9VgV3RTC4?t=485'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: '/uP9VgV3RTC4?t=527'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: '/uP9VgV3RTC4?t=580'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: '/uP9VgV3RTC4?t=630'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'aayla' }, { image: 'cat' }, { image: 'mace' }, { image: 'qgj' }],
            video: '/uP9VgV3RTC4?t=670'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Padme Amidala',
    image: 'padme',
    modifiers: [
      {
        name: 'Steadfast Retribution',
        text: 'Whenever an ally takes damage, they reflect back 100% of the damage.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: true, one: false, two: false, three: false, four: false },
    toons: [
      { image: 'jka', speed: 164, bonus: 0 },
      { image: '3po', speed: 152, bonus: 20 },
      { image: 'gk', speed: 147, bonus: 0 },
      { image: 'padme', speed: 143, bonus: 0 },
      { image: 'ahsoka', speed: 125, bonus: 50 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'd_VlMxVC3YU?t=78'
          },
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: '3po' }, { image: 'threepio' }],
            video: 'd_VlMxVC3YU?t=117'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'd_VlMxVC3YU?t=144'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'l3' }, { image: 'nest' }, { image: 'kuiil' }, { image: 'vandor-chewie' }],
            video: 'd_VlMxVC3YU?t=183'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'd_VlMxVC3YU?t=220'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'phasma' }, { image: 'kru' }, { image: 'foo' }],
            video: 'd_VlMxVC3YU?t=253'
          },
          {
            name: 'Bad Batch',
            toons: [{ image: 'hunter' }, { image: 'omega' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'd_VlMxVC3YU?t=300'
          },
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'vader' }, { image: 'mara' }, { image: 'thrawn' }, { image: 'shoretrooper' }],
            video: 'd_VlMxVC3YU?t=330'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'snowtrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: 'd_VlMxVC3YU?t=371'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'dengar' }, { image: 'boba' }, { image: 'ig88' }, { image: 'cad' }],
            video: 'd_VlMxVC3YU?t=414'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'ahsoka' }, { image: 'mace' }, { image: 'gk' }],
            video: 'd_VlMxVC3YU?t=462'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'jango' }, { image: 'wat' }],
            video: 'd_VlMxVC3YU?t=507'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'bb' }, { image: 'finn' }, { image: 'holdo' }, { image: 'r2' }],
            video: 'd_VlMxVC3YU?t=557'
          }
        ]
      }
    ]
  },
  {
    title: 'Geonosian Brood Alpha',
    image: 'gba',
    modifiers: [
      {
        name: 'Malicious Minions',
        text: 'Allies get boost to their speed, health, protection and damage.'
      }
    ],
    min: .125,
    max: .125,
    prepared: { none: false, one: true, two: false, three: false, four: false },
    toons: [
      { image: 'geo-soldier', speed: 174, bonus: 0 },
      { image: 'poggle', speed: 164, bonus: 0 },
      { image: 'geo-spy', speed: 147, bonus: 0 },
      { image: 'gba', speed: 136, bonus: 0 },
      { image: 'sun-fac', speed: 129, bonus: 0 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: '3po' }, { image: 'threepio' }],
            video: 'RiS917c_4mU?t=57'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'ig-11' }, { image: 'kuiil' }],
            video: 'RiS917c_4mU?t=93'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'RiS917c_4mU?t=132'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'nute' }, { image: 'magna' }, { image: 'b1' }, { image: 'b2' }],
            video: 'RiS917c_4mU?t=195'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'RiS917c_4mU?t=233'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'phasma' }, { image: 'kru' }, { image: 'foo' }],
            video: 'RiS917c_4mU?t=260'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'RiS917c_4mU?t=307'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'RiS917c_4mU?t=345'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'wat' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }],
            video: 'RiS917c_4mU?t=384'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'zombie' }, { image: 'daka' }, { image: 'spirit' }],
            video: 'RiS917c_4mU?t=427'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'greef' }, { image: 'boba' }, { image: 'jango' }, { image: 'mando' }],
            video: 'RiS917c_4mU?t=485'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'ahsoka' }, { image: 'barriss' }, { image: 'gk' }],
            video: 'RiS917c_4mU?t=535'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'nebit' }, { image: 'datcha' }, { image: 'jawa-scavenger' }, { image: 'jawa-engineer' }],
            video: 'RiS917c_4mU?t=588'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'mace' }, { image: 'cat' }, { image: 'qgj' }, { image: 'aayla' }],
            video: 'RiS917c_4mU?t=626'
          }
        ]
      }
    ]
  },
  {
    title: 'Bossk',
    image: 'bossk',
    modifiers: [
      {
        name: 'Thrill of the Hunt',
        text: 'Allies gain True Defense buff and gain Frenzy when the contract is triggered.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'jango', speed: 178, bonus: 0 },
      { image: 'bossk', speed: 171, bonus: 0 },
      { image: 'boba', speed: 167, bonus: 0 },
      { image: 'aurra', speed: 167, bonus: 0 },
      { image: 'embo', speed: 160, bonus: 0 },
      { image: 'cad', speed: 133, bonus: 0 },
      { image: 'dengar', speed: 129, bonus: 0 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: '_4uZp_QL3SE?t=119'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: '_4uZp_QL3SE?t=150'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: '_4uZp_QL3SE?t=178'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: '_4uZp_QL3SE?t=178'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: '_4uZp_QL3SE?t=247'
          },
          {
            name: 'Bad Batch',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: '_4uZp_QL3SE?t=286'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: '_4uZp_QL3SE?t=325'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: '_4uZp_QL3SE?t=361'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '_4uZp_QL3SE?t=401'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: '_4uZp_QL3SE?t=447'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: '_4uZp_QL3SE?t=490'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: '_4uZp_QL3SE?t=539'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: '_4uZp_QL3SE?t=583'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'aayla' }, { image: 'cat' }, { image: 'mace' }, { image: 'qgj' }],
            video: '_4uZp_QL3SE?t=609'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Count Dooku',
    image: 'dooku',
    modifiers: [
      {
        name: 'Malicious Minions',
        text: 'Allies get boost to their speed, health, protection and damage.'
      }
    ],
    min: .125,
    max: .125,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'asajj', speed: 124, bonus: 75 },
      { image: 'dooku', speed: 187, bonus: 0 },
      { image: 'wat', speed: 186, bonus: 0 },
      { image: 'jango', speed: 178, bonus: 0 },
      { image: 'nute', speed: 176, bonus: 0 },
      { image: 'b1', speed: 165, bonus: 0 },
      { image: 'droideka', speed: 103, bonus: 52 },
      { image: 'b2', speed: 131, bonus: 0 },
      { image: 'gg', speed: 129, bonus: 0 },
      { image: 'magna', speed: 119, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }, { image: 'fallen-basti' }],
            video: 'dRGPgWDBD3w?t=74'
          },
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'dRGPgWDBD3w?t=124'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'ig-11' }, { image: 'kuiil' }],
            video: 'dRGPgWDBD3w?t=166'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'nest' }, { image: 'vandor-chewie' }, { image: 'vet-han' }, { image: 'l3' }],
            video: 'dRGPgWDBD3w?t=214'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'dRGPgWDBD3w?t=279'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }],
            video: 'dRGPgWDBD3w?t=341'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'dRGPgWDBD3w?t=412'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'dRGPgWDBD3w?t=481'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'aayla' }, { image: 'cat' }, { image: 'qgj' }, { image: 'mace' }],
            video: 'dRGPgWDBD3w?t=549'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'rjt' }, { image: 'holdo' }, { image: 'rose' }, { image: 'finn' }],
            video: 'dRGPgWDBD3w?t=624'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: 'dRGPgWDBD3w?t=695'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'dRGPgWDBD3w?t=759'
          },
          {
            name: 'Mothma',
            toons: [{ image: 'mothma' }, { image: 'hoth-scout' }, { image: 'pao' }, { image: 'biggs' }, { image: 'cara' }],
            video: 'dRGPgWDBD3w?t=817'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'dRGPgWDBD3w?t=905'
          }
        ]
      }
    ]
  },
  {
    title: 'Hunter',
    image: 'hunter',
    modifiers: [
      {
        name: 'Dauntless Courage',
        text: 'Allies gain stacking offense and critical damage whenever they gain a buff.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: true, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'bb-echo', speed: 198, bonus: 0 },
      { image: 'tech', speed: 160, bonus: 10 },
      { image: 'wrecker', speed: 152, bonus: 0 },
      { image: 'omega', speed: 150, bonus: 0 },
      { image: 'hunter', speed: 136, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: '3ITpPLPCyug?t=119'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: '3ITpPLPCyug?t=150'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: '3ITpPLPCyug?t=174'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: '3ITpPLPCyug?t=216'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: '3ITpPLPCyug?t=257'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: '3ITpPLPCyug?t=286'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: '3ITpPLPCyug?t=318'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '3ITpPLPCyug?t=372'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: '3ITpPLPCyug?t=412'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: '3ITpPLPCyug?t=457'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: '3ITpPLPCyug?t=511'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: '3ITpPLPCyug?t=549'
          },
          {
            name: 'JML',
            toons: [{ image: 'jedi-basti' }, { image: 'jml' }, { image: 'ben' }, { image: 'kam' }, { image: 'gmy' }],
            video: '3ITpPLPCyug?t=567'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Jango Fett',
    image: 'jango',
    modifiers: [
      {
        name: 'Thrill of the Hunt',
        text: 'Allies gain True Defense buff and gain Frenzy when the contract is triggered.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'jango', speed: 178, bonus: 30 },
      { image: 'bossk', speed: 171, bonus: 30 },
      { image: 'boba', speed: 167, bonus: 30 },
      { image: 'aurra', speed: 167, bonus: 30 },
      { image: 'embo', speed: 160, bonus: 0 },
      { image: 'cad', speed: 133, bonus: 0 },
      { image: 'dengar', speed: 129, bonus: 0 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: '6LA7dIljO8g?t=109'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: '6LA7dIljO8g?t=136'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: '6LA7dIljO8g?t=171'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: '6LA7dIljO8g?t=214'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: '6LA7dIljO8g?t=247'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: '6LA7dIljO8g?t=281'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: '6LA7dIljO8g?t=316'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: '6LA7dIljO8g?t=359'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '6LA7dIljO8g?t=401'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: '6LA7dIljO8g?t=453'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'sith-trooper' }, { image: 'foo' }, { image: 'kru' }],
            video: '6LA7dIljO8g?t=494'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: '6LA7dIljO8g?t=535'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Darth Vader',
    image: 'vader',
    modifiers: [
      {
        name: 'Imperial Edict',
        text: 'Snowtrooper Opeartive is summoned and whenever leader uses a special ability, all allies are called to assist .'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'vader', speed: 141, bonus: 56 },
      { image: 'tie-pilot', speed: 190, bonus: 0 },
      { image: 'piett', speed: 179, bonus: 0 },
      { image: 'tarkin', speed: 154, bonus: 0 },
      { image: 'stormtrooper', speed: 145, bonus: 0 },
      { image: 'palpatine', speed: 142, bonus: 0 },
      { image: 'shoretrooper', speed: 137, bonus: 0 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'JpHSg3c_T2A?t=128'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'JpHSg3c_T2A?t=160'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'JpHSg3c_T2A?t=194'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'JpHSg3c_T2A?t=232'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'JpHSg3c_T2A?t=258'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'JpHSg3c_T2A?t=298'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'JpHSg3c_T2A?t=330'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'JpHSg3c_T2A?t=373'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'JpHSg3c_T2A?t=403'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'JpHSg3c_T2A?t=452'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: 'JpHSg3c_T2A?t=482'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: 'JpHSg3c_T2A?t=526'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'sith-trooper' }, { image: 'fost' }, { image: 'kru' }],
            video: 'JpHSg3c_T2A?t=561'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'JpHSg3c_T2A?t=598'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'General Skywalker',
    image: 'gas',
    modifiers: [
      {
        name: 'Staunch Reprisal / Steadfast Retribution',
        text: 'Whenever an ally unit takes damage, they reflect back 20% / 100% of the damage.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: true, one: false, two: false, three: false, four: false },
    toons: [
      { image: 'gas', speed: 157, bonus: 32 },
      { image: 'rex', speed: 160, bonus: 32 },
      { image: 'arc', speed: 177, bonus: 36 },
      { image: 'fives', speed: 135, bonus: 27 },
      { image: 'ahsoka', speed: 125, bonus: 25 },
    ],
    teams: [
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: '3po' }, { image: 'threepio' }],
            video: 'cAboc3kTPOM?t=292'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'ahsoka' }, { image: 'jka' }, { image: 'gmy' }, { image: 'gk' }],
            video: 'cAboc3kTPOM?t=220'
          },
          {
            name: 'Jedi Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'ben' }, { image: 'jkl' }],
            video: 'cAboc3kTPOM?t=462'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'dengar' }, { image: 'boba' }, { image: 'greef' }, { image: 'mando' }],
            video: 'cAboc3kTPOM?t=360'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'gk' }, { image: 'padme' }, { image: 'shaak-ti' }],
            video: 'cAboc3kTPOM?t=708'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'gideon' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'shoretrooper' }],
            video: 'cAboc3kTPOM?t=768'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'bb' }, { image: 'finn' }, { image: 'holdo' }, { image: 'r2' }],
            video: 'cAboc3kTPOM?t=600'
          }
        ]
      },
      {
        id: 'gone-30',
        counters: []
      }
    ]
  },
  {
    title: 'Mother Talzin',
    image: 'talzin',
    modifiers: [
      {
        name: 'Limitless Magick',
        text: 'Whever an ally is revived, other allies gain stacking health, protection, offense and speed.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: false, four: true },
    toons: [
      { image: 'asajj', speed: 124, bonus: 105 },
      { image: 'spirit', speed: 173, bonus: 30 },
      { image: 'daka', speed: 156, bonus: 30 },
      { image: 'talzin', speed: 142, bonus: 30 },
      { image: 'talia', speed: 135, bonus: 30 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'lzOu-zyf2PQ?t=75'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'lzOu-zyf2PQ?t=116'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'lzOu-zyf2PQ?t=150'
          },
          {
            name: 'Hunter',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'wrecker' }, { image: 'shaak-ti' }],
            video: 'lzOu-zyf2PQ?t=183'
          },
          {
            name: 'Maul',
            toons: [{ image: 'maul' }, { image: 'armorer' }, { image: 'bokatan' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'lzOu-zyf2PQ?t=230'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: 'lzOu-zyf2PQ?t=279'
          },
          {
            name: 'Beskar Mando',
            toons: [{ image: 'bam' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'lzOu-zyf2PQ?t=305'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'lzOu-zyf2PQ?t=346'
          },
          {
            name: 'Mothma',
            toons: [{ image: 'mothma' }, { image: 'kyle' }, { image: 'pao' }, { image: 'hoth-scout' }, { image: 'cara' }],
            video: 'lzOu-zyf2PQ?t=394'
          }
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'lzOu-zyf2PQ?t=449'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'lzOu-zyf2PQ?t=493'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'lzOu-zyf2PQ?t=529'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'gideon' }, { image: 'dark-trooper' }, { image: 'range' }],
            video: 'lzOu-zyf2PQ?t=563'
          },
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'mara' }, { image: 'thrawn' }, { image: 'shoretrooper' }, { image: 'tarkin' }],
            video: 'lzOu-zyf2PQ?t=595'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'lzOu-zyf2PQ?t=635'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'lzOu-zyf2PQ?t=670'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'S74SDT9Bl-g?t=285'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'S74SDT9Bl-g?t=319'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'S74SDT9Bl-g?t=365'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'S74SDT9Bl-g?t=408'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'phasma' }, { image: 'foo' }, { image: 'hux' }, { image: 'kru' }],
            video: 'S74SDT9Bl-g?t=445'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'shoretrooper' }],
            video: 'S74SDT9Bl-g?t=484'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'S74SDT9Bl-g?t=524'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'dooku' }, { image: 'sion' }, { image: 'set' }, { image: 'nihilus' }],
            video: 'S74SDT9Bl-g?t=553'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'S74SDT9Bl-g?t=592'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: 'holdo' }],
            video: 'S74SDT9Bl-g?t=73'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'barriss' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'S74SDT9Bl-g?t=117'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: 'S74SDT9Bl-g?t=153'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'qgj' }, { image: 'mace' }],
            video: 'S74SDT9Bl-g?t=188'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'S74SDT9Bl-g?t=226'
          }
        ]
      }
    ]
  },
  {
    title: 'Chief Chirpa',
    image: 'chirpa',
    modifiers: [
      {
        name: 'Booby Traps',
        text: 'Allies gain counter chance and bonus speed. Enemies attacking out of turn get stunned.'
      }
    ],
    min: .2,
    max: .2,
    prepared: { none: false, one: false, two: false, three: false, four: true },
    toons: [
      { image: '3po', speed: 152, bonus: 20 },
      { image: 'wicket', speed: 166, bonus: 0 },
      { image: 'paploo', speed: 128, bonus: 32 },
      { image: 'ewok-scout', speed: 160, bonus: 0 },
      { image: 'logray', speed: 147, bonus: 0 },
      { image: 'teebo', speed: 138, bonus: 0 },
      { image: 'chirpa', speed: 136, bonus: 0 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'SFVFcDpsTzU?t=331'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'SFVFcDpsTzU?t=354'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'SFVFcDpsTzU?t=381'
          },
          {
            name: 'Hunter',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'wrecker' }, { image: 'shaak-ti' }],
            video: 'SFVFcDpsTzU?t=403'
          },
          {
            name: 'Maul',
            toons: [{ image: 'maul' }, { image: 'armorer' }, { image: 'bokatan' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'SFVFcDpsTzU?t=451'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: 'SFVFcDpsTzU?t=497'
          },
          {
            name: 'Beskar Mando',
            toons: [{ image: 'bam' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'SFVFcDpsTzU?t=546'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'SFVFcDpsTzU?t=579'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'nihilus' }, { image: 'sion' }, { image: 'talon' }, { image: 'set' }],
            video: 'SFVFcDpsTzU?t=615'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'SFVFcDpsTzU?t=64'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'SFVFcDpsTzU?t=104'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'SFVFcDpsTzU?t=138'
          },
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'mara' }, { image: 'thrawn' }, { image: 'shoretrooper' }, { image: 'tarkin' }],
            video: 'SFVFcDpsTzU?t=162'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'SFVFcDpsTzU?t=190'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }],
            video: 'SFVFcDpsTzU?t=217'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'SFVFcDpsTzU?t=247'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'SFVFcDpsTzU?t=247'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'DR',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'fallen-basti' }, { image: 'hk' }, { image: 'marauder' }],
            video: 'eqeMYFrxeXE?t=70'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'eqeMYFrxeXE?t=117'
          },
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'eqeMYFrxeXE?t=167'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'bokatan' }],
            video: 'eqeMYFrxeXE?t=207'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'ig-11' }, { image: 'kuiil' }, { image: 'l3' }, { image: 'vandor-chewie' }],
            video: 'eqeMYFrxeXE?t=258'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'eqeMYFrxeXE?t=305'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'shaak-ti' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'eqeMYFrxeXE?t=358'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'wat' }],
            video: 'eqeMYFrxeXE?t=434'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'eqeMYFrxeXE?t=499'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'droideka' }, { image: 'magna' }],
            video: 'eqeMYFrxeXE?t=542'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'shoretrooper' }, { image: 'dark-trooper' }, { image: 'gideon' }],
            video: 'eqeMYFrxeXE?t=584'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'dooku' }, { image: 'talon' }],
            video: 'eqeMYFrxeXE?t=624'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'dengar' }, { image: 'zam' }],
            video: 'eqeMYFrxeXE?t=703'
          }
        ]
      }
    ]
  },
  {
    title: 'CT-7567 "Rex"',
    image: 'rex',
    modifiers: [
      {
        name: 'Dauntless Courage',
        text: 'Allies gain stacking offense and critical damage whenever they gain a buff.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'arc', speed: 177, bonus: 29 },
      { image: 'rex', speed: 160, bonus: 26 },
      { image: 'echo', speed: 149, bonus: 24 },
      { image: 'fives', speed: 135, bonus: 22 },
      { image: 'cody', speed: 135, bonus: 0 }
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'R7b5rKFTghA?t=398'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'R7b5rKFTghA?t=440'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'R7b5rKFTghA?t=471'
          },
          {
            name: 'Hunter',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'wrecker' }, { image: 'shaak-ti' }],
            video: 'R7b5rKFTghA?t=494'
          },
          {
            name: 'Maul',
            toons: [{ image: 'maul' }, { image: 'armorer' }, { image: 'bokatan' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'R7b5rKFTghA?t=532'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: 'R7b5rKFTghA?t=570'
          },
          {
            name: 'Beskar Mando',
            toons: [{ image: 'bam' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'R7b5rKFTghA?t=595'
          }
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'R7b5rKFTghA?t=52'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'R7b5rKFTghA?t=86'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'R7b5rKFTghA?t=122'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'gideon' }, { image: 'dark-trooper' }, { image: 'range' }],
            video: 'R7b5rKFTghA?t=154'
          },
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'mara' }, { image: 'thrawn' }, { image: 'shoretrooper' }, { image: 'tarkin' }],
            video: 'R7b5rKFTghA?t=189'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'R7b5rKFTghA?t=228'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }],
            video: 'R7b5rKFTghA?t=253'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'R7b5rKFTghA?t=281'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'R7b5rKFTghA?t=326'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: '1d4qFBtLQds?t=83'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: '1d4qFBtLQds?t=112'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: '1d4qFBtLQds?t=142'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: '1d4qFBtLQds?t=184'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: '1d4qFBtLQds?t=210'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: '1d4qFBtLQds?t=250'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: '1d4qFBtLQds?t=281'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'kylo' }, { image: 'sith-trooper' }, { image: 'kru' }, { image: 'fost' }],
            video: '1d4qFBtLQds?t=309'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '1d4qFBtLQds?t=348'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: '1d4qFBtLQds?t=393'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: '1d4qFBtLQds?t=393'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'dark-trooper' }, { image: 'range' }, { image: 'shoretrooper' }],
            video: '1d4qFBtLQds?t=468'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: '1d4qFBtLQds?t=508'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'mace' }, { image: 'qgj' }],
            video: '1d4qFBtLQds?t=532'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Grand Master Yoda',
    image: 'gmy',
    modifiers: [
      {
        name: 'Humble Strike',
        text: 'Allies gain stacking critical damage whenever an enemy buff is dispelled or expires.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'shaak-ti', speed: 187, bonus: 0 },
      { image: 'gmy', speed: 177, bonus: 0 },
      { image: 'qgj', speed: 165, bonus: 0 },
      { image: 'jka', speed: 164, bonus: 0 },
      { image: 'kam', speed: 154, bonus: 0 },
      { image: 'ahsoka', speed: 125, bonus: 50 },
      { image: 'gk', speed: 147, bonus: 0 },
      { image: 'mace', speed: 143, bonus: 0 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'l4vwRHoeDQc?t=438'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'l4vwRHoeDQc?t=496'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'l4vwRHoeDQc?t=551'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: 'l4vwRHoeDQc?t=602'
          },
          {
            name: 'Beskar Mando',
            toons: [{ image: 'bam' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'l4vwRHoeDQc?t=658'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'l4vwRHoeDQc?t=700'
          },
          {
            name: 'Mothma',
            toons: [{ image: 'mothma' }, { image: 'kyle' }, { image: 'hoth-scout' }, { image: 'cara' }, { image: 'pao' }],
            video: 'l4vwRHoeDQc?t=760'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'l4vwRHoeDQc?t=73'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'l4vwRHoeDQc?t=120'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'l4vwRHoeDQc?t=156'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'gideon' }, { image: 'dark-trooper' }, { image: 'range' }],
            video: 'l4vwRHoeDQc?t=205'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'l4vwRHoeDQc?t=234'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }],
            video: 'l4vwRHoeDQc?t=277'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'l4vwRHoeDQc?t=334'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'l4vwRHoeDQc?t=387'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'FJ_zJxKnqok?t=549'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'ig-11' }, { image: 'kuiil' }],
            video: 'FJ_zJxKnqok?t=591'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'nest' }, { image: 'vandor-chewie' }, { image: 'qira' }, { image: 'l3' }],
            video: 'FJ_zJxKnqok?t=641'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'FJ_zJxKnqok?t=710'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'FJ_zJxKnqok?t=764'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'shoretrooper' }, { image: 'mara' }],
            video: 'FJ_zJxKnqok?t=84'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'barriss' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'FJ_zJxKnqok?t=199'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'rjt' }, { image: 'holdo' }, { image: 'bb' }, { image: 'finn' }],
            video: 'FJ_zJxKnqok?t=263'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'zam' }, { image: 'greef' }, { image: 'mando' }],
            video: 'FJ_zJxKnqok?t=341'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'FJ_zJxKnqok?t=417'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'FJ_zJxKnqok?t=472'
          }
        ]
      }
    ]
  },
  {
    title: 'General Grievous',
    image: 'gg',
    modifiers: [
      {
        name: 'Malicious Minions',
        text: 'Allies get boost to their speed, health, protection and damage.'
      }
    ],
    min: .125,
    max: .125,
    prepared: { none: true, one: false, two: false, three: false, four: false },
    toons: [
      { image: 'wat', speed: 186, bonus: 0 },
      { image: 'b1', speed: 165, bonus: 0 },
      { image: 'droideka', speed: 103, bonus: 52 },
      { image: 'b2', speed: 131, bonus: 0 },
      { image: 'gg', speed: 129, bonus: 0 },
      { image: 'magna', speed: 119, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'IueqCEYOIPU?t=128&si=jvWTdK6ouI1O-UJq'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'IueqCEYOIPU?t=160&si=0gcnOwat_3jk2oCo'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'IueqCEYOIPU?t=193&si=Jl-jVu19TJxvSduj'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'IueqCEYOIPU?t=240&si=7qBqitnh2TWlMOSD'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'IueqCEYOIPU?t=293&si=L-xosidv1d0NtAOu'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'IueqCEYOIPU?t=346&si=4ThREJ9EPmC8_FIp'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'IueqCEYOIPU?t=389&si=wZ5_Au-7UkcfzWfF'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'IueqCEYOIPU?t=429&si=7UjFExK5P5w26xAo'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'IueqCEYOIPU?t=476&si=lwhRNTwgH_zdf1YP'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'spirit' }, { image: 'zombie' }, { image: 'daka' }, { image: 'asajj' }],
            video: 'IueqCEYOIPU?t=509&si=37WxLXVkkLVw2l9a'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'sith-trooper' }, { image: 'fost' }, { image: 'kru' }],
            video: 'IueqCEYOIPU?t=554&si=u7EIAQRiNyjJiHid'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'mace' }, { image: 'cat' }, { image: 'aayla' }, { image: 'qgj' }],
            video: 'IueqCEYOIPU?t=580&si=fOynOlEMZnoQaG9k'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'General Kenobi',
    image: 'gk',
    modifiers: [
      {
        name: 'Humble Strike',
        text: 'Allies gain stacking critical damage whenever an enemy buff is dispelled or expires.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'shaak-ti', speed: 187, bonus: 0 },
      { image: 'gmy', speed: 177, bonus: 0 },
      { image: 'qgj', speed: 165, bonus: 0 },
      { image: 'jka', speed: 164, bonus: 0 },
      { image: 'kam', speed: 154, bonus: 0 },
      { image: 'gk', speed: 147, bonus: 0 },
      { image: 'aayla', speed: 145, bonus: 0 },
      { image: 'mace', speed: 143, bonus: 0 },
      { image: 'ahsoka', speed: 125, bonus: 0 }
    ],
    counters: [
      { image: 'padme', disks: ['entrenched'] },
      { image: 'rjt', disks: ['entrenched'] },
      { image: 'cls', disks: ['amplify-agony', 'volatile-accelerator'] }
    ]
  },
  {
    title: 'Mon Mothma',
    image: 'mothma',
    modifiers: [
      {
        name: 'The Odds',
        text: 'Gives allies chance to attack again whenever they score a critical hit. Allies can\'t be countered.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'chirrut', speed: 173, bonus: 0 },
      { image: 'bistan', speed: 165, bonus: 0 },
      { image: 'pao', speed: 165, bonus: 0 },
      { image: 'mothma', speed: 111, bonus: 50 },
      { image: 'pathfinder', speed: 141, bonus: 0 },
      { image: 'baze', speed: 110, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }, { image: 'fallen-basti' }],
            video: 'askWDGb4QYw?t=333'
          },
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'askWDGb4QYw?t=371'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'ig-11' }, { image: 'kuiil' }],
            video: 'askWDGb4QYw?t=400'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'nest' }, { image: 'vandor-chewie' }, { image: 'vet-han' }, { image: 'l3' }],
            video: 'askWDGb4QYw?t=444'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'askWDGb4QYw?t=511'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'askWDGb4QYw?t=570'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'askWDGb4QYw?t=607'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'wat' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }],
            video: 'askWDGb4QYw?t=635'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'rg' }, { image: 'tarkin' }, { image: 'thrawn' }],
            video: 'askWDGb4QYw?t=681'
          },
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: 'askWDGb4QYw?t=70'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'askWDGb4QYw?t=140'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'barriss' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'askWDGb4QYw?t=208'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'datcha' }, { image: 'nebit' }, { image: 'jawa-scavenger' }, { image: 'jawa-engineer' }],
            video: 'askWDGb4QYw?t=277'
          },
        ]
      }
    ]
  },
  {
    title: 'General Veers',
    image: 'veers',
    modifiers: [
      {
        name: 'Endless Ranks',
        text: 'Allies get 3 revives shared amongst them.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: true, two: false, three: false, four: false },
    toons: [
      { image: 'piett', speed: 179, bonus: 20 },
      { image: 'starck', speed: 175, bonus: 20 },
      { image: 'veers', speed: 157, bonus: 20 },
      { image: 'range', speed: 147, bonus: 20 },
      { image: 'death-trooper', speed: 144, bonus: 20 },
      { image: 'shoretrooper', speed: 137, bonus: 20 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'paaX4I2Wdkc?t=83'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'paaX4I2Wdkc?t=133'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'paaX4I2Wdkc?t=171'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'foo' }, { image: 'hux' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'paaX4I2Wdkc?t=225'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'bb-echo' }, { image: 'wrecker' }, { image: 'hunter' }, { image: 'tech' }],
            video: 'paaX4I2Wdkc?t=274'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'mara' }, { image: 'rg' }, { image: 'stormtrooper' }, { image: 'thrawn' }],
            video: 'paaX4I2Wdkc?t=319'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'jkr' }, { image: 'jka' }],
            video: 'paaX4I2Wdkc?t=371'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'paaX4I2Wdkc?t=425'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'paaX4I2Wdkc?t=476'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'paaX4I2Wdkc?t=516'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'vandor-chewie' }],
            video: 'paaX4I2Wdkc?t=547'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'aYDxIeJK1hE?t=105'
          },
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'aYDxIeJK1hE?t=130'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'bokatan' }],
            video: 'aYDxIeJK1hE?t=160'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'kuiil' }, { image: 'vandor-chewie' }],
            video: 'aYDxIeJK1hE?t=203'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'wat' }],
            video: 'aYDxIeJK1hE?t=230'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'aYDxIeJK1hE?t=280'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'aYDxIeJK1hE?t=312'
          },
          {
            name: 'DR',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'fallen-basti' }, { image: 'hk' }, { image: 'marauder' }],
            video: 'aYDxIeJK1hE?t=350'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'shoretrooper' }],
            video: 'aYDxIeJK1hE?t=387'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'phasma' }, { image: 'foo' }, { image: 'hux' }, { image: 'kru' }],
            video: 'aYDxIeJK1hE?t=422'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: 'aYDxIeJK1hE?t=472'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'barriss' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'aYDxIeJK1hE?t=531'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'mace' }, { image: 'qgj' }],
            video: 'aYDxIeJK1hE?t=583'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: 'holdo' }],
            video: 'aYDxIeJK1hE?t=632'
          }
        ]
      }
    ]
  },
  {
    title: 'Carth Onasi',
    image: 'carth',
    modifiers: [
      {
        name: 'Sweeping Strikes',
        text: 'Allies gain bonus turn meter whenever they resist detrimental effects. Whenever they use basic ability they also deal true damage to all enemies.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: true, two: false, three: false, four: false },
    toons: [
      { image: 'nest', speed: 179, bonus: 0 },
      { image: 'carth', speed: 179, bonus: 0 },
      { image: 'mission', speed: 154, bonus: 0 },
      { image: 'canderous', speed: 142, bonus: 0 },
      { image: 'zaalbar', speed: 126, bonus: 0 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'tqoQ__qusvs?t=67'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'tqoQ__qusvs?t=122'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'tqoQ__qusvs?t=156'
          },
          {
            name: 'Bad Batch',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'shaak-ti' }, { image: 'wrecker' }],
            video: 'tqoQ__qusvs?t=216'
          },
          {
            name: 'Jango',
            toons: [{ image: 'jango' }, { image: 'greef' }, { image: 'mando' }, { image: 'bossk' }, { image: 'bam' }],
            video: 'tqoQ__qusvs?t=273'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'tqoQ__qusvs?t=333'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'tqoQ__qusvs?t=397'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'tqoQ__qusvs?t=427'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'tqoQ__qusvs?t=453'
          },
          {
            name: 'Rey (JT)',
            toons: [{ image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: '3po' }, { image: 'l3' }],
            video: 'tqoQ__qusvs?t=480'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'tqoQ__qusvs?t=529'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'tqoQ__qusvs?t=573'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'DR',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'hk' }, { image: 'malak' }, { image: 'marauder' }],
            video: 'VAI910jloFE?t=246'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'VAI910jloFE?t=281'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'VAI910jloFE?t=321'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }, { image: 'bb-echo' }],
            video: 'VAI910jloFE?t=348'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'death-trooper' }],
            video: 'VAI910jloFE?t=388'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'capt-han' }, { image: 'cat' }],
            video: 'VAI910jloFE?t=428'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'mace' }, { image: 'qgj' }, { image: 'cat' }, { image: 'aayla' }],
            video: 'VAI910jloFE?t=471'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'malak' }, { image: 'dooku' }],
            video: 'VAI910jloFE?t=86'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'consular' }, { image: 'gk' }],
            video: 'VAI910jloFE?t=150'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'nebit' }, { image: 'datcha' }, { image: 'jawa' }, { image: 'jawa-scavenger' }],
            video: 'VAI910jloFE?t=199'
          }
        ]
      }
    ]
  },
  {
    title: 'Grand Admiral Thrawn',
    image: 'thrawn',
    modifiers: [
      {
        name: 'Imperial Edict',
        text: 'Snowtrooper Opeartive is summoned and whenever leader uses a special ability, all allies are called to assist .'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'vader', speed: 141, bonus: 56 },
      { image: 'tie-pilot', speed: 190, bonus: 0 },
      { image: 'thrawn', speed: 170, bonus: 0 },
      { image: 'tarkin', speed: 154, bonus: 0 },
      { image: 'palpatine', speed: 142, bonus: 0 },
    ],
    counters: [
      { image: 'rjt' },
      { image: 'jkl' },
      { image: 'gg' },
      { image: 'padme', disks: ['entrenched'] },
      { image: 'cls', disks: ['amplify-agony', 'volatile-accelerator'] }
    ]
  },
  {
    title: 'Emperor Palpatine',
    image: 'palpatine',
    modifiers: [
      {
        name: 'Imperial Edict',
        text: 'Snowtrooper Opeartive is summoned and whenever leader uses a special ability, all allies are called to assist.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'vader', speed: 141, bonus: 56 },
      { image: 'piett', speed: 179, bonus: 0 },
      { image: 'tie-pilot', speed: 190, bonus: 0 },
      { image: 'thrawn', speed: 170, bonus: 0 },
      { image: 'stormtrooper', speed: 145, bonus: 0 },
      { image: 'palpatine', speed: 142, bonus: 0 },
      { image: 'shoretrooper', speed: 137, bonus: 0 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'MXp2Toaw-eY?t=77'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'MXp2Toaw-eY?t=104'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'MXp2Toaw-eY?t=132'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'MXp2Toaw-eY?t=172'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'MXp2Toaw-eY?t=197'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'MXp2Toaw-eY?t=232'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'MXp2Toaw-eY?t=295'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: 'MXp2Toaw-eY?t=322'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: 'MXp2Toaw-eY?t=358'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'sith-trooper' }, { image: 'fost' }, { image: 'kru' }],
            video: 'MXp2Toaw-eY?t=392'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'MXp2Toaw-eY?t=435'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'qgj' }, { image: 'mace' }],
            video: 'MXp2Toaw-eY?t=460'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'URoRRuR\'R\'R',
    image: 'uror',
    modifiers: [
      {
        name: 'Tusken Ambush!',
        text: 'Run... It\'s and ambush :)'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'uror', speed: 167, bonus: 0 },
      { image: 'tusken-shaman', speed: 157, bonus: 0 },
      { image: 'tusken-raider', speed: 151, bonus: 0 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'H-fIIWIHy08?t=465'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'H-fIIWIHy08?t=500'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'H-fIIWIHy08?t=533'
          },
          {
            name: 'Hunter',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'wrecker' }, { image: 'shaak-ti' }],
            video: 'H-fIIWIHy08?t=560'
          },
          {
            name: 'Maul',
            toons: [{ image: 'maul' }, { image: 'armorer' }, { image: 'bokatan' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'H-fIIWIHy08?t=598'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: '3po' }, { image: 'threepio' }, { image: 'han' }, { image: 'chewie' }],
            video: 'H-fIIWIHy08?t=629'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'H-fIIWIHy08?t=663'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'nihilus' }, { image: 'sion' }, { image: 'set' }, { image: 'talon' }],
            video: 'H-fIIWIHy08?t=692'
          },
          {
            name: 'Mothma',
            toons: [{ image: 'mothma' }, { image: 'kyle' }, { image: 'pao' }, { image: 'hoth-scout' }, { image: 'cara' }],
            video: 'H-fIIWIHy08?t=722'
          },
          {
            name: 'Beskar Mando',
            toons: [{ image: 'bam' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'H-fIIWIHy08?t=749'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'H-fIIWIHy08?t=62'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'gk' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'H-fIIWIHy08?t=102'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'H-fIIWIHy08?t=135'
          },
          {
            name: 'Rey (JT)',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: '3po' }, { image: 'l3' }],
            video: 'H-fIIWIHy08?t=161'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'piett' }, { image: 'gideon' }, { image: 'dark-trooper' }, { image: 'range' }],
            video: 'H-fIIWIHy08?t=189'
          },
          {
            name: 'Palpatine',
            toons: [{ image: 'palpatine' }, { image: 'mara' }, { image: 'thrawn' }, { image: 'shoretrooper' }, { image: 'tarkin' }],
            video: 'H-fIIWIHy08?t=228'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'H-fIIWIHy08?t=251'
          },
          {
            name: 'Geos',
            toons: [{ image: 'gba' }, { image: 'geo-spy' }, { image: 'sun-fac' }, { image: 'poggle' }, { image: 'geo-soldier' }],
            video: 'H-fIIWIHy08?t=292'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }],
            video: 'H-fIIWIHy08?t=324'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'H-fIIWIHy08?t=360'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'H-fIIWIHy08?t=397'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'FhKQoeksLxM?t=99'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'FhKQoeksLxM?t=129'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'FhKQoeksLxM?t=166'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'FhKQoeksLxM?t=215'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'FhKQoeksLxM?t=244'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'FhKQoeksLxM?t=285'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'FhKQoeksLxM?t=324'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'asajj' }, { image: 'zombie' }, { image: 'daka' }, { image: 'spirit' }],
            video: 'FhKQoeksLxM?t=356'
          },
          {
            name: 'Sortie',
            toons: [{ image: 'sortie' }, { image: 'bb' }, { image: 'r2' }, { image: '3po' }, { image: 'l3' }],
            video: 'FhKQoeksLxM?t=395'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'FhKQoeksLxM?t=443'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'FhKQoeksLxM?t=483'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: 'FhKQoeksLxM?t=518'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: 'FhKQoeksLxM?t=565'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'nest' }, { image: 'qira' }, { image: 'l3' }, { image: 'vandor-chewie' }],
            video: 'FhKQoeksLxM?t=610'
          },
          {
            name: 'Geos',
            toons: [{ image: 'gba' }, { image: 'geo-spy' }, { image: 'sun-fac' }, { image: 'poggle' }, { image: 'geo-soldier' }],
            video: 'FhKQoeksLxM?t=657'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'sith-trooper' }, { image: 'fost' }, { image: 'kru' }],
            video: 'FhKQoeksLxM?t=701'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'FhKQoeksLxM?t=740'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Rey (Jedi Training)',
    image: 'rjt',
    modifiers: [
      {
        name: 'Fearless Fortitued',
        text: 'Whenever an ally gains a buff, they also gain stacking critical damage and bonus turn meter.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: true, two: false, three: false, four: false },
    toons: [
      { image: 'hero-finn', speed: 183, bonus: 0 },
      { image: 'r2', speed: 177, bonus: 0 },
      { image: 'hero-poe', speed: 174, bonus: 0 },
      { image: 'rjt', speed: 160, bonus: 0 },
      { image: 'holdo', speed: 110, bonus: 50 },
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: '18c-NGhyJL8?t=369'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: '18c-NGhyJL8?t=400'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: '18c-NGhyJL8?t=442'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: '18c-NGhyJL8?t=491'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: '18c-NGhyJL8?t=520'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'wat' }],
            video: '18c-NGhyJL8?t=572'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: '18c-NGhyJL8?t=611'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'phasma' }, { image: 'foo' }, { image: 'hux' }, { image: 'kru' }],
            video: '18c-NGhyJL8?t=643'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'mace' }, { image: 'qgj' }, { image: 'gk' }, { image: 'aayla' }],
            video: '18c-NGhyJL8?t=682'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'gideon' }, { image: 'piett' }, { image: 'dark-trooper' }],
            video: '18c-NGhyJL8?t=735'
          },
          {
            name: 'Veeres',
            toons: [{ image: 'veers' }, { image: 'shoretrooper' }, { image: 'range' }, { image: 'starck' }, { image: 'death-trooper' }],
            video: '18c-NGhyJL8?t=794'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'kuiil' }, { image: 'nest' }, { image: 'ig-11' }, { image: 'vandor-chewie' }],
            video: '18c-NGhyJL8?t=848'
          },
          {
            name: 'Sortie',
            toons: [{ image: 'sortie' }, { image: 'bb' }, { image: 'r2' }, { image: '3po' }, { image: 'hk' }],
            video: '18c-NGhyJL8?t=923'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }, { image: 'asajj' }],
            video: '18c-NGhyJL8?t=73'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'nebit' }, { image: 'datcha' }, { image: 'jawa' }, { image: 'jawa-scavenger' }],
            video: '18c-NGhyJL8?t=128'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: '18c-NGhyJL8?t=163'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: '18c-NGhyJL8?t=223'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'kru' }, { image: 'sith-trooper' }, { image: 'foo' }, { image: 'fox' }],
            video: '18c-NGhyJL8?t=290'
          }
        ]
      }
    ]
  },
  {
    title: 'Darth Revan',
    image: 'dr',
    modifiers: [
      {
        name: 'Grim Machination',
        text: 'Whenever an ally is defeated, all allies gain stacking health, offense and protection. Additionally, allies also gain bonus turn meter and recover health.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: true, one: true, two: false, three: false, four: false },
    toons: [
      { image: 'dr', speed: 174, bonus: 0 },
      { image: 'malak', speed: 173, bonus: 0 },
      { image: 'marauder', speed: 162, bonus: 0 },
      { image: 'fallen-basti', speed: 156, bonus: 0 },
      { image: 'hk', speed: 123, bonus: 0 },
      { image: 'set', speed: 115, bonus: 0 },
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'gS58P818Ys8?t=295'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'gS58P818Ys8?t=355'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'gS58P818Ys8?t=428'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'kru' }, { image: 'hux' }, { image: 'foo' }, { image: 'sith-trooper' }],
            video: 'gS58P818Ys8?t=492'
          }
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'gS58P818Ys8?t=54'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'gk' }],
            video: 'gS58P818Ys8?t=110'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'gS58P818Ys8?t=150'
          },
          {
            name: 'Rey (JT)',
            toons: [{ image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: '3po' }, { image: 'l3' }],
            video: 'gS58P818Ys8?t=200'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'gS58P818Ys8?t=247'
          }
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: '3po' }, { image: 'threepio' }],
            video: 'K5xsmmwCfog?t=465'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'ig-11' }, { image: 'kuiil' }],
            video: 'K5xsmmwCfog?t=530'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'shaak-ti' }, { image: 'kam' }],
            video: 'K5xsmmwCfog?t=675'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'K5xsmmwCfog?t=808'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'starck' }, { image: 'range' }, { image: 'dark-trooper' }, { image: 'piett' }],
            video: 'K5xsmmwCfog?t=866'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'K5xsmmwCfog?t=592'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'phasma' }, { image: 'kru' }, { image: 'foo' }],
            video: 'K5xsmmwCfog?t=756'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'dengar' }, { image: 'boba' }, { image: 'greef' }, { image: 'mando' }],
            video: 'K5xsmmwCfog?t=210'
          },
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'bb' }, { image: 'finn' }, { image: 'holdo' }, { image: 'r2' }],
            video: 'K5xsmmwCfog?t=342'
          }
        ]
      }
    ]
  },
  {
    title: 'Nute Gunray',
    image: 'nute',
    modifiers: [
      {
        name: 'Malicious Minions',
        text: 'Allies get boost to their speed, health, protection and damage.'
      }
    ],
    min: .125,
    max: .125,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    toons: [
      { image: 'asajj', speed: 124, bonus: 75 },
      { image: 'dooku', speed: 187, bonus: 0 },
      { image: 'wat', speed: 186, bonus: 0 },
      { image: 'jango', speed: 178, bonus: 0 },
      { image: 'nute', speed: 176, bonus: 0 },
      { image: 'b1', speed: 165, bonus: 0 },
      { image: 'gba', speed: 160, bonus: 0 },
      { image: 'droideka', speed: 103, bonus: 52 },
      { image: 'b2', speed: 131, bonus: 0 },
      { image: 'gg', speed: 129, bonus: 0 },
      { image: 'magna', speed: 119, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'Dt8o6sKrbq0?t=93'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'Dt8o6sKrbq0?t=157'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jka' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jkr' }],
            video: 'Dt8o6sKrbq0?t=199'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'Dt8o6sKrbq0?t=257'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'fives' }, { image: 'rex' }, { image: 'arc' }],
            video: 'Dt8o6sKrbq0?t=288'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'bb-echo' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'tech' }],
            video: 'Dt8o6sKrbq0?t=328'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'canderous' }, { image: 'wat' }],
            video: 'Dt8o6sKrbq0?t=384'
          },
          {
            name: 'RJT',
            toons: [{ image: 'rjt' }, { image: 'bb' }, { image: 'r2' }, { image: 'ig-11' }, { image: 'l3' }],
            video: 'Dt8o6sKrbq0?t=430'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: '3po' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'Dt8o6sKrbq0?t=490'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'fallen-basti' }, { image: 'malak' }, { image: 'marauder' }, { image: 'hk' }],
            video: 'Dt8o6sKrbq0?t=543'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'set' }, { image: 'talon' }],
            video: 'Dt8o6sKrbq0?t=592'
          },
          {
            name: 'Iden',
            toons: [{ image: 'iden' }, { image: 'shoretrooper' }, { image: 'death-trooper' }, { image: 'magma' }, { image: 'stormtrooper' }],
            video: 'Dt8o6sKrbq0?t=652'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'Dt8o6sKrbq0?t=705'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'aayla' }, { image: 'cat' }, { image: 'mace' }, { image: 'qgj' }],
            video: 'Dt8o6sKrbq0?t=734'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: []
      }
    ]
  },
  {
    title: 'Maul',
    image: 'mando-maul',
    modifiers: [
      {
        name: 'The Code',
        text: 'Whenever and ally takes damage, all allies gain stacking counter chance and protection.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'mando-maul', speed: 189, bonus: 0 },
      { image: 'armorer', speed: 186, bonus: 0 },
      { image: 'jango', speed: 178, bonus: 0 },
      { image: 'bokatan', speed: 152, bonus: 0 },
      { image: 'canderous', speed: 142, bonus: 0 }
    ],
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: '3po' }, { image: 'threepio' }],
            video: 'Ct2d_m2RzyU?t=520'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'Ct2d_m2RzyU?t=570'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'l3' }, { image: 'nest' }, { image: 'kuiil' }, { image: 'vandor-chewie' }],
            video: 'Ct2d_m2RzyU?t=611'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'nute' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }],
            video: 'Ct2d_m2RzyU?t=701'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'Ct2d_m2RzyU?t=742'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'padme' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'Ct2d_m2RzyU?t=787'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'hux' }, { image: 'phasma' }, { image: 'kru' }, { image: 'foo' }],
            video: 'Ct2d_m2RzyU?t=850'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'dengar' }, { image: 'boba' }, { image: 'mando' }, { image: 'greef' }],
            video: 'Ct2d_m2RzyU?t=51'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'ahsoka' }, { image: 'bb-echo' }, { image: 'gk' }],
            video: 'Ct2d_m2RzyU?t=156'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'talon' }, { image: 'set' }],
            video: 'Ct2d_m2RzyU?t=217'
          },
          {
            name: 'Thrawn',
            toons: [{ image: 'thrawn' }, { image: 'vader' }, { image: 'gideon' }, { image: 'wat' }, { image: 'gba' }],
            video: 'Ct2d_m2RzyU?t=296'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'Ct2d_m2RzyU?t=429'
          }
        ]
      }
    ]
  },
  {
    title: 'Bo-Katan Kryze',
    image: 'bokatan',
    modifiers: [
      {
        name: 'The Code',
        text: 'Whenever and ally takes damage, all allies gain stacking counter chance and protection.'
      }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: false, three: true, four: false },
    toons: [
      { image: 'armorer', speed: 186, bonus: 0 },
      { image: 'jango', speed: 178, bonus: 0 },
      { image: 'sabine', speed: 171, bonus: 0 },
      { image: 'bokatan', speed: 152, bonus: 0 },
      { image: 'canderous', speed: 142, bonus: 0 }
    ],
    teams: [
      {
        id: 'stoneskin',
        counters: [
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'cat' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'WNCYNDhH0T4?t=97'
          },
          {
            name: 'Darth Revan',
            toons: [{ image: 'dr' }, { image: 'malak' }, { image: 'hk' }, { image: 'fallen-basti' }, { image: 'marauder' }],
            video: 'WNCYNDhH0T4?t=161'
          },
          {
            name: 'General Skywalker',
            toons: [{ image: 'gas' }, { image: 'echo' }, { image: 'rex' }, { image: 'fives' }, { image: 'arc' }],
            video: 'WNCYNDhH0T4?t=207'
          },
          {
            name: 'Bad Batch',
            toons: [{ image: 'hunter' }, { image: 'bb-echo' }, { image: 'tech' }, { image: 'shaak-ti' }, { image: 'wrecker' }],
            video: 'WNCYNDhH0T4?t=252'
          },
          {
            name: 'Veers',
            toons: [{ image: 'veers' }, { image: 'stormtrooper' }, { image: 'piett' }, { image: 'dark-trooper' }, { image: 'starck' }],
            video: 'WNCYNDhH0T4?t=292'
          },
          {
            name: 'Commander Luke',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: '3po' }, { image: 'threepio' }],
            video: 'WNCYNDhH0T4?t=317'
          },
          {
            name: 'General Grievous',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'magna' }, { image: 'nute' }],
            video: 'WNCYNDhH0T4?t=348'
          },
          {
            name: 'Mon Mothma',
            toons: [{ image: 'mothma' }, { image: 'kyle' }, { image: 'pao' }, { image: 'hoth-scout' }, { image: 'cara' }],
            video: 'WNCYNDhH0T4?t=384'
          },
        ]
      },
      {
        id: 'overzealous',
        counters: [
          {
            name: 'Bastila',
            toons: [{ image: 'jedi-basti' }, { image: 'jolee' }, { image: 'yoda' }, { image: 'shaak-ti' }, { image: 'ben' }],
            video: 'WNCYNDhH0T4?t=474'
          },
          {
            name: 'Rex',
            toons: [{ image: 'rex' }, { image: 'echo' }, { image: 'fives' }, { image: 'shaak-ti' }, { image: 'arc' }],
            video: 'WNCYNDhH0T4?t=504'
          },
          {
            name: 'Rey (JT)',
            toons: [{ image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: '3po' }, { image: 'l3' }],
            video: 'WNCYNDhH0T4?t=544'
          },
          {
            name: 'Hux',
            toons: [{ image: 'hux' }, { image: 'foo' }, { image: 'fox' }, { image: 'phasma' }, { image: 'kru' }],
            video: 'WNCYNDhH0T4?t=578'
          },
          {
            name: 'Raddus',
            toons: [{ image: 'admiralraddus' }, { image: 'jyn' }, { image: 'pathfinder' }, { image: 'cassian' }, { image: 'k2' }],
            video: 'WNCYNDhH0T4?t=607'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'bam' }, { image: 'ig-11' }, { image: 'qira' }, { image: 'kuiil' }],
            video: 'WNCYNDhH0T4?t=655'
          },
        ]
      },
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'mF240Y7w3Zs?t=85'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'vet-han' }, { image: 'vet-chewie' }],
            video: 'mF240Y7w3Zs?t=128'
          },
          {
            name: 'JKL',
            toons: [{ image: 'jkl' }, { image: 'jkr' }, { image: 'hermit' }, { image: 'jolee' }, { image: 'jka' }],
            video: 'mF240Y7w3Zs?t=165'
          },
          {
            name: 'GG',
            toons: [{ image: 'gg' }, { image: 'b1' }, { image: 'b2' }, { image: 'nute' }, { image: 'magna' }],
            video: 'mF240Y7w3Zs?t=213'
          },
          {
            name: 'Thrawn',
            toons: [{ image: 'thrawn' }, { image: 'vader' }, { image: 'tarkin' }, { image: 'gba' }, { image: 'wat' }],
            video: 'mF240Y7w3Zs?t=248'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'mF240Y7w3Zs?t=321'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'mF240Y7w3Zs?t=360'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'wat' }],
            video: 'mF240Y7w3Zs?t=402'
          },
          {
            name: 'Talzin',
            toons: [{ image: 'talzin' }, { image: 'daka' }, { image: 'zombie' }, { image: 'spirit' }, { image: 'asajj' }],
            video: 'mF240Y7w3Zs?t=462'
          },
          {
            name: 'SLKR',
            toons: [{ image: 'slkr' }, { image: 'phasma' }, { image: 'foo' }, { image: 'hux' }, { image: 'kru' }],
            video: 'mF240Y7w3Zs?t=522'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'shoretrooper' }],
            video: 'mF240Y7w3Zs?t=590'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'rjt' }, { image: 'r2' }, { image: 'bb' }, { image: '3po' }],
            video: 'mF240Y7w3Zs?t=643'
          },
          {
            name: 'Padme',
            toons: [{ image: 'padme' }, { image: 'jka' }, { image: 'bb-echo' }, { image: 'ahsoka' }, { image: 'gk' }],
            video: 'mF240Y7w3Zs?t=720'
          },
          {
            name: 'Bossk',
            toons: [{ image: 'bossk' }, { image: 'boba' }, { image: 'jango' }, { image: 'greef' }, { image: 'mando' }],
            video: 'mF240Y7w3Zs?t=759'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'mace' }, { image: 'qgj' }],
            video: 'mF240Y7w3Zs?t=825'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'malak' }, { image: 'dooku' }],
            video: 'mF240Y7w3Zs?t=887'
          }
        ]
      }
    ]
  },


  {
    title: 'Jawas',
    image: 'nebit',
    modifiers: [
      {
        name: 'Shootogawa!',
        text: 'Whenever Thermal Detonators explode Jawa allies will recover protection and increase their speed. Whenever a debuffed enemy deals damage inflict a thermal detonators an all enemies.'
      }
    ],
    toons: [
      { image: 'nebit', speed: 135, bonus: 0 },
      { image: 'jawa-engineer', speed: 173, bonus: 50 },
      { image: 'dathcha', speed: 157, bonus: 0 },
      { image: 'jawa', speed: 153, bonus: 0 },
      { image: 'jawa-scavenger', speed: 139, bonus: 0 }
    ],
    min: 0,
    max: 0,
    prepared: { none: false, one: false, two: true, three: false, four: false },
    teams: [
      {
        id: 'gone-30',
        counters: [
          {
            name: 'CLS',
            toons: [{ image: 'cls' }, { image: 'han' }, { image: 'chewie' }, { image: 'threepio' }, { image: '3po' }],
            video: 'nXf5DhQMvmA?t=96'
          },
          {
            name: 'BAM',
            toons: [{ image: 'bam' }, { image: 'han' }, { image: 'chewie' }, { image: 'ig-11' }, { image: 'kuiil' }],
            video: 'nXf5DhQMvmA?t=136'
          },
          {
            name: 'Dash',
            toons: [{ image: 'dash' }, { image: 'young-han' }, { image: 'l3' }, { image: 'vandor-chewie' }, { image: 'qira' }],
            video: 'nXf5DhQMvmA?t=201'
          },
          {
            name: 'GAS',
            toons: [{ image: 'gas' }, { image: 'rex' }, { image: 'fives' }, { image: 'echo' }, { image: 'arc' }],
            video: 'nXf5DhQMvmA?t=247'
          },
          {
            name: 'Shaak Ti',
            toons: [{ image: 'shaak-ti' }, { image: 'hunter' }, { image: 'wrecker' }, { image: 'bb-echo' }, { image: 'tech' }],
            video: 'nXf5DhQMvmA?t=302'
          },
          {
            name: 'Maul',
            toons: [{ image: 'mando-maul' }, { image: 'canderous' }, { image: 'bokatan' }, { image: 'armorer' }, { image: 'wat' }],
            video: 'nXf5DhQMvmA?t=344'
          },
          {
            name: 'LV',
            toons: [{ image: 'lv' }, { image: 'vader' }, { image: 'thrawn' }, { image: 'rg' }, { image: 'tarkin' }],
            video: 'nXf5DhQMvmA?t=401'
          }
        ]
      },
      {
        id: 'self-destruct',
        counters: [
          {
            name: 'Rey',
            toons: [{ image: 'rey' }, { image: 'holdo' }, { image: 'finn' }, { image: 'bb' }, { image: '3po' }],
            video: 'nXf5DhQMvmA?t=470'
          },
          {
            name: 'JMK',
            toons: [{ image: 'jmk' }, { image: 'cat' }, { image: 'aayla' }, { image: 'mace' }, { image: 'qgj' }],
            video: 'nXf5DhQMvmA?t=554'
          },
          {
            name: 'Traya',
            toons: [{ image: 'traya' }, { image: 'sion' }, { image: 'nihilus' }, { image: 'talon' }, { image: 'sid' }],
            video: 'nXf5DhQMvmA?t=622'
          }
        ]
      }
    ]
  },
];

export {
  enemies,
  list
}