const sector6 = [
    { name: 'Conquest Volume 16 - Feats Preview Guide', id: 'G9kfzMIu5h0' },
    { name: 'Low Gear Guide', id: 'rlb_tlMtOlE' },
    { name: 'Datacron Farming', id: 'CgX6JpCIIdQ' },
];

const sector1 = [
    { name: 'Sector 1 - Feats Guide', id: 'VSOm6nRcj4g' },
    { name: 'Sector 1 - Mini & Final Boss Guide', id: '5AeDDhXuz5A' },
];

const sector2 = [
    { name: 'Sector 2 - Feats Guide', id: 'YWLK52JUuxc' },
    { name: 'Sector 2 - Mini & Final Boss Guide', id: 'HbVl6AnlkwY' },
];

const sector3 = [
    { name: 'Sector 3 - Feats Guide', id: 'ncdA62rgwS0' },
    { name: 'Sector 3 - Mini & Final Boss Guide', id: 'His-MU1Fpmc' },
];

const sector4 = [
    { name: 'Sector 4 - Feats Guide', id: 'sgycYTd2Zbo' },
    { name: 'Sector 4 - Mini & Final Boss Guide', id: 'CJklCPU2Op4' },
];

const sector5 = [
    { name: 'Sector 5 - Feats Guide', id: 'm8GLdlP5SMA' },
    { name: 'Sector 5 - Mini & Final Boss Guide', id: '6SAU2VaY9w4' }
];

const sectors = {
    sector1: sector1,
    sector2: sector2,
    sector3: sector3,
    sector4: sector4,
    sector5: sector5,
    sector6: sector6,
};

export {
    sectors
}