import React, { useEffect } from 'react';

const CountersRedirect = (props) => {

  useEffect(() => {
    props.history.push('/counters/5v5/');
  }, [props.history]);

  return (
    <div></div>
  )
}

export default CountersRedirect;