import React from 'react';
import classNames from 'classnames';
import './_style.scss';

const Target = props => {

  const setValue = props.value.left === 0 ? props.current : props.value.result;
  const setTitle = props.value.left === 0 ? props.complete : props.title;

  return (
    <div className="sw-target">
      <div className="sw-target__title">{setTitle}</div>
      <div className={classNames('sw-target__value', props.value.left === 0 ? 'bg-success' : 'bg-danger')}>{setValue}</div>
    </div>
  );
}

export default Target;