import React from 'react';
import classNames from 'classnames';

import OmicronsRow from './components/row';
import OmicronsHeader from './components/header';

const OmicronsList = (props) => {
  const { data, type, order, sort, onSort, loading } = props
  let rank = 0

  return (
    <div className={classNames('sw-omicrons', loading && 'sw-omicrons--loading')}>
      <OmicronsHeader sort={sort} order={order} onSort={onSort} type={type} />
      <div className={classNames('sw-omicrons__list', order === 'ASC' && 'sw-omicrons__list--reverse')}>
        {data.map(d => {
          const offense = type === '5v5' ? d['5v5_off_diff'] : d['3v3_off_diff']
          const defense = type === '5v5' ? d['5v5_def_diff'] : d['3v3_def_diff']

          if (offense !== '0') {
            rank += 1

            return (
              <OmicronsRow
                key={d.id}
                position={rank}
                image={d.image}
                unit={d.unit}
                ability={d.ability}
                icon={d.icon}
                count={d.count}
                gls={d.gls}
                solo={d.solo}
                adoption={d.ratio}
                offense={offense}
                defense={defense}
                sort={sort}
                order={order}
              />
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  );
}

export default OmicronsList;