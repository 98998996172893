const day11 = [
  {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 1101,
        name: '2x',
        videoThumb: '',
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'shaak-ti', name: 'Shaak Ti' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
    ]
  },


  {
    title: `Sector 4 - Datacron Farming`,
    teams: [
      {
        id: 1102,
        name: '3x',
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        id: 1103,
        name: '1x',
        videoThumb: 'sgycYTd2Zbo?t=302&si=yM8VDC95F5cLkqrV',
        feats: [
          { name: 'Blight', qty: 5 },
          { name: 'Nightsisters', qty: 5 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Blight', skip: true },
      { name: 'Tenacity Up', complete: true },
      { name: 'Kelleran', current: 13, target: 15 },
      { name: 'Nightsisters', current: 30, target: 30 }
    ]
  },

  {
    title: `Sector 5 - Datacron Farming`,
    teams: [
      {
        id: 1104,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=378&si=Nrcy93gMlcTW1Ueh',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 1105,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=333&si=qha9YFIPipIyUiYe',
        feats: [
          { name: 'Plague', qty: 10 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Accuracy Up', complete: true },
      { name: 'Geo Spy', current: 12, target: 15 },
      { name: 'Plague', current: 80, target: 100 },
      { name: 'Peridea', current: 10, target: 25 }
    ]
  },


  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true },
      { name: 'Imperial Remnants', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Master QGJ', current: 13, target: 15 },
    ]
  }
];

export {
  day11
}