const sector4 = [
  {
    title: 'Tenacity Up',
    text: 'Gain Tenacity Up 50 times',
    keycards: 10,
    teams: [
      {
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran' },
          { image: 'yoda', name: 'Master Yoda', main: true },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=119&si=HWZRgNteM14DBkeH',
        feats: [
          { name: 'Tenacity Up', qty: 5 }
        ],
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC' },
          { image: 'rex', name: 'Rex', main: true },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=119&si=HWZRgNteM14DBkeH',
        feats: [
          { name: 'Tenacity Up', qty: 10 },
          { name: 'Kelleran', qty: 1 }
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jmk', name: 'Master Kenobi', main: true },
          { image: 'kelleran', name: 'Kelleran' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'cat', name: 'Commander Ahsoka' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
        ]
      }
    ]
  },
  {
    title: 'Kelleran',
    text: 'Win 15 battles with Kelleran Beq in your squad',
    keycards: 10,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=1219&si=_R0AjeHRrnbKo0v6',
        feats: [
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'canderous', name: 'Candy' },
          { image: 'jango', name: 'Jango' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=119&si=HWZRgNteM14DBkeH',
        feats: [
          { name: 'Tenacity Up', qty: 10 },
          { name: 'Kelleran', qty: 1 }
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jmk', name: 'Master Kenobi' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'cat', name: 'Commander Ahsoka' },
          { image: 'master-qgj', name: 'Master QGJ' },
        ]
      }
    ]
  },
  {
    title: 'Blight',
    text: 'Attempt to inflict or gain Blight 40 times',
    keycards: 10,
    teams: [
      {
        name: 'Cheese',
        videoThumb: '4r7OwYlnFQU?t=1082&si=3oZBiPjQEphX0sxX',
        feats: [
          { name: 'Blight', qty: 40 }
        ],
        toons: [
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'death-trooper', name: 'Death Trooper' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=362&si=828NCY46RjaVlpyA',
        feats: [
          { name: 'Blight', qty: 5 }
        ],
        global: [
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=302&si=9OtF1_ycTZF9IIvP',
        feats: [
          { name: 'Blight', qty: 5 }
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      }
    ]
  },
  {
    title: 'Nightsisters',
    text: 'Defeat 30 enemies with Nightsisters',
    keycards: 10,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=1261&si=g15JfJb0SqRb8WEK',
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=241&si=uFAeVTzVQCwKmNU7',
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        videoThumb: 'sgycYTd2Zbo?t=302&si=yM8VDC95F5cLkqrV',
        feats: [
          { name: 'Blight', qty: 5 }
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Tuskens with a full squad of Galactic Republic surviving and with Jar Jar surviving',
    keycards: 9,
    teams: [
      {
        name: 'Galactic Republic',
        videoThumb: 'CJklCPU2Op4?t=86&si=mES2j1HSXSFcIrDk',
        feats: [
          { name: 'Tenacity Up', qty: 10 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'padme', name: 'Padme' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'ahsoka', name: 'Ahsoka' },
          { image: 'cat', name: 'Commander Ahsoka' },
        ]
      },
      {
        videoThumb: 'CJklCPU2Op4?t=176&si=PNf0_KVBb-ntd02x',
        feats: [
          { name: 'Tenacity Up', qty: 10 },
          { name: 'Kelleran', qty: 1 }
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jmk', name: 'Master Kenobi' },
          { image: 'kelleran', name: 'Kelleran' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'cat', name: 'Commander Ahsoka' },
          { image: 'master-qgj', name: 'Master QGJ' },
        ]
      },
      {
        name: 'Jar Jar',
        videoThumb: 'CJklCPU2Op4?t=246&si=OjZL1QKgAO4hnrtf',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Dash with Dark Trooper Moff Gideon surviving and without using Empire',
    keycards: 11,
    teams: [
      {
        name: 'DT Gideon',
        videoThumb: 'CJklCPU2Op4?t=531&si=qwTsZhqyE0WFlaeI',
        feats: [
          { name: 'Blight', qty: 5 }
        ],
        global: [
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        name: 'No Empire',
        videoThumb: 'CJklCPU2Op4?t=389&si=xW0SXE3A_W7T9oIL',
        feats: [
          { name: 'Blight', qty: 5 }
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'CJklCPU2Op4?t=465&si=Xlw2mjiuhe0ZG-CK',
        feats: [
          { name: 'Tenacity Up', qty: 5 }
        ],
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC' },
          { image: 'rex', name: 'Rex', main: true },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' },
        ]
      },
    ]
  }
];

export {
  sector4
}