import React from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';

import Toon from '../toon';
import { ItemTypes } from './types';

const ToonDrag = props => {
  const { name, image, assigned, ggImage } = props;
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TOON,
    item: { name, image, ggImage },
    end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
            props.onDrop && props.onDrop(item, dropResult);
        }
    },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} className={classNames('sw-toon-drag', isDragging && 'sw-toon-drag--active', assigned && 'sw-toon-drag--assigned')}>
      <button className="btn btn-dark btn-sm rounded-circle sw-toon-drag__button" onClick={() => props.onRemove(props.image)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
      <Toon image={image} ggImage={ggImage} />
    </div>
  );
}

export default ToonDrag;