import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Page from '../../components/page';
import Section from '../../components/section';
import SubMenu from '../../components/submenu';
import OmicronsList from './list';
import { videos } from './data/videos';
import './_style.scss';

const menuLinks = [
  { label: '5v5' },
  { label: '3v3' },
  { label: 'Videos' }
];

const Omicrons = () => {
  const [activeTab, selectTab] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState('count');
  const [order, setOrder] = useState('DESC');

  useEffect(() => {
    document.title = 'GAC Omicron Rankings - SWGOH-4 Life';

    getData('count', 'DESC')
  }, [])

  const getData = (sort, order) => {
    setLoading(true)
    axios.post('https://swgoh4.life/api/omicrons.php', {
      sort: sort,
      order: 'DESC'
    })
      .then(response => {
        setData(response.data)
        if (sort) {
          let updateSort = sort.replace('5v5_', '').replace('3v3_', '')
          setSort(updateSort)
        }
        order && setOrder(order)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const onSort = (v) => {
    let updateOrder = 'DESC'
    let updateSort = v.replace('5v5_', '').replace('3v3_', '')
    if (updateSort === sort && order === 'DESC') {
      updateOrder = 'ASC'
    }
    getData(v, updateOrder)
  }

  const setTab = (v) => {
    if (v < 2) {
      getData('count', 'DESC')
    }
    console.log(v)
    selectTab(v)
  }

  const intro = <div className="row">
    <div className="col-12">
      Omicron rankings based on GAC Insight Data by <a href='https://swgoh.gg/gac/insight/' rel='noopener noreferrer' target='_blank'>swgoh.gg</a>
      <div className='small mt-2'>
        <strong>Total Count</strong> = How many players equipped an Omicron<br />
        <strong>Popularity</strong> = Total Count / G13 Units (higher is more popular)<br />
        <strong>Offense Impact</strong> = How much does Omicron improve win rates<br />
        <strong>Defense Impact</strong> = How much does Omicron improve hold rates
      </div>
    </div>
  </div>

  return (
    <Page title="GAC Omicron Rankings" intro={intro}>

      <SubMenu links={menuLinks} active={activeTab} onClick={setTab} theme='light' breakpoint={425} />

      {activeTab === 0 && <OmicronsList data={data} type='5v5' sort={sort} order={order} onSort={onSort} loading={loading} />}
      {activeTab === 1 && <OmicronsList data={data} type='3v3' sort={sort} order={order} onSort={onSort} loading={loading} />}
      {activeTab === 2 && <Section videos={videos} />}
    </Page>
  );
}

export default Omicrons;