import { sector0 } from './sector0';
import { sector1 } from './sector1';
import { sector2 } from './sector2';
import { sector3 } from './sector3';
import { sector4 } from './sector4';
import { sector5 } from './sector5';

const sectors = [
  sector0, sector1, sector2, sector3, sector4, sector5
]

export {
  sectors
}