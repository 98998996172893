import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import SubMenu from '../../components/submenu';
import Section from '../../components/section';
import { two, one, three } from './data/videos';

const menuLinks = [
  { label: 'Challenge Tier 3' },
  { label: 'Challenge Tier 2' },
  { label: 'Challenge Tier 1' }
];

const Assault = () => {
  const [activeTab, selectTab] = useState(0);

  useEffect(() => {
    document.title = 'Assault Battles - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      A library of videos showing possible teams that can complete the Assault Battles Challenge Tiers
    </div>
  </div>;

  return (
    <Page title="Assault Battles" intro={intro}>

      <SubMenu links={menuLinks} active={activeTab} onClick={selectTab} theme='light' />

      {activeTab === 0 && <Section title="Challenge Tier 3" videos={three} />}
      {activeTab === 1 && <Section title="Challenge Tier 2" videos={two} />}
      {activeTab === 2 && <Section title="Challenge Tier 1" videos={one} />}

    </Page>
  );
}

export default Assault;
