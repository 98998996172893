const day5 = [
  {
    title: `Sector 4 - Mini Boss`,
    boss: 'tuskens',
    teams: [
      {
        id: 501,
        name: 'Galactic Republic',
        videoThumb: 'CJklCPU2Op4?t=86&si=mES2j1HSXSFcIrDk',
        feats: [
          { name: 'Tenacity Up', qty: 10 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'padme', name: 'Padme' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'ahsoka', name: 'Ahsoka' },
          { image: 'cat', name: 'Commander Ahsoka' },
        ]
      },
    ]
  },
  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 503,
        name: '4x',
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        id: 504,
        name: '4x',
        videoThumb: 'sgycYTd2Zbo?t=119&si=HWZRgNteM14DBkeH',
        feats: [
          { name: 'Tenacity Up', qty: 5 }
        ],
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC' },
          { image: 'rex', name: 'Rex', main: true },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' },
        ]
      },
    ]
  },
  {
    title: `Sector 4 - Final Boss`,
    boss: 'dash',
    teams: [
      {
        id: 507,
        name: 'No Empire',
        videoThumb: 'CJklCPU2Op4?t=389&si=xW0SXE3A_W7T9oIL',
        feats: [
          { name: 'Blight', qty: 5 }
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      }
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Blight', skip: true },
      { name: 'Tenacity Up', current: 50, target: 50 },
      { name: 'Kelleran', current: 8, target: 15 },
      { name: 'Nightsisters', current: 5, target: 30 }
    ]
  },



  {
    title: `Sector 5 - Battles`,
    teams: [
      {
        id: 508,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=378&si=Nrcy93gMlcTW1Ueh',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 509,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=333&si=qha9YFIPipIyUiYe',
        feats: [
          { name: 'Plague', qty: 10 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Peridea', skip: true },
      { name: 'Accuracy Up', current: 0, target: 50 },
      { name: 'Geo Spy', current: 2, target: 15 },
      { name: 'Plague', current: 20, target: 100 },
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Imperial Remnants', skip: true },
      { name: 'Gungans', skip: true },
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', current: 250, target: 250 },
      { name: 'Nightsisters', current: 17, target: 20 },
      { name: 'Master QGJ', current: 6, target: 15 },
    ]
  }
];

export {
  day5
}