import React, { useEffect, useState } from 'react';
import { sectorOne } from './data/sectorOne'
import { sectorTwo } from './data/sectorTwo'
import { sectorThree } from './data/sectorThree'
import TbSquad from './tbSquad';
import RoteMenu from './roteMenu';

const TbLoadOuts = () => {
  const [activeSector, setSector] = useState(1);
  const [activePlanet, setPlanet] = useState('ds');
  const [activeToons, setToons] = useState([]);

  const changeSector = (directon) => {
    let updateSector

    if (directon === 'down') {
      updateSector = activeSector - 1
    } else {
      updateSector = activeSector + 1
    }
    configureSelecton('ds', updateSector)
  }

  const changePlanet = (path, sector) => {
    const getSector = sector ? sector : activeSector
    configureSelecton(path, getSector)
  }

  const configureSelecton = (path, sector) => {
    let findPlanets = {}

    if (sector === 1) {
      findPlanets = sectorOne.planets
    } else if (sector === 2) {
      findPlanets = sectorTwo.planets
    } else if (sector === 3) {
      findPlanets = sectorThree.planets
    }

    const findLoadouts = findPlanets.find(l => l.path === path).loadouts
    setToons(findLoadouts)
    setPlanet(path)
    setSector(sector)
    localStorage.setItem("roteSector", sector)
    localStorage.setItem("rotePath", path)
  }

  useEffect(() => {
    const getSector = Number(localStorage.getItem("roteSector"));
    getSector && setSector(getSector);
    const getPath = localStorage.getItem("rotePath");
    getPath && setPlanet(getPath);
    configureSelecton(getPath ? getPath : 'ds', getSector ? getSector : 1)
  }, []);

  return (
    <div>
      {activeToons.length > 0 && <RoteMenu sector={activeSector} active={activePlanet} onClick={changePlanet} onArrow={changeSector} />}
      <div className='row'>
        {activeToons.length > 0 && <TbSquad loadouts={activeToons} />}
      </div>
    </div>

  );
}

export default TbLoadOuts;