import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import Section from '../../components/section';
import SubMenu from '../../components/submenu';
import TbMenu from './menu';
import TbLoadOuts from './loadouts';

import { fullClear, ds, ships, ls, mix, planetsP1, planetsP2, planetsP3, planetsP4 } from './data/videos';

const menuLinks = [
  { label: 'Missions' },
  { label: 'Loadouts' },
  { label: 'Planets' }
];

const RoteTb = () => {

  const [activeTab, selectTab] = useState(0);

  useEffect(() => {
    document.title = 'Rise of the Empire Territory Battle - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      A library of videos to help you conquer the Rise of the Empire Territory Battle.<br/><br />
      <span className='badge badge-primary'>NEW</span> Loadouts feature has been added to help you with finding effective teams for each planet. The default suggested teams have been created to avoid duplication within the same phase.
    </div>
  </div>;

  return (
    <Page menu={<TbMenu />} title="Rise of the Empire TB" intro={intro}>
      
      <SubMenu links={menuLinks} active={activeTab} onClick={selectTab} theme='light' breakpoint={425} />

       {activeTab === 0 && <Section title="Full Clear" videos={fullClear} />}
       {activeTab === 0 && <Section title="Fleet" videos={ships} />}
       {activeTab === 0 && <Section title="Light Side" videos={ls} />}
       {activeTab === 0 && <Section title="Mix" videos={mix} />}
       {activeTab === 0 && <Section title="Dark Side" videos={ds} />}
       {activeTab === 1 && <TbLoadOuts />}
       {activeTab === 2 && <Section title="Sector 4" videos={planetsP4} />}
       {activeTab === 2 && <Section title="Sector 3" videos={planetsP3} />}
       {activeTab === 2 && <Section title="Sector 2" videos={planetsP2} />}
       {activeTab === 2 && <Section title="Sector 1" videos={planetsP1} />}
    </Page>
  );
}

export default RoteTb;
