import React from 'react';

const DatacronAccountsModal = (props) => {
  const { onClose, children, toggleAddAccount } = props

  return (
    <>
      <div className="sw-toon-drop__backdrop" onClick={onClose}>
        <div className="sw-toon-drop__counters d-flex flex-column" onClick={(e) => e.stopPropagation()}>
          <div className="sw-zone__add-header mx-0">
            <h2 className="mb-0 h3 sw-heading text-light">Manage Accounts</h2>
            <button className="btn btn-sm btn-link text-light" onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
          <div className="px-3 flex-grow-1">
            <hr className='border-info' />
            {children}
          </div>
          <div className="border-top border-info">
            <div className="d-flex pt-3">
              <div className='flex-grow-1 pr-1'>
                <button type="button" className="btn btn-block btn-outline-secondary" onClick={onClose}>Close</button>
              </div>
              <div className='flex-grow-1 pl-2'>
                <button type="button" className="btn btn-block btn-secondary" onClick={toggleAddAccount}>Add Account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DatacronAccountsModal;