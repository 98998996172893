import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import SubMenu from '../../components/submenu';
import Section from '../../components/section';
import { dsCm, dsSm, dsFm } from './data/videos';
import TbMenu from '../rote-tb/menu';

const menuLinks = [
  { label: 'Special' },
  { label: 'Combat' },
  { label: 'Fleet' }
];

const GeoTbDs = () => {

  const [activeTab, selectTab] = useState(0);

  useEffect(() => {
    document.title = 'Geo Territory Battle: Dark Side - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
    A library of videos to help you conquer the Dark Side of Geo Territory Battle.
    </div>
  </div>;

  return (
    <Page menu={<TbMenu />} title="Geonosis TB: Dark Side" intro={intro}>

      <SubMenu links={menuLinks} active={activeTab} onClick={selectTab} theme='light' />

      {activeTab === 0 && <Section videos={dsSm} title="Special Missions" />}
      {activeTab === 1 && <Section videos={dsCm} title="Combat Missions" />}
      {activeTab === 2 && <Section videos={dsFm} title="Fleet Missions" />}

    </Page>
  );
}

export default GeoTbDs;
