import React from 'react'
import classNames from 'classnames'

const AddDatacronStats = (props) => {
  const { selected, list, onChange, label } = props

  return (
    <div className='mb-3'>
      <div className='mb-1 text-dark'>{label}</div>
      <div className='d-flex flex-wrap flex-md-nowrap'>
        {list.map(stat =>
          <button
            key={stat}
            className={classNames('btn btn-outline-dark btn-sm mb-1 mr-1', selected === stat && 'active')}
            onClick={() => onChange(stat)}
          >
            {stat}
          </button>
        )}
      </div>
    </div>
  )
}

export default AddDatacronStats