import React, { useEffect } from 'react';

import Page from '../../components/page';
import Section from '../../components/section';
import { videos } from './data/videos';

const ProvingGrounds = () => {

  useEffect(() => {
    document.title = 'Proving Grounds - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      Master the Proving Grounds with the below guides and start earning shards and blueprints for those rare Conquest units.
    </div>
  </div>;

  return (
    <Page title="Proving Grounds" intro={intro}>

      <Section videos={videos} />

    </Page>
  );
}

export default ProvingGrounds;
