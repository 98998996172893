const videos = [
  { name: 'Top 10', desc: 'Under-rated Offense GAC Omicrons', id: 'j6furhwevsA' },
  { name: 'Top 10', desc: 'Under-rated Defense GAC Omicrons', id: '12MYVTF0fKQ' },
  { name: 'Top 10', desc: 'Over-rated Offense GAC Omicrons', id: 'ONNhRovoTQ0' },
  { name: 'Top 10', desc: 'Over-rated Defense GAC Omicrons', id: 'PGTSjUdiEm0' },
  { name: '3v3 GAC', desc: 'Auto Basic Merrin Omicron vs Jabba', id: 'sAXmThXbrYo' },
  { name: 'Ranking', desc: 'Ranking ALL my Omicrons', id: '6ZLyx4vtDb4' },
];

export {
  videos
}