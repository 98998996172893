import React from 'react';
import classNames from 'classnames';

const menuMap = [
  { sector: 1, planets: [{ value: 'ds', label: 'Mustafar' }, { value: 'mix', label: 'Corellia' }, { value: 'ls', label: 'Coruscant' }] },
  { sector: 2, planets: [{ value: 'ds', label: 'Geonosis' }, { value: 'mix', label: 'Felucia' }, { value: 'ls', label: 'Bracca' }] },
  { sector: 3, planets: [{ value: 'ds', label: 'Dathomir' }, { value: 'mix', label: 'Tatooine' }, { value: 'ls', label: 'Kashyyyk' }, { value: 'ls-bonus', label: 'Zeffo' }] }
]

const getLabel = (sector, path) => {
  const findSector = menuMap.find(m => m.sector === sector)
  const findPlanet = findSector.planets.find(p => p.value === path)
  return findPlanet.label
}

const RoteMenu = (props) => {
  const { onClick, active, sector, onArrow } = props

  return (
    <div className='sw-rote-menu'>
      <div className='sw-rote-menu__sectors'>
        <button
          className='sw-rote-arrow sw-rote-arrow--down'
          onClick={() => onArrow('down')} disabled={sector === 1}
        >
          <span className='sw-rote-arrow__label'>Down</span>
        </button>
        <div className='sw-rote-sector'>
          Sector {sector}
        </div>
        <button
          className='sw-rote-arrow sw-rote-arrow--up'
          onClick={() => onArrow('up')} disabled={sector === 3}
        >
          <span className='sw-rote-arrow__label'>Up</span>
        </button>
      </div>
      <div className='sw-rote-menu__planets'>
        <button
          className={classNames('sw-rote-planet', active === 'ds' && 'sw-rote-planet--active')}
          onClick={() => onClick('ds')}
        >
          <span className={`sw-rote-planet__icon sw-rote-planet__icon--s${sector}-ds`} />
          <span className={'sw-rote-planet__label'}>{getLabel(sector, 'ds')}</span>
        </button>
        <button
          className={classNames('sw-rote-planet', active === 'mix' && 'sw-rote-planet--active')}
          onClick={() => onClick('mix')}
        >
          <span className={`sw-rote-planet__icon sw-rote-planet__icon--s${sector}-mix`} />
          <span className={'sw-rote-planet__label'}>{getLabel(sector, 'mix')}</span>
        </button>
        <button
          className={classNames('sw-rote-planet', active === 'ls' && 'sw-rote-planet--active')}
          onClick={() => onClick('ls')}
        >
          <span className={`sw-rote-planet__icon sw-rote-planet__icon--s${sector}-ls`} />
          <span className={'sw-rote-planet__label'}>{getLabel(sector, 'ls')}</span>
        </button>
        {sector === 3 &&
          <button
            className={classNames('sw-rote-planet', active === 'ls-bonus' && 'sw-rote-planet--active')}
            onClick={() => onClick('ls-bonus')}
          >
            <span className={`sw-rote-planet__icon sw-rote-planet__icon--s${sector}-ls-bonus`} />
            <span className={'sw-rote-planet__label'}>{getLabel(sector, 'ls-bonus')}</span>
          </button>
        }
      </div>
    </div>

  );
}

export default RoteMenu;