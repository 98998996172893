export const set18Bonus = [
  {
    id: 'ls-debuff-damage',
    label: '2% Damage on Debuff',
    full: `Whenever Light Side allies inflict a debuff on an enemy, they deal damage to that enemy equal to 2% of that enemy's Max Health.`
  },
  {
    id: 'ls-protection-up',
    label: '20% Protection Up',
    full: `Light Side allies start the battle with Protection Up (20%) for 2 turns.`
  },
  {
    id: 'ls-tenacity-up',
    label: 'Tenacity Up',
    full: `Whenever Light Side allies start their turn with at least 3 debuffs that can be dispelled, they gain Tenacity Up for 2 turns.`
  },
  {
    id: 'ls-recover-hp',
    label: 'Recover 2% Health & Protection',
    full: `Whenever Light Side allies gain a buff, they recover 2% Health and Protection.`
  },
  {
    id: 'ls-buffs-special',
    label: 'Gain Buffs on Special',
    full: `Whenever Light Side allies use a Special ability, they gain Evasion Up, Health Steal Up, and Health Up for 2 turns.`
  },
  {
    id: 'ds-recover-health',
    label: 'Recover 2% Health',
    full: `Dark Side allies recover 2% Health whenever they critically hit an enemy.`
  },
  {
    id: 'ds-tank-revive',
    label: 'Tank Revive',
    full: `The first time a Dark Side Tank ally is defeated, revive them with 100% Health, reset their cooldowns, and they gain a bonus turn. Limit once per battle.`
  },
  {
    id: 'ds-gain-tm',
    label: '10% TM on Defeat',
    full: `Dark Side allies gain +10% Turn Meter whenever an ally or enemy is defeated.`
  },
  {
    id: 'ds-bulk',
    label: '15% Health & Protection',
    full: `At the start of the encounter Dark Side allies gain 15% Max Health and Max Protection for each other Dark Side ally.`
  },
  {
    id: 'ds-tm-special',
    label: '15% TM on Special',
    full: `Whenever Dark Side allies use a Special ability they gain 15% Turn Meter.`
  },
  {
    id: 'mercenary-fortune',
    parent: 'ds',
    label: 'Solider of Fortune',
    full: `At the start of each of their turns, Mercenary allies have 2% Offense equal to the number of stacks of Solider of Fortune they have, until they are defeated. Whenever an enemy attacks out of turn, they take bonus True Damage, which can't be evaded and can't defeat them.`
  },
  {
    id: 'mercenary-bonus-damage',
    parent: 'ds',
    label: '10% Bonus Damage',
    full: `Whenever Mercenary allies inflict a debuff on an enemy during their turn, they deal bonus damage equal to 10% of that enemy's Max Health, which can't be evaded.`
  },
  {
    id: 'mercenary-stun',
    parent: 'ds',
    label: 'Stun on Special',
    full: `Whenever Mercenary allies use a Special ability during their turn, they Stun target enemy for 1 turn, which can't be evaded or resisted.`
  },
  {
    id: 'mercenary-offense',
    parent: 'ds',
    label: '400% Offense',
    full: `At the start of their first turn, Mercenary allies gain Offense equal to 400% of their current Defense. Then they lose 50% Defense.`
  },
  {
    id: 'mercenary-retribution',
    parent: 'ds',
    label: 'Retribution',
    full: `At the start of battle, Mercenary allies gain Retribution for 3 turns, which can't be dispelled.`
  },
  {
    id: 'ufu-tm-health',
    parent: 'ds',
    label: '5% TM & Health',
    full: `Whenever a Dark Side or Light Side Unaligned Force User critically hits an enemy, dispel all debuffs on themself and gain 5% Turn Meter. Whenever a Dark Side or Light Side Unaligned Force User uses a Special ability against an enemy with at least 3 debuffs, reduce that enemy's Max Health by 5%. Whenever a Dark Side or Light Side Unaligned Force User uses a Special ability when they have at least 3 buffs that can be dispelled, gain 5% Max Health.`
  },
  {
    id: 'ufu-potency-tenacity',
    parent: 'ds',
    label: '2% Potency & Tenacity',
    full: `Whenever a debuff on an enemy expires, Unaligned Force User allies gain 2% Potency and Tenacity (stacking) until the end of battle.`
  },
  {
    id: 'ufu-damage-immunity-health',
    parent: 'ds',
    label: 'Damage Immunity Below 50% Health',
    full: `The first time each Unaligned Force User ally falls below 50% Health they gain Damage Immunity for 1 turn and gain Health Steal Up for 2 turns.`
  },
  {
    id: 'ufu-damage-immunity-start',
    parent: 'ds',
    label: 'Start With Damage Immunity',
    full: `Unaligned Force User allies start the battle with Damage Immunity for 2 turns (can't be copied).`
  },
  {
    id: 'ufu-bonus-turn',
    parent: 'ds',
    label: 'Bonus Turn on Defeat',
    full: `Whenever a Unaligned Force User ally defeats an enemy, they take a bonus turn.`
  },
  {
    id: 'or-cooldown-reset',
    parent: 'ls',
    label: 'Reset Cooldown',
    full: `Whenever an Old Republic ally defeats an enemy, they refresh the cooldown of all Old Republic allies.`
  },
  {
    id: 'or-tm',
    parent: 'ls',
    label: '10% TM on Buff',
    full: `Whenever Old Republic allies grant a buff to another ally, that ally gains 10% Turn Meter (limit once per turn per ally).`
  },
  {
    id: 'or-recover-protection',
    parent: 'ls',
    label: 'Protection Recovery',
    full: `Whenever Old Republic allies recover Health, they recover Protection equal to the amount of Health recovered. Old Republic allies can't recover Protection this way more than once per turn or if their Health was full.`
  },
  {
    id: 'or-reduce-cooldown',
    parent: 'ls',
    label: 'Reduce Cooldown',
    full: `The first time each Old Republic ally falls below 50% Health, all Old Republic allies reduce their cooldowns by 1 and gain 10% Turn Meter.`
  },
  {
    id: 'or-stats',
    parent: 'ls',
    label: 'Stat Boost on Target',
    full: `Whenever Old Republic allies target another ally with an ability during their turn, they both gain 5% Defense, Max Health, Max Protection, and Offense (stacking) until the end of battle.`
  },
  {
    id: 'unit-jango',
    parent: 'mercenary',
    label: 'Jango Fett',
    full: `Whenever Jango Fett is dealt damage, reflect 20% of the damage dealt back onto the attacker as True damage, which can't be evaded. This damage can't defeat enemies.`
  },
  {
    id: 'unit-dengar',
    parent: 'mercenary',
    label: 'Dengar',
    full: `Whenever an enemy takes a turn, Dengar takes a bonus turn. During this bonus turn, Dengar deals 100% more damage. This bonus turn can't trigger this effect on enemies.`
  },
  {
    id: 'unit-hondo',
    parent: 'mercenary',
    label: 'Hondo Ohnaka',
    full: `Whenever Hondo Ohnaka uses a Basic ability, he gains 9 additional stacks of Ransom, Protection Up (100%, stacking) for 2 turns, and reduces his cooldowns by 1.`
  },
  {
    id: 'unit-aurra',
    parent: 'mercenary',
    label: 'Aurra Sing',
    full: `Whenever an enemy is Stunned, Aurra Sing takes a bonus turn.`
  },
  {
    id: 'unit-shin',
    parent: 'mercenary',
    label: 'Shin Hati',
    full: `Shin Hati gains 15% Turn Meter when other allies use a Special ability on their turn.`
  },
  {
    id: 'unit-mara',
    parent: 'ufu',
    label: 'Mara Jade',
    full: `At the start of any other non-Emperor Palpatine ally's turn, Mara Jade gains a stack of Loyal Hand. Whenever an ally with less than 100% Turn Meter gains bonus Turn Meter they gain 3% Critical Chance and Critical Damage (stacking) for 1 turn. Whenever an enemy is inflicted with Shock, they take damage equal to 10% of their Max Health.`
  },
  {
    id: 'unit-gi',
    parent: 'ufu',
    label: 'Grand Inquisitor',
    full: `While Grand Inquisitor is active, Inquisitorius allies are immune to Fear and Stun and whenever an ally or enemy is defeated, Inquisitorius allies gain 50% Turn Meter, Damage Immunity for 1 turn which can't be copied, and their cooldowns are reset.`
  },
  {
    id: 'unit-kylo',
    parent: 'ufu',
    label: 'Kylo Ren',
    full: `Kylo Ren has +5% Max Health and Max Protection per Relic Amplifier level, and damage they receive is decreased by 15%.`
  },
  {
    id: 'unit-8th-bro',
    parent: 'ufu',
    label: 'Eighth Brother',
    full: `At the start of Eighth Brother's turn, they have a 20% chance to gain 50% Defense Penetration for 1 turn.`
  },
  {
    id: 'unit-sk',
    parent: 'ufu',
    label: 'Starkiller',
    full: `Whenever another ally uses an ability on their turn, Starkiller assists (limit once per turn) dealing 50% more damage.`
  },
  {
    id: 'unit-maul',
    parent: 'ufu',
    label: 'Maul',
    full: `The first time Maul is defeated, they are revived with 100% Health, gain Health Over Time (75%) for 2 turns, and take a bonus turn.`
  },
  {
    id: 'unit-malicos',
    parent: 'ufu',
    label: 'Taron Malicos',
    full: `At the start of battle, Taron Malicos gains 5 stacks of Edge of Madness and 3 stacks of Resilient Defense which can't be copied, dispelled, or prevented. The first time Taron Malicos gains 20 stacks of Edge of Madness, he gains a bonus turn and his cooldowns are reset.`
  },
  {
    id: 'unit-jolee',
    parent: 'or',
    label: 'Jolee Bindo',
    full: `Whenever another ally attacks during Jolee Bindo's turn, Jolee Bindo and that ally gain 25% Turn Meter (limit once per turn).`
  },
  {
    id: 'unit-carth',
    parent: 'or',
    label: 'Carth Onasi',
    full: `Carth Onasi has +100% Max Health, Max Protection, and Potency and he gains 15% Turn Meter whenever another character ends their turn. Whenever he uses his Basic ability, he attacks two more times. Whenever an Old Republic ally resists a debuff, they dispel all debuffs on themselves and gain 10% Tenacity (stacking) until the end of battle. Whenever an Old Republic ally is Dazed, they dispel it. When they dispel Daze, they gain 15% Turn Meter. Whenever Carth uses a Special ability, he triggers the damage of all Damage Over Time debuffs on enemies. Whenever an enemy is defeated, Carth gains 25% Offense (stacking) and Critical Damage (stacking) until the end of the battle, his cooldowns are reset, and he gains 100% Turn Meter.`
  },
  {
    id: 'unit-juhani',
    parent: 'or',
    label: 'Juhani',
    full: `Whenever Juhani loses Taunt, remove 50% Turn Meter from all enemies.`
  },
  {
    id: 'unit-t3',
    parent: 'or',
    label: 'T3-M4',
    full: `Whenever T3-M4 uses a Basic ability on their turn, other allies gain +25% Turn Meter.`
  },
  {
    id: 'unit-sortie',
    parent: 'or',
    label: '50R-T',
    full: `While 50R-T is active, at the start of 50R-T's turn, apply Spare Parts to all other Droid allies that do not have it. Whenever Droid allies gain Spare Parts, they gain +25% Offense (stacking) for the rest of the encounter. Whenever a Droid ally is defeated, dispel all debuffs on 50R-T.`
  },
  {
    id: 'unit-mission',
    parent: 'or',
    label: 'Mission Vao',
    full: `Whenever Mission Vao gains Stealth, they recover 100% Protection.`
  },
  {
    id: 'unit-zaalbar',
    parent: 'or',
    label: 'Zaalbar',
    full: `Zaalbar gains +20% Defense for each Old Republic ally. Whenever another Old Republic ally uses a Special ability, Zaalbar recovers 20% Health and Protection and inflicts a stack of Damage Over Time for 2 turns on all enemies, which can't be evaded.`
  },
]