const sector5 = [
  {
    title: 'Accuracy Up',
    text: 'Gain Accuracy Up 50 times',
    keycards: 10,
    teams: [
      {
        name: 'Cheese',
        videoThumb: '4r7OwYlnFQU?t=1783&si=0YgRxDZs1OH7ZYx4',
        feats: [
          { name: 'Accuracy Up', qty: 40 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'cere', name: 'Cere', main: true },
          { image: 'cal', name: 'Cal' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=251&si=2FNCVxF4XhbN4Zwb',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao' },
          { image: 'kyle', name: 'Kyle' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=152&si=WIWxwI7dmfJEhMUu',
        feats: [
          { name: 'Accuracy Up', qty: 7 },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'jedi-basti', name: 'Jedi Basti', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'jkr', name: 'Jedi Revan' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=78&si=7cGEJtZA02uTvkxv',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=294&si=BvYTKfD7q2_JJEGr',
        feats: [
          { name: 'Accuracy Up', qty: 10 },
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'amidala', name: 'Amidala', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'bb-echo', name: 'BB Echo' },
          { image: 'tech', name: 'Tech' },
          { image: 'master-qgj', name: 'Master QGJ' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=193&si=oSs5EvI83Iu2GD2D',
        feats: [
          { name: 'Accuracy Up', qty: 20 },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'cere', name: 'Cere', main: true },
          { image: 'cal', name: 'Cal' },
          { image: 'visas', name: 'Visas' },
          { image: 'rjt', name: 'Rey (JT)' },
        ]
      },
    ]
  },
  {
    title: 'Plague',
    text: 'Attempt to inflict Plague 100 times',
    keycards: 15,
    teams: [
      {
        name: 'Cheese',
        videoThumb: '4r7OwYlnFQU?t=1684&si=cx7vroKWScAFD4RY',
        feats: [
          { name: 'Plague', qty: 100 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'han', name: 'Han' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=333&si=9oBAWdJS5saVS7V9',
        feats: [
          { name: 'Plague', qty: 10 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      }
    ]
  },
  {
    title: 'Death Trooper (Peridea)',
    text: 'Defeat 25 enemies with Death Trooper (Peridea)',
    keycards: 15,
    teams: [
      {
        name: 'Cheese',
        videoThumb: 'm8GLdlP5SMA?t=78&si=iDMNaIs7WfAh4hvu',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
          { name: 'Peridea', qty: 25 },
        ],
        global: [
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=1405&si=wANJbiiihEceHFxG',
        feats: [
          { name: 'Peridea', qty: 4 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'han', name: 'Han' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'malak', name: 'Malak' },
          { image: 'dooku', name: 'Dooku' },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=1560&si=POJN1gZoJdm4R1EF',
        feats: [
          { name: 'Peridea', qty: 4 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'han', name: 'Han' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'ben', name: 'Old Ben' },
          { image: 'bb-echo', name: 'BB Echo' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=333&si=9oBAWdJS5saVS7V9',
        feats: [
          { name: 'Plague', qty: 10 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'Peridea', qty: 3 },
        ],
        toons: [
          { image: 'enoch', name: 'Enoch' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'tfp', name: 'Tie Pilot' },
          { image: 'stormtrooper', name: 'Stormtrooper' }
        ]
      },
    ]
  },
  {
    title: 'Geo Spy',
    text: 'Win 15 battles with Geo Spy surviving',
    keycards: 15,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=1359&si=pfkfsI2vaD4WJJpO',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'canderous', name: 'Candy' },
          { image: 'jango', name: 'Jango' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        videoThumb: 'm8GLdlP5SMA?t=378&si=uuV_uhy1_vz4GFJ1',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs General Skywalker with Padawan Obi-Wan defeating an enemy and without Galactic Republic',
    keycards: 9,
    teams: [
      {
        name: 'Padawan Obi-Wan',
        videoThumb: '6SAU2VaY9w4?t=112&si=8VLU150mTnDdOgpX',
        feats: [
          { name: 'Accuracy Up', qty: 7 },
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'jedi-basti', name: 'Jedi Basti' },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'padawan-obi', name: 'Padawan Obi', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
        ]
      },
      {
        name: 'No Galactic Republic',
        videoThumb: '6SAU2VaY9w4?t=187&si=6OS9keI2fYsxGEB0',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Jedi Knight Revan with Luthen Rael surviving and with a full squad of Gungans',
    keycards: 12,
    teams: [
      {
        name: 'Luthen',
        videoThumb: '6SAU2VaY9w4?t=306&si=b7slil6C4sVJbwiu',
        feats: [          
          { name: 'Accuracy Up', qty: 5 },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder'},
          { image: 'luthen', name: 'Luthen', main: true },
          { image: 'pao', name: 'Pao' },
          { image: 'kyle', name: 'Kyle' },
        ]
      },
      {
        name: 'Gungans',
        videoThumb: '6SAU2VaY9w4?t=364&si=KARNKF5FzJOsfsQE',
        global: [
          { name: 'Gungans' }
        ],
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      }
    ]
  }
];

export {
  sector5
}