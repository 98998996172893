const day14 = [
  {
    title: `Sector 5 - Datacron Farming`,
    teams: [
      {
        id: 1401,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=78&si=iDMNaIs7WfAh4hvu',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
          { name: 'Peridea', qty: 25 },
        ],
        global: [
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Accuracy Up', complete: true },
      { name: 'Geo Spy', complete: true },
      { name: 'Plague', complete: true },
      { name: 'Peridea', current: 25, target: 25 }
    ]
  },


  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true },
      { name: 'Imperial Remnants', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Master QGJ', complete: true },
    ]
  }
];

export {
  day14
}