import React from 'react';
import classNames from 'classnames';

import Toon from '../toon';
import './_style.scss';

const Squad = props => {

  return (
    <div className={classNames('sw-squad', props.progress && (props.progress.qty === props.progress.completed) && 'sw-squad--complete', props.noMargin && 'sw-squad--no-margin', props.dataDisk && 'mt-3')}>
      {props.name && <div className={classNames('sw-squad__name', props.video && 'sw-squad__name--video')}>
        <div className="sw-squad__title">{props.name}</div>
        {props.video &&
          <div className="sw-squad__video">
            <a
              href={`https://youtu.be/${props.video}`} target="_blank"
              className="btn btn-youtube sw-squad__watch" rel="noopener noreferrer"
            >
              <span className="icon-youtube" />
            </a>
          </div>
        }
      </div>
      }
      <div className={classNames('sw-squad__toons', props.noWrap && 'sw-squad__toons--no-wrap')}>
        {props.toons && props.toons.map((toon, i) =>
          <div key={i} className="sw-squad__toon">
            <Toon image={toon.image} name={toon.name} main={toon.main} inlineName={props.inlineName} />
          </div>
        )}
        {props.videoThumb &&
          <div className="sw-squad__video sw-squad__video--thumb">
            <a
              href={`https://youtu.be/${props.videoThumb}`} target="_blank"
              className="btn btn-youtube sw-squad__watch" rel="noopener noreferrer"
            >
              <span className="icon-youtube" />
            </a>
          </div>
        }
      </div>
    </div>
  );
}

export default Squad;