export const sectorThree = {
  title: 'Sector 2',
  planets: [
    {
      title: 'Kashyyyk',
      path: 'ls',
      loadouts: [
        {
          title: 'Saw',
          video: '-HaHCj_IYNs?t=655&si=ob2giqjEkmJtMrmi',
          toons: [
            { image: 'saw', main: true },
            { image: 'drogan' },
            { image: 'cara' },
            { image: 'baze' },
            { image: 'cassian' }
          ],
          alternatives: [
            {
              videoThumb: '',
              toons: [
                { image: 'saw', main: true },
                { image: 'kyle' },
                { image: 'cara' },
                { image: 'baze' },
                { image: 'cassian' }
              ]
            }
          ]
        },
        {
          title: 'Wookies',
          video: '-HaHCj_IYNs?t=54&si=V0LRpqgMfY0lwKJ9',
          toons: [
            { image: 'tarfful' },
            { image: 'chewie' },
            { image: 'vet-chewie' },
            { image: 'vandor-chewie' },
            { image: 'threepio' }
          ],
          alternatives: [
            {
              videoThumb: '',
              toons: [
                { image: 'tarfful' },
                { image: 'chewie' },
                { image: 'zaalbar' },
                { image: 'cw-chewie' },
                { image: 'threepio' }
              ]
            }
          ]
        },
        {
          title: 'LS',
          video: '-HaHCj_IYNs?t=1003&si=HkNYYe3creNkh8_a',
          toons: [
            { image: 'jmk' },
            { image: 'cat' },
            { image: 'padme' },
            { image: 'jka' },
            { image: 'gk' }
          ],
          alternatives: [
            {
              videoThumb: 'cuQf5cD_HoE?t=608&si=tC5GN4H4cTY0GRkX',
              toons: [
                { image: 'amidala' },
                { image: 'kelleran' },
                { image: 'padawan-obi' },
                { image: 'master-qgj' },
                { image: 'kam' }
              ]
            },
            {
              videoThumb: 'IRNnaG4W6oU?t=595&si=ufYO1ALnfo1g5xuq',
              toons: [
                { image: 'rjt' },
                { image: 'r2' },
                { image: '3po' },
                { image: 'bb' },
                { image: 'scavenger' }
              ]
            }
          ]
        },
        {
          title: 'LS',
          video: 'watch?v=tuijX4-bWP8&t=403s',
          toons: [
            { image: 'gl-leia' },
            { image: 'cls' },
            { image: 'han' },
            { image: 'admiralraddus' },
            { image: 'kanan' }
          ],
          alternatives: [
            {
              videoThumb: 'IRNnaG4W6oU?t=417&si=FWbI2_DaPOlwTSRE',
              toons: [
                { image: 'jkl' },
                { image: 'jml' },
                { image: 'gmy' },
                { image: 'shaak-ti' },
                { image: 'jka' }
              ]
            }
          ]
        },
        {
          title: 'Fleet',
          video: 'jsdTV585mbY?t=232&si=RyX0hWMdUOgOfrBz',
          toons: [
            { image: 'profundity', main: true },
            { image: 'milf' },
            { image: 'rebel-ywing' },
            { image: 'outrider' },
            { image: 'phantom' }
          ],
        }
      ]
    },
    {
      title: 'Zeffo',
      path: 'ls-bonus',
      loadouts: [
        {
          title: 'Clones',
          video: 'EkS1iNQyKTI?t=510&si=1qILUGl7jWMRJJx4',
          toons: [
            { image: 'rex' },
            { image: 'arc' },
            { image: 'captain-rex' },
            { image: 'echo' },
            { image: 'fives' }
          ],
          alternatives: [
            {
              videoThumb: '',
              toons: [
                { image: 'hunter' },
                { image: 'bb-echo' },
                { image: 'tech' },
                { image: 'omega' },
                { image: 'wrecker' }
              ]
            }
          ]
        },
        {
          title: 'Jedi',
          video: 'EkS1iNQyKTI?t=834&si=DvLHJRfekrl0ScCD',
          toons: [
            { image: 'jedi-cal', main: true },
            { image: 'jkl' },
            { image: 'jml' },
            { image: 'gas' },
            { image: 'shaak-ti' }
          ]
        },
        {
          title: 'UFU',
          video: 'EkS1iNQyKTI?t=1041&si=GARul2dur69HbIfb',
          toons: [
            { image: 'rey' },
            { image: 'cal' },
            { image: 'cere' },
            { image: 'ben-solo' },
            { image: 'fulcrum' }
          ]
        },
        {
          title: 'LS',
          video: 'EkS1iNQyKTI?t=1437&si=c5QCb1P3nZhvm1qz',
          toons: [
            { image: 'rjt' },
            { image: 'r2' },
            { image: '3po' },
            { image: 'bb' },
            { image: 'scavenger' }
          ],
          alternatives: [
            {
              videoThumb: 'EkS1iNQyKTI?t=1610&si=WmIAxj7UKnkpAvMS',
              toons: [
                { image: 'jmk' },
                { image: 'cat' },
                { image: 'padme' },
                { image: 'jka' },
                { image: 'gk' }
              ]
            },
            {
              videoThumb: 'EkS1iNQyKTI?t=1225&si=MvxIDklTtbfw6qFX',
              toons: [
                { image: 'gl-leia' },
                { image: 'admiralraddus' },
                { image: 'cls' },
                { image: 'kanan' },
                { image: 'han' }
              ]
            },
            {
              videoThumb: '',
              toons: [
                { image: 'cls' },
                { image: 'han' },
                { image: 'chewie' },
                { image: 'threepio' },
                { image: '3po' }
              ]
            }
          ]
        },
        {
          title: 'Fleet',
          videoThumb: 'EkS1iNQyKTI?t=1795&si=nwsZrHIb9V_J8N49',
          toons: [
            { image: 'negotiator', main: true },
            { image: 'marauder-ship' },
            { image: 'btl-ywing' },
            { image: 'ani-eta' },
            { image: 'plo-starfighter' }
          ],
        }
      ]
    },
    {
      title: 'Tatooine',
      path: 'mix',
      loadouts: [
        {
          title: `Bo-Katan`,
          video: 'ZVANqmwW9gQ?t=597&si=ZihfxNccKWttTLNj',
          toons: [
            { image: 'bo-mandalor', main: true, name: 'AUTO' },
            { image: 'bam', main: true },
            { image: 'ig-12' },
          ],
          alternatives: [
            {
              toons: [
                { image: 'bo-mandalor', main: true },
                { image: 'bam', main: true },
                { image: 'paz' },
              ],
            }
          ]
        },
        {
          title: `Reva`,
          video: '2Q8f_Gpnkcg?t=23&si=Sa_HqVZx77mg63TM',
          toons: [
            { image: 'gi', main: true },
            { image: 'ninth-sister' },
            { image: 'seventh-sister' },
            { image: 'eighth-brother' },
            { image: 'fifth-brother' }
          ],
          alternatives: [
            {
              videoThumb: 'ZVANqmwW9gQ?t=677&si=CbNuZNW6-e47_Bq_',
              toons: [
                { image: 'gi', main: true, name: 'AUTO' },
                { image: 'reva' },
                { image: 'seventh-sister' },
                { image: 'eighth-brother' },
                { image: 'fifth-brother' }
              ],
            }
          ]
        },
        {
          title: 'Fennec',
          video: '-n_9bepyhpQ?t=965&si=Tgy58NqqfGZhy9Vt',
          toons: [
            { image: 'gba' },
            { image: 'fennec', main: true },
            { image: 'sun-fac' },
            { image: 'poggle' },
            { image: 'wampa' }
          ],
          alternatives: [
            {
              videoThumb: 'ZVANqmwW9gQ?t=818&si=zGs6SQx4O8yfVBSg',
              toons: [
                { image: 'malgus', name: 'AUTO' },
                { image: 'fennec', main: true },
                { image: 'malak' },
                { image: 'fallen-basti' },
                { image: 'set' }
              ]
            },
            {
              videoThumb: 'sI8t5Pg3NX8?t=380&si=bij-2fa2whMyAZxq',
              toons: [
                { image: 'jmk' },
                { image: 'fennec', main: true },
                { image: 'padme' },
                { image: 'gk' },
                { image: 'jka' }
              ]
            },
            {
              videoThumb: 'sI8t5Pg3NX8?t=257&si=fWT0zvu7cnL7xoFH',
              toons: [
                { image: 'boba' },
                { image: 'fennec', main: true },
                { image: 'zam' },
                { image: 'wampa' },
                { image: 'jango' }
              ]
            }
          ]
        },
        {
          title: 'Jabba',
          video: 'ZVANqmwW9gQ?t=728&si=_Oi1GEeEb_Shn2sY',
          toons: [
            { image: 'jabba', main: true, name: 'AUTO' },
            { image: 'boba' },
            { image: 'cad' },
            { image: 'zam' },
            { image: 'dash' }
          ],
          alternatives: [
            {
              videoThumb: 'k_Msldf_jqA?t=366&si=GrlIPyCbm65MTV3X',
              toons: [
                { image: 'jabba', main: true },
                { image: 'han' },
                { image: 'chewie' },
                { image: 'dash' },
                { image: 'boba' }
              ]
            }
          ]
        },
        {
          title: 'Mix',
          video: 'ZVANqmwW9gQ?t=769&si=VCjZHhfoT61Xh0-R',
          toons: [
            { image: 'slkr', name: 'AUTO' },
            { image: 'sith-trooper' },
            { image: 'phasma' },
            { image: 'kylo' },
            { image: 'kru' }
          ],
          alternatives: [
            {
              videoThumb: 'sI8t5Pg3NX8?t=491&si=am_FTIggU0VXKDof',
              toons: [
                { image: 'rey' },
                { image: 'fives' },
                { image: 'echo' },
                { image: 'arc' },
                { image: 'admiralraddus' }
              ]
            },
          ]
        },
        {
          title: 'Fleet',
          video: 'ZVANqmwW9gQ?t=877&si=zAjqtMNV3VpKjEO4',
          toons: [
            { image: 'executor', name: 'AUTO' },
            { image: 'houndstooth' },
            { image: 'razor-crest' },
            { image: 'xanadu-blood' },
            { image: 'ig-2000' }
          ]
        }
      ]
    },
    {
      title: 'Dathomir',
      path: 'ds',
      loadouts: [
        {
          title: `Aphra`,
          video: 'ZVANqmwW9gQ?t=155&si=bIgscm1BG2kIHHxV',
          toons: [
            { image: 'aphra', main: true, name: 'AUTO' },
            { image: 'hk' },
            { image: 'gg' },
            { image: 'vader' },
            { image: 'bt-1' }
          ]
        },
        {
          title: 'Merrin',
          video: '-n_9bepyhpQ?t=66&si=w6KeIjPtzkT6lcIe',
          toons: [
            { image: 'daka' },
            { image: 'merrin', main: true },
            { image: 'zombie' },
            { image: 'asajj' },
            { image: 'talzin' }
          ],
          alternatives: [
            {
              videoThumb: 'w754OsqH5vs?t=465&si=ekm_oWPDjK--TpLp',
              toons: [                
                { image: 'talzin' },
                { image: 'daka' },
                { image: 'merrin', main: true },
                { image: 'zombie' },
                { image: 'asajj' }
              ]
            }
          ]
        },
        {
          title: 'Empire',
          video: 'ZVANqmwW9gQ?t=102&si=xRWdouwWGLJVzkks',
          toons: [
            { image: 'lv', name: 'AUTO' },
            { image: 'rg' },
            { image: 'mara' },
            { image: 'ninth-sister' },
            { image: 'piett' }
          ],
          alternatives: [
            {
              videoThumb: 'p9LAD2WlaoU?t=263&si=Q5eC6HnOKJmVWytl',
              toons: [
                { image: 'lv' },
                { image: 'rg' },
                { image: 'vader' },
                { image: 'tarkin' },
                { image: 'piett' }
              ]
            }
          ]
        },
        {
          title: 'DS',
          video: 'ZVANqmwW9gQ?t=213&si=1abDQAJBvIdNwKcM',
          toons: [
            { image: 'trench', name: 'AUTO' },
            { image: 'wat' },
            { image: 'nute' },
            { image: 'jango' },
            { image: 'dooku' }
          ],
          alternatives: [
            {
              videoThumb: 'p9LAD2WlaoU?t=393&si=j0UF0z5wCbzs8w8w',
              toons: [
                { image: 'palpatine' },
                { image: 'sid' },
                { image: 'dooku' },
                { image: 'see' },
                { image: 'malgus' }
              ]
            }
          ]
        },
        {
          title: 'DS',
          video: '-n_9bepyhpQ?t=690&si=6BYEVkafApFXGTU3',
          toons: [
            { image: 'dr' },
            { image: 'see' },
            { image: 'malak' },
            { image: 'malgus' },
            { image: 'set' }
          ],
          alternatives: [
            {
              videoThumb: 'p9LAD2WlaoU?t=572&si=P2ga4omoFXDuoUc8',
              toons: [
                { image: 'slkr' },
                { image: 'jango' },
                { image: 'gg' },
                { image: 'kru' },
                { image: 'nute' }
              ]
            }
          ]
        }
      ]
    }
  ]
}