import React from 'react'

const GgCounterBanner = (props) => {
  const { type, leader, season, isShips } = props

  const onCountersClick = () => {
    const getid = leader ? leader.base_id : ''
    window.open(`https://swgoh.gg/gac/${isShips ? 'ship-' : ''}counters/${getid}/?season_id=${season}`, '_blank')
  }

  const onInsightClick = () => {
    const getid = leader ? leader.base_id : ''
    const counterType = isShips ? 'combat_type=2' : `squad_size=${type}`
    window.open(`https://swgoh.gg/gac/insight/squads/?g=1&show_cleanups=false&league=KYBER&d_lead=${getid}&${counterType}`, '_blank')
  }

  return (
    <div className='sw-gg-banner'>
      <div className='sw-gg-banner__background'>
        <div className='sw-gg-logo'>
          <img src='/images/external/gg-logo.png' alt='swgoh.gg' className='sw-gg-logo__image' />
        </div>
        <div className='sw-gg-button'>
          <button className='btn btn-sm btn-light sw-gg-button__element' onClick={onCountersClick}>Counters</button>
        </div>
        <div className='sw-gg-button'>
          <button className='btn btn-sm btn-light sw-gg-button__element' onClick={onInsightClick}>Insights</button>
        </div>
      </div>
    </div>
  )
}

export default GgCounterBanner