import React from 'react';
import classNames from 'classnames';
import './_style.scss';


const ScoreBar = props => {

  const getProgress = (props.value / props.max * 100).toFixed(2);
  const getTarget = (props.target / props.max * 100).toFixed(2);

  return (
    <div className={classNames('sw-score-bar', props.progress && 'sw-score-bar--progress')}>
      {!props.progress && <div className="sw-score-bar__title">{props.title} - {props.target}</div>}
      <div className={classNames('sw-score-bar__diagram', props.progress && 'sw-score-bar__diagram--progress')}>
        {props.progress && <div className="sw-score-bar__name">{props.title}</div>}
        <div className="progress sw-score-bar__progress">
          <div
            style={{ width: `${getProgress}%` }}
            className={classNames('progress-bar sw-score-bar__progress-bar', props.value >= props.target ? 'bg-success' : 'bg-secondary')}
          />
          <div style={{ left: `${getTarget <= 100 ? getTarget : 100}%` }} className="sw-score-bar__goal" />
          {props.progress && getProgress > 0 && getProgress < 100 &&
            <div
              style={{ left: `${getProgress}%` }}
              className={classNames('sw-score-bar__value', props.value >= props.target ? 'sw-score-bar__value--success' : 'sw-score-bar__value--default')}
            >{props.value}</div>
          }
        </div>
        {props.progress ?
          <div className="sw-score-bar__status">
            {props.value >= props.target ?
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              :
              props.target
            }
          </div>
          :
          <div style={{ left: `${getTarget <= 100 ? getTarget : 100}%` }} className="sw-score-bar__indicator" />
        }
        {!props.progress && getProgress > 0 &&
          <div
            style={{ left: `${getProgress}%` }}
            className={classNames('sw-score-bar__value', props.value >= props.target ? 'sw-score-bar__value--success' : 'sw-score-bar__value--default')}
          >{props.value}</div>
        }
      </div>
    </div>
  );
}

export default ScoreBar;