import React, { useState, useEffect } from 'react';

import Toon from '../toon';

const sectorBonus = [
  { id: 1, s: 0, e: 20 },
  { id: 2, s: 20, e: 60 },
  { id: 3, s: 30, e: 70 },
  { id: 4, s: 40, e: 80 },
  { id: 5, s: 50, e: 90 }
];

const speedCalc = (sector, base, flat, prepared, modifier) => {
  return Math.round((((1 + (sector * 1.6 / 100)) * base) + flat) * (1 + prepared + modifier));
}

const SpeedToon = (props) => <div className="sw-conquest-speed__tier">
  <span className="badge badge-info rounded-pill">{props.label}</span>
  <div className="sw-conquest-speed__range">
    {speedCalc(props.start, props.speed, props.bonus, props.prepared, props.min)} - {speedCalc(props.end, props.speed, props.bonus, props.prepared, props.max)}
  </div>
</div>;

const ConquestSpeed = props => {
  const [sector, setSector] = useState({ id: 1, s: 0, e: 20 });

  useEffect(() => {
    props.sector && getSectorRange(props.sector);
  }, [props.sector]);

  const getSectorRange = (v) => {
    let sectorData = sectorBonus.find(b => b.id === v);
    setSector(sectorData);
  }

  return (
    <div className="sw-conquest-speed">

      {props.prepared &&
        <div className="sw-conquest-speed__tiers">
          {props.prepared.none && <span className="badge badge-info rounded-pill">0</span>}
          {props.prepared.one && <span className="badge badge-info rounded-pill">I</span>}
          {props.prepared.two && <span className="badge badge-info rounded-pill">II</span>}
          {props.prepared.three && <span className="badge badge-info rounded-pill">III</span>}
          {props.prepared.four && <span className="badge badge-info rounded-pill">IV</span>}
        </div>
      }

      {props.toons && props.toons.map((t, i) =>
        <div className="sw-conquest-speed__toon" key={i}>
          <div className="sw-conquest-speed__image">
            <Toon image={t.image} />
          </div>

          {props.prepared &&
            <div className="sw-conquest-speed__data">
              {props.prepared.none &&
                <SpeedToon start={sector.s} end={sector.e} speed={t.speed} bonus={t.bonus} min={props.min} max={props.max} prepared={0} label="0" />
              }
              {props.prepared.one &&
                <SpeedToon start={sector.s} end={sector.e} speed={t.speed} bonus={t.bonus} min={props.min} max={props.max} prepared={.2} label="I" />
              }
              {props.prepared.two &&
                <SpeedToon start={sector.s} end={sector.e} speed={t.speed} bonus={t.bonus} min={props.min} max={props.max} prepared={.4} label="II" />
              }
              {props.prepared.three &&
                <SpeedToon start={sector.s} end={sector.e} speed={t.speed} bonus={t.bonus} min={props.min} max={props.max} prepared={.6} label="III" />
              }
              {props.prepared.four &&
                <SpeedToon start={sector.s} end={sector.e} speed={t.speed} bonus={t.bonus} min={props.min} max={props.max} prepared={.8} label="IV" />
              }
            </div>
          }
        </div>
      )}

    </div>
  );
}

export default ConquestSpeed;