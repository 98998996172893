import React, { useState } from 'react';

import Dialog from '../../components/dialog';
import axios from 'axios';

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function generateString(length) {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const ShareDatacronList = (props) => {
  const { list, getActiveSet } = props
  const [showDialog, toggleDialog] = useState(false);
  const [shareName, setShareName] = useState('');
  const [shareUrl, setShowUrl] = useState('');

  const onShare = () => {
    const urlHash = generateString(16)
    axios.post('https://swgoh4.life/api/share-datacrons.php', {
      shareUrl: urlHash,
      shareName: shareName,
      shareData: JSON.stringify(list),
      setId: getActiveSet
    })
      .then(response => {
        setShowUrl(urlHash)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onClose = () => {
    toggleDialog(false)
    setShareName('')
    setShowUrl('')
  }

  return (
    <>
      <button className='btn btn-sm btn-info px-3 mr-2' onClick={() => toggleDialog(true)}>Share</button>
      {showDialog &&
        <Dialog
          title='Share Datacron List'
          cancel={{
            label: 'Close',
            theme: 'outline-dark',
            click: onClose
          }}
          confirm={{
            label: 'Share',
            theme: 'primary',
            disabled: !shareName,
            click: () => {
              onShare()
            }
          }}
        >
          <div className='text-left'>
            {shareUrl ?
              <>
                <strong>{shareName} share link generated</strong>
                <div>
                  <a
                    href={`https://swgoh4.life/datacrons/list?id=${shareUrl}`}
                    target='_blank' rel='noopener noreferrer' className='text-secondary'
                  >
                    https://swgoh4.life/datacrons/list?id={shareUrl}
                  </a>
                </div>
              </>
              :
              <>
                <label htmlFor='shareName'>Share Name</label>
                <input
                  type='text'
                  className='form-control border-dark'
                  name='shareName'
                  id='shareName'
                  value={shareName}
                  onChange={(e) => setShareName(e.target.value)}
                />
              </>
            }
          </div>
        </Dialog>
      }
    </>
  );
}

export default ShareDatacronList;