const sector1 = [  
  {
    title: 'Tenacity Down',
    text: 'Attempt to inflict Tenacity Down 50 times',
    keycards: 5,
    teams: [
      {
        videoThumb: 'VSOm6nRcj4g?t=52&si=IgOkt_v_My2N5lrT',
        feats: [
          { name: 'Tenacity Down', qty: 10 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie', main: true },
          { image: 'threepio', name: 'Threepio' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=141&si=p5cZSWDcFysehgUA',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=198&si=-SrLl7otiRV-sLMc',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 1 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara', main: true },
          { image: 'morgan', name: 'Morgan' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=93&si=fEdQWBjizaqS2Ta6',
        feats: [
          { name: 'Tenacity Down', qty: 7 }
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'dr', name: 'Darth Revan' },
          { image: 'hk', name: 'HK', main: true },
          { image: 'malak', name: 'Malak', main: true },
          { image: 'fallen-basti', name: 'Fallen Basti' },
          { image: 'cal', name: 'Cal', main: true },
        ]
      },
    ]
  },

  {
    title: 'Morgan',
    text: 'Win 10 battles with Morgan Elsbeth surviving',
    keycards: 5,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=298&si=09dPrfS3ltfnFVzo',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=235&si=TNR_eIIVuTqJZL0D',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [          
          { image: 'talzin', name: 'Talzin' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin'},
          { image: 'asajj', name: 'Asajj' }
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
    ]
  },
  {
    title: 'Gungans',
    text: 'Defeat 35 enemies with Gungans',
    keycards: 5,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=472&si=wm1imXfYy9lKb5nn',
        feats: [
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'traya', name: 'Traya' },
          { image: 'malak', name: 'Malak' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=141&si=p5cZSWDcFysehgUA',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: 'VSOm6nRcj4g?t=198&si=-SrLl7otiRV-sLMc',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 1 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      },
    ]
  },
  {
    title: 'Decay',
    text: 'Gain Decay 50 times',
    keycards: 5,
    teams: [
      {
        videoThumb: 'VSOm6nRcj4g?t=366&si=eJO1o08slFIwcxTl',
        feats: [
          { name: 'Decay', qty: 10 },
        ],
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'tfp', name: 'Tie Pilot' },
          { image: 'stormtrooper', name: 'Stormtrooper' }
        ]
      }
    ]
  },
  {
    title: 'Mini Boss',
    text: `Battle vs Jawas with a full squad of Geonosians and with Light Side only`,
    keycards: 7,
    teams: [
      {
        name: `Geonosians`,
        videoThumb: '5AeDDhXuz5A?t=113&si=s3u9vmF2kSczOBSn',
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
      {
        name: `Light Side`,
        videoThumb: '5AeDDhXuz5A?t=204&si=a1Eux91EskcKSkAV',
        feats: [
          { name: 'Tenacity Down', qty: 10 },
        ],
        toons: [          
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'Arc Trooper' },
          { image: 'rex', name: 'Rex' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'fives', name: 'Fives' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Darth Malgus with Queen Amidala defeating an enemy and with Gungan Boomadier surviving',
    keycards: 9,
    teams: [
      {
        name: 'Queen Amidala',
        videoThumb: '5AeDDhXuz5A?t=285&si=5vGM0LS-95zZZqeR',
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jml', name: 'Mster Luke'  },
          { image: 'amidala', name: 'Amidala', main: true  },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        name: 'Boomadier',
        videoThumb: '5AeDDhXuz5A?t=384&si=qp1-oFQFgsR4Km4m',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier', main: true },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: '5AeDDhXuz5A?t=465&si=VPO4_WJO5sUCm7hk',
        toons: [
          { image: 'see', name: 'Sith Eternal' },
          { image: 'traya', name: 'Traya' },
          { image: 'nihilus', name: 'Nihilus' },
          { image: 'boomadier', name: 'Boomadier', main: true },
          { image: 'malak', name: 'Malak' },
        ]
      }
    ]
  }
];

export {
  sector1
}