import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import SubMenu from '../../components/submenu';
import Section from '../../components/section';
import { rey, kylo, jml, see, solo } from './data/videos';

const menuLinks = [  
  { label: 'Solo Journeys' },
  { label: 'SEE' },
  { label: 'JML' },
  { label: 'Rey' },
  { label: 'SLKR' }
];

const GalacticLegends = () => {
  const [activeTab, selectTab] = useState(0);

  useEffect(() => {
    document.title = 'Journey Guide - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      Video guides for unlocking Galactic Legends as well as other Solo Journey legendary units.
    </div>
  </div>;

  return (
    <Page title="Journey Guide" intro={intro}>

      <SubMenu theme='light' links={menuLinks} active={activeTab} onClick={selectTab} breakpoint={768} />
      
      {activeTab === 0 && <Section title="Solo Journeys" videos={solo} />}
      {activeTab === 1 && <Section title="Sith Eternal Emperor" videos={see} />}
      {activeTab === 2 && <Section title="Jedi Master Luke" videos={jml} />}
      {activeTab === 3 && <Section title="Rey" videos={rey} />}
      {activeTab === 4 && <Section title="Supreme Leader Kylo Ren" videos={kylo} />}

    </Page>
  );
}

export default GalacticLegends;
