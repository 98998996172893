const three = [
    { name: 'Secrets and Shadows', desc: `Merrin takes NS to the next level!`, id: 'Mnp2karvR8s' },
    { name: 'Fanatical Devotion', desc: `Purge the Jedi with Grand Inquisitor`, id: 'pUEEYo3IvzI' },
    { name: 'Rebel Round-up', desc: `Steamroll the rebel scum with Imperial Troopers`, id: 'OH7z8HWy3Y0' },
    { name: 'Military Might', desc: `CLS + Double Chewies roll over the Empire with the TM train`, id: 'dUBrL8cwtCg' },
    { name: 'Forest Moon', desc: `Piet and Imperial Troopers wipe out Ewoks`, id: 'Z3RFJWpBkMg' },
    { name: 'Ground War', desc: `Jedi Knight Luke calls his fellow Jedi Heroes to arise and bring down the Separatists`, id: 'JRIMxBsbesc' },
    { name: 'Place of Power', desc: `Semi-autoplay with Palp, Vader, Malak, Basti and Nihilus`, id: 'zyqWAjMNKhs' }
];

const two = [
    { name: 'Fanatical Devotion', desc: `No GI - No Problem!`, id: 'pjEC1LIBHPo' },
    { name: 'Forest Moon - Tropers', desc: `Insert Piet, Imperial Troopers and press Autoplay`, id: 'id_A1XwSBPI' },
    { name: 'Forest Moon - Droids', desc: `Thrawn and droids capture the Ewoks in Challenge Tier 2`, id: 'YFf-jZixXVk' },
    { name: 'Ground War', desc: `Beat Challenge Tier 2 with Jedi Knight Revan and his friends`, id: 'VysH_k3Psn0' },
    { name: 'Military Might - Rex', desc: `Win Challenge Tier 2 with Rex and Chewie`, id: 'UABd7vKn71g' },
    { name: 'Military Might - CLS', desc: `Take down the Empire with Commander Luke Skywalker and his band of rebels`, id: 'colAEoq9UxE' },
    { name: 'Military Might - Cody', desc: `Brush off the dust from your zeta Cody to defeat the Empire`, id: 'Wh6xLEilRAU' },
    { name: 'Places of Power', desc: `Autoplay Challenge Tier 2 with reworked Vader`, id: 'EqdpJlZibXs' },
    { name: 'Rebel Round-up', desc: `Outlast Rebels in Challenge Tier 2 with Bossk and his fellow bounty hunters`, id: '6uS7XSC_ttE' },
    { name: 'Secrets and Shadows', desc: `R5 Daka, R4 Asajj, G12 Talzin & Zombie + G11 Talia`, id: '6Q3TwLEi_UU' }
];

const one = [
    { name: 'Forest Moon', desc: `Autoplay Challenge Tier 1 with Thrawn, separatist droids and BB-8`, id: 'BpH7Uha4PcM' },
    { name: 'Places of Power', desc: `Autoplay Challenge Tier 1 with Palpatine lead`, id: '3a5lEX1Wt7s' },
    { name: 'Secrets and Shadows', desc: `Talzin with G12 NS and relic Asajj`, id: '4GFqn3orxeY' }
];

export {
    two,
    one,
    three
}