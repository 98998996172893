import React from 'react';
import classNames from 'classnames';

const DataDisk = props => {
  const { icon, slots, rarity, name, desc } = props

  return (
    <div className='sw-data-disk'>
      <div className={classNames('sw-data-disk__image', `sw-data-disk__image--${rarity}`)}>
        <div className={classNames('sw-data-disk__icon', `sw-data-disk__icon--${icon}`)} />
        <div className='sw-data-disk__slots'>
          {slots.map(s =>
            <div key={s} className='sw-data-disk__slot' />
          )}
        </div>
      </div>
      <div className='sw-data-disk__content'>
        <div className='sw-data-disk__name'>
          {name}
        </div>
        <div className='sw-data-disk__desc'>
          {desc}
        </div>
      </div>
    </div>
  );
}

export default DataDisk;