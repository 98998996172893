import { toons, capitals } from '../data/planner/characters';

const divisions = [
    { 
        id: 1, defense: { teams: 14, banners: 0, zones: 909 }, 
        north: { teams: 4, banners: 240 }, 
        south: { teams: 4, banners: 240 }, 
        trap: { teams: 3, banners: 210 }, 
        fleet: { teams: 3, banners: 219 },
        maxBanners: 1918,
        maxOffenseBanners: 999,
        maxAvgBanners: 71
    },
    { 
        id: 2, defense: { teams: 11, banners: 0, zones: 816 }, 
        north: { teams: 3, banners: 210 }, 
        south: { teams: 3, banners: 210 }, 
        trap: { teams: 3, banners: 210 }, 
        fleet: { teams: 2, banners: 186 },
        maxBanners: 1607,
        maxOffenseBanners: 781,
        maxAvgBanners: 71
    },
    { 
        id: 3, defense: { teams: 9, banners: 0, zones: 756 }, 
        north: { teams: 3, banners: 210 }, 
        south: { teams: 2, banners: 180 }, 
        trap: { teams: 2, banners: 180 }, 
        fleet: { teams: 2, banners: 186 },
        maxBanners: 1409,
        maxOffenseBanners: 643,
        maxAvgBanners: 71
    },
    { 
        id: 4, defense: { teams: 6, banners: 0, zones: 663 }, 
        north: { teams: 2, banners: 180 }, 
        south: { teams: 2, banners: 180 }, 
        trap: { teams: 1, banners: 150 }, 
        fleet: { teams: 1, banners: 153 },
        maxBanners: 1098,
        maxOffenseBanners: 425,
        maxAvgBanners: 71
    },
    { 
        id: 5, defense: { teams: 4, banners: 0, zones: 603 }, 
        north: { teams: 1, banners: 150 }, 
        south: { teams: 1, banners: 150 }, 
        trap: { teams: 1, banners: 150 }, 
        fleet: { teams: 1, banners: 153 },
        maxBanners: 900,
        maxOffenseBanners: 287,
        maxAvgBanners: 72
    }
];

const divisions3v3 = [
    { 
        id: 1, defense: { teams: 18, banners: 0, zones: 999 }, 
        north: { teams: 5, banners: 260 }, 
        south: { teams: 5, banners: 260 }, 
        trap: { teams: 5, banners: 260 }, 
        fleet: { teams: 3, banners: 219 },
        maxBanners: 2122,
        maxOffenseBanners: 1113,
        maxAvgBanners: 62
    },
    { 
        id: 2, defense: { teams: 15, banners: 0, zones: 910 }, 
        north: { teams: 4, banners: 232 }, 
        south: { teams: 4, banners: 232 }, 
        trap: { teams: 5, banners: 260 }, 
        fleet: { teams: 2, banners: 186 },
        maxBanners: 1839,
        maxOffenseBanners: 919,
        maxAvgBanners: 61
    },
    { 
        id: 3, defense: { teams: 12, banners: 0, zones: 826 }, 
        north: { teams: 3, banners: 204 }, 
        south: { teams: 3, banners: 204 }, 
        trap: { teams: 4, banners: 232 }, 
        fleet: { teams: 2, banners: 186 },
        maxBanners: 1578,
        maxOffenseBanners: 742,
        maxAvgBanners: 62
    },
    { 
        id: 4, defense: { teams: 8, banners: 0, zones: 709 }, 
        north: { teams: 2, banners: 176 }, 
        south: { teams: 2, banners: 176 }, 
        trap: { teams: 3, banners: 204 }, 
        fleet: { teams: 1, banners: 153 },
        maxBanners: 1208,
        maxOffenseBanners: 489,
        maxAvgBanners: 61
    },
    { 
        id: 5, defense: { teams: 4, banners: 0, zones: 597 }, 
        north: { teams: 1, banners: 148 }, 
        south: { teams: 1, banners: 148 }, 
        trap: { teams: 1, banners: 148 }, 
        fleet: { teams: 1, banners: 153 },
        maxBanners: 860,
        maxOffenseBanners: 253,
        maxAvgBanners: 63
    }
];

const calcZoneBanners = (zone, offense) => {
    let cleared = [];
    offense.length > 0 && offense.forEach((o) => o && o.banners && cleared.push(Number(o.banners)));
    let offenseSum = cleared.length > 0 && cleared.reduce((a,b) => a + b);
    let zoneClear = cleared.length === zone.teams ? zone.banners : 0;
    return offenseSum + zoneClear;
}

const currentBanners = (offense) => {
    let cleared = [];
    let banners = 0;
    offense['north'] && offense['north'].forEach((o) => { 
        if(o && o.banners) {
            cleared.push(Number(o.banners));
            banners = banners + Number(o.banners);
        }
    });
    offense['south'] && offense['south'].forEach((o) => { 
        if(o && o.banners) {
            cleared.push(Number(o.banners));
            banners = banners + Number(o.banners);
        }
    });
    offense['trap'] && offense['trap'].forEach((o) => { 
        if(o && o.banners) {
            cleared.push(Number(o.banners));
            banners = banners + Number(o.banners);
        }
    });
    offense['fleet'] && offense['fleet'].forEach((o) => { 
        if(o && o.banners) {
            cleared.push(Number(o.banners));
            banners = banners + Number(o.banners);
        }
    });
    return { cleared: cleared, banners: banners }
}

export const getUnitByBase = (base, type) => {
  let list = type === 'fleet' ? capitals : toons;
  let data = list.find(f => f.base_id === base);
  return data;
}

export const currentScore = (division, offense, gac) => {
    let gacFormat = gac === '3v3' ? divisions3v3 : divisions;
    let getDiv = gacFormat.find((d => d.id === Number(division)));
    let total = calcZoneBanners(getDiv.north, offense.north) + calcZoneBanners(getDiv.south, offense.south) + calcZoneBanners(getDiv.trap, offense.trap) + calcZoneBanners(getDiv.fleet, offense.fleet) + getDiv.defense.banners;
    return total;
}

export const getAvgBanners = (target, division, gac) => {
    let gacFormat = gac === '3v3' ? divisions3v3 : divisions;
    let getDiv = gacFormat.find((d => d.id === Number(division)));
    let max = getDiv.maxAvgBanners;
    let targetMax = target - getDiv.defense.banners - getDiv.defense.zones;
    let targetBan = targetMax / getDiv.defense.teams;
    return { max: max, target: targetBan.toLocaleString('en-GB', {maximumFractionDigits: 1}) };
}

export const getTargetAvgBanners = (division, offense, target, gac) => {
    let gacFormat = gac === '3v3' ? divisions3v3 : divisions;
    let getDiv = gacFormat.find((d => d.id === Number(division)));
    let current = currentBanners(offense);
    let targetBanners = target - getDiv.defense.banners - getDiv.defense.zones - current.banners;
    let battlesLeft = getDiv.defense.teams - current.cleared.length;
    let result = (targetBanners / battlesLeft).toLocaleString('en-GB', {maximumFractionDigits: 1});
    return { result: result, banners: targetBanners, left: battlesLeft };
}

export const getCurrentAvgBanners = (offense) => {
    let current = currentBanners(offense);
    let avgBanners = (current.banners / current.cleared.length).toLocaleString('en-GB', {maximumFractionDigits: 1});
    return avgBanners;
}

export const getMaxTeams = (division, zone, gac) => {
    let gacFormat = gac === '3v3' ? divisions3v3 : divisions;
    let getDiv = gacFormat.find((d => d.id === Number(division)));
    return getDiv[zone].teams;
}

export const getMaxBanners = (division, gac) => {
    let gacFormat = gac === '3v3' ? divisions3v3 : divisions;
    let getBanners = gacFormat.find((d => d.id === Number(division)));
    return getBanners.maxBanners;
}

export const removeSelectedToons = (all, selected, zones) => {
    const toRemove = new Set(zones ? [...selected.north, ...selected.south, ...selected.fleet, ...selected.trap] : selected);
    const difference = all.filter( x => !toRemove.has(x));
    return difference;
}