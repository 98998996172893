import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';

import DatacronItem from './item';
import DatacronAccounts from './accounts';
import Dialog from '../../components/dialog';
import ShareDatacronList from './share';

const DatacronList = (props) => {
  const { dataZero, dataOne, activeTab, list, setList, loadedAccount, setLoadedAccount, loadedAccountString, setLoadedAccountString } = props
  const [showAccounts, toggleAccounts] = useState(false);
  const [showDialog, toggleDialog] = useState(false);
  const [deleteDatacron, setDeleteDatacron] = useState(null);
  const getActiveSet = activeTab === 0 ? '18' : '17'
  const getActiveData = activeTab === 0 ? dataZero : dataOne

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    const updateList = (prevItems) => update(prevItems, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevItems[dragIndex]]
      ]
    })
    setList((prevItems) => {
      if (activeTab === 0) {
        localStorage.setItem(`dcFarmingList18${loadedAccountString}`, JSON.stringify(updateList(prevItems)))
      } else {
        localStorage.setItem(`dcFarmingList17${loadedAccountString}`, JSON.stringify(updateList(prevItems)))
      }
      return updateList(prevItems)
    })
  }, [activeTab, loadedAccountString, setList])

  const onUpdateList = useCallback((id, type, bonus) => {
    let updateList = []
    list.forEach(l => {
      if (l.id === id) {
        let updateItem = {}

        if (type === 'complete') {
          const findBonus = l[bonus]
          const allLevels = [l.l3, l.l6, l.l9]
          let updateAllLevels = []
          allLevels.forEach(a => {
            if (a) {
              if (a.id === findBonus.id) {
                updateAllLevels.push({ ...a, complete: !a.complete })
              } else {
                updateAllLevels.push(a)
              }
            }
          })
          const allLevelsCleaned = updateAllLevels.filter(f => f !== undefined)
          const allLevelsComplete = allLevelsCleaned.map(f => f.complete)
          const completeAll = allLevelsComplete.every(x => x)
          updateItem = {
            ...l,
            complete: completeAll,
            [bonus]: {
              ...findBonus,
              complete: !findBonus.complete
            }
          }
          updateList.push(updateItem)
        } else if (type === 'hide') {
          updateList.filter(datacron => datacron.id === id)
        }
      } else {
        updateList.push(l)
      }
    })

    localStorage.setItem(`dcFarmingList${getActiveSet}${loadedAccountString}`, JSON.stringify(updateList))

    setList(updateList)
  }, [list, loadedAccountString, setList, getActiveSet])

  const renderItem = useCallback((item, index) => {

    const onComplete = (id, bonus) => {
      onUpdateList(id, 'complete', bonus)
    }

    const onHide = (datacron) => {
      toggleDialog(true)
      setDeleteDatacron(datacron)
    }

    return (
      <DatacronItem
        key={`${item.id}${item.unit}${item.order}`}
        id={item.id}
        index={index}
        unit={item.unit}
        ggImage={item.ggImage}
        l3={item.l3}
        l6={item.l6}
        l9={item.l9}
        hide={item.hide}
        complete={item.complete}
        stats={item.stats}
        data={item}
        moveItem={moveItem}
        onComplete={onComplete}
        onHide={onHide}
        activeTab={activeTab}
      />
    )
  }, [moveItem, activeTab, onUpdateList])

  const onAccountLoad = (name) => {
    setLoadedAccount(name)
    localStorage.setItem('dcFarmingListLoadedAccount', name)
    toggleAccounts(false)
    const loadedListString = name === 'Default' ? '' : name
    setLoadedAccountString(loadedListString)

    const getList = localStorage.getItem(`dcFarmingList${getActiveSet}${loadedListString}`)
    if (getList && getList !== 'undefined') {
      setList(JSON.parse(getList))
    } else {
      setList(getActiveData)
    }
  }

  useEffect(() => {
    const getLoadedList = localStorage.getItem('dcFarmingListLoadedAccount')
    let loadedListString = ''
    if (getLoadedList && getLoadedList !== 'undefined') {
      setLoadedAccount(getLoadedList)
      loadedListString = getLoadedList === 'Default' ? '' : getLoadedList
      setLoadedAccountString(loadedListString)
    }

    const getList = localStorage.getItem(`dcFarmingList${getActiveSet}${loadedListString}`)
    if (getList && getList !== 'undefined') {
      setList(JSON.parse(getList))
    } else {
      setList(getActiveData)
      localStorage.setItem(`dcFarmingList${getActiveSet}`, JSON.stringify(getActiveData))
    }
  }, [activeTab, dataOne, dataZero, setList, setLoadedAccount, setLoadedAccountString, getActiveSet, getActiveData])

  return (
    <>
      <div className='sw-dc-accounts'>
        <div className='sw-dc-accounts__name lead text-uppercase font-weight-bold'>{loadedAccount && loadedAccount} Account</div>
        <div className='sw-dc-accounts__actions'>
          {activeTab === 0 && <ShareDatacronList list={list} getActiveSet={getActiveSet} />}
          <button className='btn btn-sm btn-info px-3 mr-2' onClick={() => toggleAccounts(true)}>Manage Accounts</button>
        </div>
      </div>
      <div className='sw-dc-list'>
        {list.length > 0 && list.map((l, i) => renderItem(l, i))}
      </div>

      {showAccounts && <DatacronAccounts onAccountLoad={onAccountLoad} loadedAccount={loadedAccount} onClose={() => toggleAccounts(false)} />}

      {showDialog &&
        <Dialog
          title='Delete Datacron'
          cancel={{
            label: 'Cancel',
            theme: 'outline-dark',
            click: toggleDialog
          }}
          confirm={{
            label: 'Delete',
            theme: 'danger',
            click: () => {
              onUpdateList(deleteDatacron.id, 'hide')
              toggleDialog(false)
            }
          }}
        >
          <div className='bg-dark'>
            <DatacronItem
              id={deleteDatacron.id}
              unit={deleteDatacron.unit}
              ggImage={deleteDatacron.ggImage}
              l3={deleteDatacron.l3}
              l6={deleteDatacron.l6}
              l9={deleteDatacron.l9}
              complete={deleteDatacron.complete}
              stats={deleteDatacron.stats}
              activeTab={activeTab}
              compactView
            />
          </div>
        </Dialog>
      }
    </>
  );
}

export default DatacronList;