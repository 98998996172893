import React, { useState } from 'react';
import classNames from 'classnames';

import Squad from '../../components/squad';

const TbAlternative = (props) => {
  const { loadouts } = props
  const [showAlts, toggleAlts] = useState(false);

  return (
    <div className={classNames(
      'sw-tb-squad-alternatives',
      showAlts && 'sw-tb-squad-alternatives--active'
    )}
    >
      <div
        className={classNames(
          'sw-tb-squad-alternatives__title',
          showAlts && 'sw-tb-squad-alternatives__title--active'
        )}
        onClick={() => toggleAlts(!showAlts)}
      >
        Alternative Teams
      </div>
      {loadouts.map((a, i) =>
        <div
          className={classNames(
            'sw-tb-squad-alternatives__panel',
            showAlts && 'sw-tb-squad-alternatives__panel--active'
          )}
          key={i}
        >
          <div className='sw-tb-squad__toons'>
            <Squad toons={a.toons} videoThumb={a.videoThumb} />
          </div>
        </div>
      )}
    </div>
  );
}

export default TbAlternative;