import React from 'react';
import Toon from '../../../components/toon'
import OmicronsCell from './cell';

const OmicronsRow = (props) => {
  const { image, unit, ability, count, adoption, offense, defense, gls, solo, position, sort, order, icon } = props

  return (
    <div className='sw-omi-row'>
      <div className='sw-omi-row__toon'>
        <div className='sw-omi-row__position'>
          #{position}
        </div>
        <div className='sw-omi-row__image'>
          <Toon image={image} icon={icon} />
        </div>
        <div className='sw-omi-row__meta'>
          <div className='font-weight-bold'>{unit}</div>
          <div className='small'>{ability}</div>
          <div className='small'>{gls && <span className='badge badge-secondary'>vs GLs</span>} {solo && <span className='badge badge-primary'>Solo</span>}</div>
        </div>
      </div>
      <div className='sw-omi-row__data'>
        <OmicronsCell header='Count' isSort={sort === 'count'} order={order} value={Number(count).toLocaleString()} />
        <OmicronsCell header='Popularity' isSort={sort === 'ratio'} order={order} value={`${Number(adoption).toFixed(0)}`} />
        <OmicronsCell header='Offense' isSort={sort === 'off_diff'} order={order} value={`${offense}%`} />
        <OmicronsCell header='Defense' isSort={sort === 'def_diff'} order={order} value={`${defense}%`} />
      </div>
    </div>
  );
}

export default OmicronsRow;