import React from 'react';
import ToonDrop from './toonDrop';
import './_style.scss';

const ToonDefense = props => {

  return (
    <div className="sw-zone__match">
      <ToonDrop
        zone={props.zone} onRemove={props.onRemove}
        data={props.data} onBannersAdd={props.onBannersAdd}
        format={props.format}
      />
    </div>
  );
}

export default ToonDefense;