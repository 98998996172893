import React from 'react';
import classNames from 'classnames';

import Toon from '../toon';
import './_style.scss';

const Counter = props => {
  return (
    <div className={classNames('sw-counter', props.noDivider && 'sw-counter--no-divider')}>
      <div className="sw-counter__toons">
        {props.banners && <div className="sw-counter__banners">
          <span className="badge badge-secondary rounded-0">
            <span className="sw-counter__banners__digit">{props.banners}</span>
          </span>
        </div>
        }
        <div className="sw-counter__teams">
          <div className="sw-counter__offense">
            {props.toons && props.toons.map((toon, i) =>
              <div key={i} className={classNames(`sw-counter__toon sw-counter__toon--${i + 1}`, props.performance && `sw-counter__toon--performance sw-counter__toon--performance--${i + 1}`)}>
                <Toon image={toon} />
              </div>
            )}
          </div>
          <div className="sw-counter__vs">vs</div>
          <div className="sw-counter__defense">
            {props.defense && props.defense.map((def, i) =>
              <div key={i} className={classNames(`sw-counter__toon sw-counter__toon--${i + 1}`, props.performance && `sw-counter__toon--performance sw-counter__toon--performance--${i + 1}`)}>
                <Toon image={def} />
              </div>
            )}
          </div>
        </div>
      </div>
      {(props.id || props.url) && <div className="sw-counter__play">
        <a
          href={props.id ? `https://youtu.be/${props.id}` : props.url} target="_blank"
          className="btn sw-counter__action" rel="noopener noreferrer"
        >
          <div className={classNames('sw-counter__creator', `sw-counter__creator--${props.creator ? props.creator : 'bd'}`)} />
          <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="sw-counter__youtube">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
          </svg>
        </a>
      </div>
      }
      {(props.home || props.home === 0) &&
        <div className="sw-counter__performance">
          <div className="sw-counter__score">Score</div>
          <div className="sw-counter__home">{props.home}</div>
          <div className="sw-counter__spacer">-</div>
          <div className="sw-counter__away">{props.away}</div>
        </div>
      }
    </div>
  );
}

export default Counter;