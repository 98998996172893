import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const TbMenu = () => {

  return (
    <div className={classNames('nav nav-pills nav-fill')}>
      <li className="nav-item">
        <NavLink className="nav-link" to="/territory-battles/" exact={true}>
          <span>ROTE</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/territory-battles/geo-light-side/" exact={true}>
          <span>Geo LS</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/territory-battles/geo-dark-side/" exact={true}>
          <span>Geo DS</span>
        </NavLink>
      </li>
    </div>

  );
}

export default TbMenu;