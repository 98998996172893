import React, { useEffect } from 'react';

import Page from '../../components/page';
import ConquestTeams from '../../components/conquest';
import ConquestMenu from './menu';
import { disks } from './data/disks';

const intro = <div>Recommended disks to help you on the quest for the red box.</div>;

const ConquestDisks = () => {

  useEffect(() => {
    document.title = 'Conquest Data Disks - SWGOH-4 Life';
  },);

  return (
    <Page title="Data Disks" intro={intro} menu={<ConquestMenu />}>
      {disks && disks.map((feat, i) =>
        feat.recommended && <ConquestTeams
          squads={feat.teams} key={i} title={feat.title} text={feat.text} isDisk
        />
      )}
    </Page>
  );
}

export default ConquestDisks;
