import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const ConquestMenu = (props) => {

  return (
    <div className={classNames('nav nav-pills nav-fill sw-sub-menu')}>
      <li className="nav-item sw-sub-menu__item">
        <NavLink className="nav-link sw-sub-menu__link" to="/conquest/" exact={true}>
          <span>Guides</span>
        </NavLink>
      </li>
      <li className="nav-item sw-sub-menu__item">
        <NavLink className="nav-link sw-sub-menu__link" to="/conquest/plan/" exact={true}>
          <span>Plan</span>
        </NavLink>
      </li>
      <li className="nav-item sw-sub-menu__item">
        <NavLink className="nav-link sw-sub-menu__link" to="/conquest/feats/" exact={true}>
          <span>Feats</span>
        </NavLink>
      </li>
      <li className="nav-item sw-sub-menu__item">
        <NavLink className="nav-link sw-sub-menu__link" to="/conquest/disks/" exact={true}>
          <span>Disks</span>
        </NavLink>
      </li>
      <li className="nav-item sw-sub-menu__item">
        <NavLink className="nav-link sw-sub-menu__link" to="/conquest/enemies/">
          <span>Enemies</span>
        </NavLink>
      </li>
    </div>

  );
}

export default ConquestMenu;