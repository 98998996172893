import React, { useState } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import { ItemTypes } from './types';
import { list as list5v5 } from '../../pages/5v5/data/counters';
import { videos as videos5v5 } from '../../pages/5v5/data/videos';
import { list as list3v3 } from '../../pages/3v3/data/counters';
import { videos as videos3v3 } from '../../pages/3v3/data/videos';
import { list as listFleet } from '../../pages/ships/data/counters';
import { counters as videosFleet } from '../../pages/ships/data/videos';
import Toon from '../toon';
import AddBanners from './addBanners';
import ToonRemove from './toonRemove';
import Counter from '../counter';
import GgCounterBanner from '../counter/ggBanner';

const ToonDrop = props => {
  const [showBanners, setShow] = useState(false);
  const [showVideos, setVideos] = useState(false);
  const [leaderVideos, selectVideos] = useState([]);
  const [leaderName, setLeaderName] = useState('');
  const [counterType, setCounterType] = useState('');
  const [counterSeason, setCounterSeason] = useState('');
  const [counterShips, setCounterShips] = useState(false);
  const { data } = props;
  
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.TOON,
    drop: () => data && ({ name: data.name, base_id: data.base_id, ggImage: data.ggImage, zone: props.zone }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = data && canDrop && isOver;

  const loadVideos = () => {
    const getList = props.zone ==='fleet' ? listFleet : props.format === '3v3' ? list3v3 : list5v5;
    const getVideos = props.zone ==='fleet' ? videosFleet : props.format === '3v3' ? videos3v3 : videos5v5;
    const getName = getList.find(l => l.base_id === props.data.base_id);
    const filterVideos = getName && getVideos.filter(c => c.counter === getName.value);
    setLeaderName(getName)
    setCounterType(props.format === '3v3' ? '3' : props.format === '5v5' && '5')
    setCounterSeason((props.format === '3v3' || props.zone === 'fleet') ? 'CHAMPIONSHIPS_GRAND_ARENA_GA2_EVENT_SEASON_59' : 'CHAMPIONSHIPS_GRAND_ARENA_GA2_EVENT_SEASON_58')
    setCounterShips(props.zone === 'fleet')
    selectVideos(getName ? filterVideos.sort((a, b) => parseFloat(b.banners) - parseFloat(a.banners)) : []);
    setVideos(true);
  }

  return (
    <div ref={drop} className={classNames('sw-toon-drop', isActive && 'sw-toon-drop--active')}>
      <div className="sw-toon-drop__offense">
        {props.data && !props.data.banners && props.data.off &&
          <ToonRemove onRemove={() => props.onRemove(props.data.off.base_id, props.data.base_id, props.zone)} />
        }
        <Toon image={props.data && props.data.off && props.data.off.base_id} ggImage={props.data && props.data.off && props.data.off.ggImage} />
        <div className="sw-toon-drop__banners">
          {props.data && props.data.banners ?
            <><button className="btn btn-primary btn-sm rounded-circle sw-toon-drop__button sw-toon-drop__button--inline font-weight-bold" onClick={() => setShow(true)}>{props.data.banners}</button></>
            : props.data && props.data.off &&
            <button className="btn btn-success btn-sm rounded-circle sw-toon-drop__button sw-toon-drop__button--inline" onClick={() => setShow(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
              </svg>
            </button>
          }
          {showBanners &&
            <AddBanners onAdd={(banners) => props.onBannersAdd(banners, props.zone, data.base_id)} onCancel={() => setShow(false)} banners={props.data.banners} />
          }
        </div>
      </div>
      <div className="sw-toon-drop__stats">
        <div className="sw-toon-drop__versus">VS</div>
      </div>
      <div className="sw-toon-drop__defense">
        {props.data && !props.data.banners &&
          <ToonRemove onRemove={() => props.onRemove(props.data.off && props.data.off.base_id, props.data.base_id, props.zone, true)} />
        }
        <Toon image={props.data && props.data.base_id} ggImage={props.data && props.data.ggImage} />
        {props.data && !props.data.banners &&
          <div className="sw-toon-drop__videos">
            <button className="btn btn-youtube btn-sm rounded-circle sw-toon-drop__button sw-toon-drop__button--inline" onClick={loadVideos}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
              </svg>
            </button>
            {showVideos &&
              <div className="sw-toon-drop__backdrop" onClick={() => setVideos(false)}>
                <div className="sw-toon-drop__counters" onClick={(e) => e.stopPropagation()}>
                  <div className="sw-zone__add-header mx-0">
                    <h2 className="mb-0 h3 sw-heading text-light">{props.format} {props.data.name} Counters</h2>
                    <button className="btn btn-sm btn-link text-light" onClick={() => setVideos(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                  {leaderName &&
                    <GgCounterBanner type={counterType} leader={leaderName} season={counterSeason} isShips={counterShips} />
                  }
                  {leaderVideos.length > 0 ? leaderVideos.map((video, i) =>
                    <Counter
                      name={video.name} toons={video.squad} id={video.id} key={i} creator={video.creator}
                      url={video.url} banners={video.banners} defense={video.defense}
                    />)
                    :
                    <div className="px-3">
                      <p className="lead font-weight-bold">Sorry, this counter is not yet available on SWGOH 4-Life</p>
                      <p>Please join my <a href="https://discord.gg/PDg8V7gNVW" rel="noopener noreferrer" target="_blanke">Discord</a> to request it or ask for advice.</p>
                      <p>Alternatively, you can search for it on other great SWGOH tools such as <a href="https://www.grandarenascience.com/counters/" rel="noopener noreferrer" target="_blanke">Grand Arena Science</a> or <a href="https://swgoh.gg/gac/counters/" rel="noopener noreferrer" target="_blanke">SWGOH.GG Meta Counters</a>.</p>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default ToonDrop;