import React from 'react';
import classNames from 'classnames';

import Squad from '../squad';
import ScoreBar from '../scorebar';
import './_style.scss';
import DataDisk from './dataDisk';


const ConquestTeams = props => {

  const getProgress = (id) => props.planProgress && props.planProgress.find(p => p.id === id);

  return (
    <div className={classNames('sw-conquest-teams', props.progress && 'sw-conquest-teams--progress', props.feats && 'sw-conquest-teams--feats', props.teamsOnly && 'sw-conquest-teams--only')}>
      {(props.boss || (props.squads && props.squads.length > 0)) &&
        <div className={classNames('sw-conquest-teams__squads', props.feats && 'sw-conquest-teams__squads--feats')}>
          {props.title && <div className={classNames('sw-conquest-teams__recommended', props.boss && 'sw-conquest-teams__recommended--boss', props.feats && 'sw-conquest-teams__recommended--feats', props.active && 'sw-conquest-teams__recommended--active')} onClick={props.setActive}>
            {props.boss && <img alt="" className="sw-conquest-teams__boss" src={`/images/conquest/boss-${props.boss}.png`} />}
            <div className="sw-conquest-teams__title">{props.title}</div>
          </div>
          }
          <div className={classNames('sw-conquest-teams__panel', props.feats && 'sw-conquest-teams__panel--feats', props.active && 'sw-conquest-teams__panel--active')}>
            {(props.keycards || props.dataDisk) &&
              <div className={classNames('sw-conquest-teams__rewards')}>
                {
                  props.keycards &&
                  <div className={classNames('sw-conquest-teams__keycards')}>
                    <img src="/images/conquest/keycard.png" alt="" className="mr-1" />
                    {props.keycards}
                  </div>
                }
                {props.dataDisk &&
                  <div className={classNames('sw-conquest-teams__keycards')}>
                    <img src="/images/conquest/data-disk.png" alt="" className="mr-1" />
                    {props.dataDisk}
                  </div>
                }
              </div>
            }
            {props.text &&
              <div className={classNames('sw-conquest-teams__text', props.feats && 'sw-conquest-teams__text--feats')}>{props.text}</div>
            }
            {props.squads && props.squads.map((s, i) =>
              <div className={classNames('sw-conquest-teams__battle', props.feats && 'sw-conquest-teams__battle--feats', props.planProgress && (getProgress(s.id).completed === getProgress(s.id).qty) && 'sw-conquest-teams__battle--complete')} key={i}>
                {s.image && <img alt="" className="sw-conquest-teams__image" src={`/images/conquest/${s.image}.png`} />}
                {props.isDisk && <DataDisk name={s.name} desc={s.desc} slots={s.slots} icon={s.icon} rarity={s.rarity} />}
                {s.toons && <Squad dataDisk={props.dataDisk} toons={s.toons} name={s.name} inlineName video={s.video} videoThumb={s.videoThumb} id={s.id} progress={props.planProgress && getProgress(s.id)} />}
                <div className="sw-conquest-teams__meta">
                  {s.feats &&
                    <div className="sw-conquest-teams__feats">
                      <div className="text-uppercase">Sector Feats / Battle</div>
                      {s.feats.map((f, i) =>
                        <span className="badge rounded-pill badge-info mx-1" key={i}>{f.name} ({f.qty}x)</span>
                      )}
                    </div>
                  }
                  {s.global &&
                    <div className="sw-conquest-teams__global">
                      <div className="text-uppercase">Global Feats</div>
                      {s.global.map((g, i) =>
                        <span className="badge rounded-pill badge-info mx-1" key={i}>{g.name}</span>
                      )}
                    </div>
                  }
                  {s.disk &&
                    <div className="sw-conquest-disk">
                      <div className="sw-conquest-disk__name">{s.disk.name}</div>
                      <div className="sw-conquest-disk__text">{s.disk.text}</div>
                    </div>
                  }
                </div>
                {props.planProgress &&
                  <div className="sw-squad__progress">
                    <button
                      className="btn btn-primary"
                      onClick={() => getProgress(s.id).completed === getProgress(s.id).qty ? props.onReset(getProgress(s.id).id) : props.onComplete(getProgress(s.id).id)}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <span>Complete</span>
                        {[...Array(props.planProgress && getProgress(s.id).qty)].map((t, i) =>
                          <span
                            key={`${getProgress(s.id).id}${i}`}
                            className={classNames('sw-squad__check', getProgress(s.id).completed > i && 'sw-squad__check--completed')}
                          >{getProgress(s.id).completed > i ?
                            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></svg>
                            : i + 1}</span>
                        )}
                      </div>
                    </button>
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      }
      {props.progress &&
        <div className={classNames('sw-conquest-teams__recommended', 'sw-conquest-teams__recommended--progress sw-conquest-teams__recommended--boss')}>
          <img alt="" className="sw-conquest-teams__boss my-md-3" src={`/images/conquest/red-box.png`} />
          {props.title}
        </div>
      }
      {props.progress &&
        <div className="px-3 px-lg-8">
          {props.progress.map((p, i) => p.complete ?
            <div className="badge bg-success rounded-pill pl-3 mr-2 mb-3" key={i}>
              {p.name}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16" className="ml-2">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            :
            p.skip ?
              <div className="badge bg-danger rounded-pill pl-3 mr-2 mb-3" key={i}>
                {p.name}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" className="ml-2"><g fill="currentColor" transform="scale(0.03125 0.03125)"><path d="M874.040 149.96c-96.706-96.702-225.28-149.96-362.040-149.96s-265.334 53.258-362.040 149.96c-96.702 96.706-149.96 225.28-149.96 362.040s53.258 265.334 149.96 362.040c96.706 96.702 225.28 149.96 362.040 149.96s265.334-53.258 362.040-149.96c96.702-96.706 149.96-225.28 149.96-362.040s-53.258-265.334-149.96-362.040zM896 512c0 82.814-26.354 159.588-71.112 222.38l-535.266-535.268c62.792-44.758 139.564-71.112 222.378-71.112 211.738 0 384 172.262 384 384zM128 512c0-82.814 26.354-159.586 71.112-222.378l535.27 535.268c-62.794 44.756-139.568 71.11-222.382 71.11-211.738 0-384-172.262-384-384z"/></g></svg>
              </div>
            :
            <ScoreBar
              value={p.current} max={p.target} target={p.target}
              title={p.name} progress key={i}
            />
          )}
        </div>
      }
    </div>
  );
}

export default ConquestTeams;