import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { ViewportContext } from '../../Viewport';
import './_style.scss';

const SubMenu = (props) => {
  const viewPort = useContext(ViewportContext);
  const { navLink, links, onClick, hasImage, active, theme } = props;

  return (
    <div className={classNames("d-flex mb-5", 'btn-group')}>
      {links.length > 0 && links.map((b, i) => navLink ?
        <NavLink 
          className={classNames(`btn btn-outline-${theme}`, viewPort.width < 768 && 'btn-sm', hasImage && 'btn-image', active === i ? 'active' : 'idle' )}
          to={b.to} exact={true} key={i}
        >
          {hasImage && <img className="btn-image__icon" src={b.image} alt="" />}
          <span className={classNames(hasImage && 'btn-image__label')}>{b.label}</span>
        </NavLink>
        :
        <button 
          className={classNames(`btn btn-outline-${theme}`, viewPort.width < 768 && 'btn-sm', hasImage && 'btn-image', active === i ? 'active' : 'idle' )} 
          onClick={() => onClick(i)} key={i}
        >
          {hasImage && <img className="btn-image__icon" src={b.image} alt="" />}
          <span className={classNames(hasImage && 'btn-image__label')}>{b.label}</span>
        </button>
      )}
    </div>

  );
}

SubMenu.defaultProps = {
  breakpoint: 576,
  navLink: false,
  hasImage: false,
  theme: 'secondary',
  links: []
}

export default SubMenu;