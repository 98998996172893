const rey = [
    { name: 'Rey - Tier 1', desc: `RJT, BB-8, R2-D2, C-3PO and Hero Finn`, id: 'GmxR3FOm7P4' },
    { name: 'Rey - Tier 2', desc: `RJT, BB-8, R2-D2, C-3PO and Hero Finn`, id: 'WjiKiOM4pi4' },
    { name: 'Rey - Tier 3', desc: `Feint, Strike, Parry, Strike`, id: 'rC6BPKu03sY' },
    { name: 'Rey - Tier 4 & 5', desc: `2 ways to beat T4 & T5`, id: 'eEEkMdVHS2Y' },
    { name: 'Rey - Tier 6', desc: `Conquer tier 6 after Kylo buffs`, id: 'fKZGcQ3-Zhk' }
];

const kylo = [
    { name: 'Kylo - Tier 1', desc: `KRU, Hux, Kylo, FOX and FOST`, id: '6rGyeoOVb3g' },
    { name: 'Kylo - Tier 2', desc: `KRU, Hux, Kylo, Sith Trooper and FOST`, id: 'hxJnYONORI0' },
    { name: 'Kylo - Tier 3', desc: `Feint, Strike, Parry, Strike`, id: '-XT3SWWr-Dk' },
    { name: 'Kylo - Tier 4 & 5', desc: `KRU, Phasma, Kylo, Hux and FOO`, id: 'MFdNjHpjHdc' },
    { name: 'Kylo - Tier 6', desc: `SLKR, Phasma, Hux, FOO and SFTP`, id: 'cOTmnMRx-S0' }
];

const jml = [    
    { name: 'JML - Tier 3', desc: `Updated guide after the Great Nerf of 2021`, id: 'db_wRRBRnrA' },
    { name: 'JML - Tier 1', desc: `JKL and R2 handle their business`, id: 'X5P1WqOljNA' },
    { name: 'JML - Tier 2', desc: `Triple tap your way through with Leia`, id: 'nYt2BMugyKI' },
    { name: 'JML - Tier 4 - 5 - 6', desc: `Uncle Luke teaches his nephew the ways of the force`, id: '7Hghl_aEqs4' }
];

const see = [
    { name: 'SEE - Tier 1', desc: `Zeta or no zeta, what path will you choose?`, id: 'sbpzCdv3Ndg' },
    { name: 'SEE - Tier 2', desc: `Anakin faces Mace Windu 1v1`, id: '2f_zNuLNp9E' },
    { name: 'SEE - Tier 3', desc: `Palp and Vader confront Jedi Luke`, id: 'izHRXtNSoFY' },
    { name: 'SEE - Tier 4 - 5 - 6', desc: `Eternal Emperor shows his unlimited power!`, id: 'imS85_c9BJA' }
];

const jmk = [
    { name: 'Squad Arena Battles', desc: `Defeat other GLs in Squad Arena`, id: '_tGU2kkB0Dg' }
];

const solo = [
    { name: 'Bombad General - Legendary Event', desc: `Jar Jar Relic 5 Unlock Guide`, id: 'm1-AkCl05pA' },
    { name: 'Battle for Mandalore - Epic Confrontation', desc: `Bo-Katan Mand'alor Unlock Guide`, id: 'RekuIbY_xNc' },
    { name: 'Starforge Showdown - Light Path', desc: `Darth Malak Light Path Guide`, id: '9NCiJapNqCo' },
    { name: 'Starforge Showdown - Dark Path', desc: `Darth Malak Dark Path Guide`, id: 'iR1GDrCyPxA' },
    { name: 'Dark Sovereign', desc: `Leviathan Tier 4 Unlock + Bonus Tier Guide`, id: '5r6-2xPYZNE' },
    { name: 'Dark Times', desc: `Unlock guide for Grand Inquisitor`, id: 'nW4Wx9xH5mI' },
    {
        name: 'Profundity Unlock Guide',  id: 'ezIfU6ugSiA',
        desc: `Guide for completing Tier 1 to 4`
    },
    {
        name: 'Profundity Bonus Tier Guide',  id: 'pgEfr9k0W2I',
        desc: `Guide for completing Bonus Tier`
    },
    { 
        name: 'Executor Journey Guide Fleet Mastery', id: '7Th7rtPEmeY', 
        desc: `Beat the bonus tier to earn blueprints`
    },
    { 
        name: 'Jedi Knight Luke Skywalker', id: 'tcAArZbRwKM', 
        desc: `Unlock Event Guide`
    }
];

export {
    rey,
    kylo,
    jml,
    see,
    jmk,
    solo
}