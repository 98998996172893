import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory  } from 'react-router-dom'

import Page from '../../components/page/index.js'
import DatacronItem from './item.js'
import Dialog from '../../components/dialog/index.js'

const ImportDatacrons = () => {
  const [list, setList] = useState([])
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [date, setDate] = useState('')
  const [importName, setImportName] = useState('')
  const [showDialog, toggleDialog] = useState(false)
  let history = useHistory()

  useEffect(() => {
    document.title = 'Import Datacron Farming List - SWGOH-4 Life'

    const getData = () => {
      const getUrl = new URL(window.location.href);
      const getId = getUrl.searchParams.get('id')

      axios.get(`https://swgoh4.life/api/get-datacrons.php?id=${getId}`)
        .then(response => {
          const getData = response.data[0]
          const parseData = JSON.parse(getData.share_data)
          setList(parseData)
          setName(getData.share_name)
          setNumber(getData.set_id)
          setDate(getData.date_created)
        })
        .catch(error => {
          console.log(error)
        })
    }

    getData()
  }, [])

  const onImport = () => {
    let updateList = []
    const getList = localStorage.getItem('dcFarmingListAccounts')
    if (getList && getList !== 'undefined') {
      updateList = [...JSON.parse(getList), importName]
    } else {
      updateList = [importName]
    }
    localStorage.setItem('dcFarmingListAccounts', JSON.stringify(updateList))
    localStorage.setItem('dcFarmingListLoadedAccount', importName)
    localStorage.setItem(`dcFarmingList${number}${importName}`, JSON.stringify(list))
    onClose()
    history.push('/datacrons/')
  }

  const onClose = () => {
    toggleDialog(false)
    setImportName('')
  }

  const intro = <div className="row">
    <div className="col-12">
      <strong>List Name:</strong> {name} (Set {number})
      <div className='mb-3'>Created on {date}</div>
      <button className='btn btn-secondary mr-3' onClick={() => toggleDialog(true)}>Import List</button>
    </div>
  </div>

  return (
    <Page title='Import Datacron Farming List' intro={intro}>
      {list.length > 0 && list.map((item, index) =>
        <div className='bg-dark' key={`${item.id}${item.unit}${item.order}`}>
          <DatacronItem
            id={item.id}
            index={index}
            unit={item.unit}
            ggImage={item.ggImage}
            l3={item.l3}
            l6={item.l6}
            l9={item.l9}
            hide={item.hide}
            complete={item.complete}
            stats={item.stats}
            data={item}
            readOnly
            activeTab={0}
          />
        </div>
      )}

      {showDialog &&
        <Dialog
          title={`Import ${name} (Set ${number})`}
          cancel={{
            label: 'Cancel',
            theme: 'outline-dark',
            click: onClose
          }}
          confirm={{
            label: 'Import',
            theme: 'primary',
            disabled: !importName,
            click: () => {
              onImport()
            }
          }}
        >
          <div className='text-left'>
            <label htmlFor='shareName'>Account Name</label>
            <input
              type='text'
              className='form-control border-dark'
              name='importName'
              id='importName'
              value={importName}
              onChange={(e) => setImportName(e.target.value)}
            />
          </div>
        </Dialog>
      }
    </Page>
  );
}

export default ImportDatacrons;
