import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const CountersMenu = () => {

  return (
    <div className={classNames('nav nav-pills nav-fill')}>
      <li className="nav-item">
        <NavLink className="nav-link" to="/counters/5v5/">
          <span>5v5</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/counters/3v3/">
          <span>3v3</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/counters/ships/"> 
          <span>Ships</span>
        </NavLink>
      </li>
    </div>

  );
}

export default CountersMenu;