import React from 'react';
import classNames from 'classnames';

const PlannerIntro = props => props.populateZones ?
  <div>
    <div className='d-flex align-items-center mb-4 mt-5'>
      <div>
        <img src="/images/external/omegabot.png" alt="Omegabot" width="48" className="rounded" />
      </div>
      <div className='flex-grow ml-3'>You will import Grand Arena plan from Omegabot. This will replace your existing defense plan.</div>
    </div>
    <p className='font-weight-bold'>Do you want to continue?</p>
    <button className='btn btn-secondary mr-3' onClick={props.onImport}>Yes</button>
    <button className='btn btn-outline-light' onClick={() => props.history.push('/gac/')}>Cancel</button>
  </div>
  :
  <div className="row align-items-end">
    <div className="col-12 mb-3 col-md mb-md-0 col-lg-auto">
      <div className="form-group-label">Format</div>
      <div className="btn-group" role="group" aria-label="Select format">
        <button
          type="button" onClick={props.change} data-id="format" data-val="5v5"
          className={classNames('btn btn-outline-light', props.values.format === '5v5' && 'active')}
        >5v5</button>
        <button
          type="button" onClick={props.change} data-id="format" data-val="3v3"
          className={classNames('btn btn-outline-light', props.values.format === '3v3' && 'active')}
        >3v3</button>
      </div>
    </div>

    <div className="col-6 col-sm-6 col-lg-3">
      <div className="form-group mb-0">
        <label htmlFor="divison" className="form-group-label">League</label>
        <select
          className="custom-select" name="division" id="division" data-id="division"
          onChange={props.change} value={props.values.division}
        >
          <option value="1">Kyber</option>
          <option value="2">Aurodium</option>
          <option value="3">Chromium</option>
          <option value="4">Bronzium</option>
          <option value="5">Carbonite</option>
        </select>
      </div>
    </div>

    <div className="col-6 col-sm-6 col-lg-3">
      <div className="form-group mb-0">
        <label htmlFor="divison" className="form-group-label">Target Score</label>
        <input
          className="form-control" name="target" id="target" data-id="target"
          onChange={props.change} value={props.values.target}
          maxLength="4"
        />
      </div>
    </div>
  </div>;

export default PlannerIntro;
