import React from 'react'
import './_style.scss'

const Dialog = (props) => {
  const { title, children, cancel, confirm } = props

  return (
    <div className='sw-dialog sw-zone__backdrop text-dark' onClick={() => cancel.click(false)}>
      <div className='sw-dialog__wrapper' onClick={(e) => e.stopPropagation()}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{title}</h5>
            </div>
            <div className='modal-body'>{children}</div>
            <div className='modal-footer justify-content-between'>
              <button type='button' className={`flex-grow-1 btn btn-${cancel.theme}`} data-dismiss='modal' onClick={() => cancel.click(false)}>{cancel.label}</button>
              <button type='button' disabled={confirm.disabled} className={`flex-grow-1 btn btn-${confirm.theme}`} onClick={confirm.click}>{confirm.label}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialog