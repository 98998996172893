import React, { useEffect } from 'react';

import Page from '../../components/page';

const NotFound = () => {

  useEffect(() => {
    document.title = 'Page not found - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      <p>This page has been moved. Please use the menu to navigate to the page you are looking for.</p>
    </div>
  </div>;

  return (
    <Page title="This is not the page you are looking for!" intro={intro} showSocial />
  );
}

export default NotFound;
