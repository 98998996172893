import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import ViewportProvider from './Viewport';
import ScrollToTop from './components/page/scrollTop';
import GacPlanner from './pages/gac/planner';
import FiveFive from './pages/5v5';
import Home from './pages/home';
import GeoTb from './pages/geo-tb';
import GeoTbDs from './pages/geo-tb/ds';
import Raids from './pages/raids';
import Ships from './pages/ships';
import Assault from './pages/assault';
import GalacticLegends from './pages/gls';
import ThreeThree from './pages/3v3';
import Conquest from './pages/conquest';
import ConquestPlan from './pages/conquest/plan';
import ConquestFeats from './pages/conquest/feats';
import ConquestDisks from './pages/conquest/disks';
import ConquestEnemies from './pages/conquest/enemies';
import RoteTb from './pages/rote-tb';
import NotFound from './pages/not-found';
import CountersRedirect from './pages/5v5/redirect';
import ProvingGrounds from './pages/proving-grounds';
import Datacrons from './pages/datacrons';
import Omicrons from './pages/omicrons';
import ImportDatacrons from './pages/datacrons/import';
import './App.scss';


const App = () => {
  return (
    <DndProvider options={HTML5toTouch}>
      <Router>
        <ScrollToTop />
        <ViewportProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/gac/" component={GacPlanner} />
            <Route exact path="/gac/populate" component={GacPlanner} />
            <Route exact path="/counters/" component={CountersRedirect} />
            <Route exact path="/counters/5v5/" component={FiveFive} />
            <Route exact path="/counters/5v5/:id/" component={FiveFive} />
            <Route exact path="/counters/3v3/" component={ThreeThree} />
            <Route exact path="/counters/3v3/:id/" component={ThreeThree} />
            <Route exact path="/counters/ships/" component={Ships} />
            <Route exact path="/counters/ships/:id/" component={Ships} />
            <Route exact path="/territory-battles/geo-light-side/" component={GeoTb} />
            <Route exact path="/territory-battles/geo-dark-side/" component={GeoTbDs} />
            <Route exact path="/territory-battles/" component={RoteTb} />
            <Route exact path="/raids/" component={Raids} />
            <Route exact path="/assault-battles/" component={Assault} />
            <Route exact path="/conquest/" component={Conquest} />
            <Route exact path="/conquest/plan/" component={ConquestPlan} />
            <Route exact path="/conquest/disks/" component={ConquestDisks} />
            <Route exact path="/conquest/feats/" component={ConquestFeats} />
            <Route exact path="/conquest/enemies/" component={ConquestEnemies} />
            <Route exact path="/conquest/enemies/:id/" component={ConquestEnemies} />
            <Route exact path="/journey-guides/" component={GalacticLegends} />
            <Route exact path="/proving-grounds/" component={ProvingGrounds} />
            <Route exact path="/datacrons/" component={Datacrons} />
            <Route exact path="/datacrons/list/" component={ImportDatacrons} />
            <Route exact path="/omicrons/" component={Omicrons} />
            <Route path='*' component={NotFound} />
          </Switch>
        </ViewportProvider>
      </Router>
    </DndProvider>
  );
}

export default App;