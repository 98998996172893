import React, { useState } from 'react';

const getName = (value) => {
  let name = `/images/datacrons/stat-${value.toLowerCase().replaceAll(' ', '-')}`
  return name
}

const DatacronStat = (props) => {
  const [showTip, toggleShowTip] = useState(false)
  const { value } = props
  
  const toggleTip = (e, toggle) => {
    e.preventDefault()
    toggleShowTip(toggle)
  }

  return (
    <div className='sw-dc-stat'>
      <div className='sw-dc-stat__link' onMouseEnter={(e) => toggleTip(e, true)} onMouseLeave={(e) => toggleTip(e, false)}>
        <img className='sw-dc-stat__image' alt='' src={`${getName(value)}.png`} />
      </div>
      
      {showTip &&
        <div className='tooltip fade bs-tooltip-bottom show sw-dc-stat__tooltip'>
          <div className='arrow sw-dc-stat__arrow' />
          <div className='tooltip-inner'>{value}</div>
        </div>
      }
    </div>
  );
}

export default DatacronStat;