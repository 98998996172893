import React, { useEffect, useState } from 'react'

import Page from '../../components/page'
import SubMenu from '../../components/submenu'
import DatacronList from './list'
import AddDatacron from './add/add.js'
import { set17Data } from './data/set17-units'
import { set18Data } from './data/set18-units'
import './_style.scss'

const menuLinks = [
  { label: 'Set 18 - No Mercy' },
  { label: 'Set 17 - Rambunctious Rage' }
]

const Datacrons = () => {
  const [activeTab, selectTab] = useState(0);
  const [list, setList] = useState([...set17Data])
  const [loadedAccount, setLoadedAccount] = useState('Default')
  const [loadedAccountString, setLoadedAccountString] = useState('')

  const setActiveTab = (id) => {
    selectTab(id)
  }

  useEffect(() => {
    document.title = 'Datacron Guide and Farming List - SWGOH-4 Life'
  })

  const intro = <div className="row">
    <div className="col-12">
      <p>The default datacron list is orderedy by priority of farming. You can remove, reorder and add your own datacrons to the list!</p>
      <p>You can even share your list with other players, that they can import to their own browser to update and manage.</p>
      <div className='sw-dc-meta mb-0'>
        <div className='sw-dc-meta__drag ml-0'>
          <svg width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
            <path d='M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0' />
          </svg>
          <div>Drag to Reorder</div>
        </div>
      </div>
    </div>
  </div>

  return (
    <Page title="Datacron Farming List" intro={intro}>
      <SubMenu links={menuLinks} active={activeTab} onClick={setActiveTab} theme='light' breakpoint={425} />
      <DatacronList
        dataZero={set18Data}
        dataOne={set17Data}
        activeTab={activeTab}
        list={list}
        loadedAccount={loadedAccount}
        setLoadedAccount={setLoadedAccount}
        loadedAccountString={loadedAccountString}
        setLoadedAccountString={setLoadedAccountString}
        setList={setList}
      />
      {activeTab === 0 && <AddDatacron activeTab={activeTab} accountString={loadedAccountString} list={list} setList={setList} />}
    </Page>
  );
}

export default Datacrons;
