const sector3 = [
  {
    title: 'Evasion Up',
    text: 'Gain Evasion Up 50 times',
    keycards: 5,
    teams: [
      {
        videoThumb: 'ncdA62rgwS0?t=72&si=xRnj0iQ_Y4-VeywA',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'hunter', name: 'Hunter', main: true },
          { image: 'bb-echo', name: 'Echo', main: true },
          { image: 'omega', name: 'Omega' },
          { image: 'wrecker', name: 'Wrecker' },
          { image: 'tech', name: 'Tech' }
        ]
      },
      {
        videoThumb: 'ncdA62rgwS0?t=117&si=e6nsp8kHlg7SMzBR',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
    ]
  },
  {
    title: 'Acolyte',
    text: 'Win 15 battles with Nightsister Acolyte surviving',
    keycards: 10,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '4r7OwYlnFQU?t=1003&si=rjPmKaGu00HfVwFc',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'canderous', name: 'Candy' },
          { image: 'jango', name: 'Jango' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: 'ncdA62rgwS0?t=228&si=TzdK8sxHf1LVDQpL',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'ncdA62rgwS0?t=165&si=IO0rQrit30QUP9wV',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
    ]
  },
  {
    title: 'Retaliate',
    text: 'Gain Retaliate 15 times',
    keycards: 10,
    teams: [
      {
        videoThumb: 'ncdA62rgwS0?t=332&si=bqLLmedqBTkdRA47',
        feats: [
          { name: 'Retaliate', qty: 2 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: 'ncdA62rgwS0?t=395&si=WvNPkYoETGYEdvjt',
        feats: [
          { name: 'Retaliate', qty: 5 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'capt-han', name: 'Cap Han' },
        ]
      },
    ]
  },
  {
    title: 'Geonosians',
    text: 'Win 15 battles with a full squad of Geonosians',
    keycards: 10,
    teams: [
      {
        videoThumb: 'ncdA62rgwS0?t=277&si=Y9XtAjtmLpp9DLiA',
        feats: [
          { name: 'Geonosians', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      }
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Admiral Raddus with Morgan surviving and Enoch in your squad',
    keycards: 7,
    teams: [
      {
        name: 'Morgan',
        videoThumb: 'His-MU1Fpmc?t=88&si=DYOV_pgVirFDOPg1',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        name: 'Low Gear',
        videoThumb: 'His-MU1Fpmc?t=218&si=73p0mSn6dXUhSlqu',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'dash', name: 'Dash' },
        ]
      },
      {
        name: 'Enoch',
        videoThumb: 'His-MU1Fpmc?t=186&si=XerhC1kVzYQg6OOF',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'Echo' },
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'wrecker', name: 'Wrecker' },
          { image: 'tech', name: 'Tech' }
        ]
      },
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Enoch with Queen Amidala surviving and without Support units',
    keycards: 11,
    teams: [
      {
        name: 'Amidala',
        videoThumb: 'His-MU1Fpmc?t=369&si=EyOkfTVKqYgAvycp',
        toons: [
          { image: 'traya', name: 'Traya' },
          { image: 'amidala', name: 'Amidala', main: true },
          { image: 'nihilus', name: 'Nihilus' },
          { image: 'dooku', name: 'Dooku' },
          { image: 'malak', name: 'Malak' }
        ]
      },
      {
        name: 'No Support',
        videoThumb: 'His-MU1Fpmc?t=543&si=CJqrWWEffdskAr4y',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'ben', name: 'Old Ben' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
    ]
  }
];

export {
  sector3
}