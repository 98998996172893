import React from 'react';
import Video from '../video';

const Section = props => {
  return (
    <div>
      {props.title && <h2 className="mb-5 mb-md-6 sw-heading">{props.title}</h2>}
      <div className="sw-feats">
        {props.recommended && <div className="sw-feats__recommended mb-4">{props.recommended}</div>}
        <div className="row">
          {props.videos.length > 0 && props.videos.map((video, i) =>
            <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
              <Video
                id={video.id} name={video.name} desc={video.desc}
                url={video.url} image={video.image}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Section;