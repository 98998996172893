const day1 = [
  {
    title: `Sector 1 - Battles`,
    teams: [
      {
        id: 101,
        name: '4x',
        videoThumb: 'VSOm6nRcj4g?t=52&si=IgOkt_v_My2N5lrT',
        feats: [
          { name: 'Tenacity Down', qty: 10 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie', main: true },
          { image: 'threepio', name: 'Threepio' },
        ]
      },
      {
        id: 102,
        name: '4x',
        videoThumb: 'VSOm6nRcj4g?t=141&si=p5cZSWDcFysehgUA',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 103,
        name: '4x',
        videoThumb: 'VSOm6nRcj4g?t=235&si=TNR_eIIVuTqJZL0D',
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [          
          { image: 'talzin', name: 'Talzin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin'},
          { image: 'asajj', name: 'Asajj' }
        ]
      },
      {
        id: 104,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=366&si=eJO1o08slFIwcxTl',
        feats: [
          { name: 'Decay', qty: 10 },
        ],
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'tfp', name: 'Tie Pilot' },
          { image: 'stormtrooper', name: 'Stormtrooper' }
        ]
      },
      {
        id: 105,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
      {
        id: 106,
        name: '2x',
        videoThumb: '',
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'shaak-ti', name: 'Shaak Ti' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
    ]
  },
  {
    title: `Sector 1 - Mini Boss`,
    boss: 'jawas',
    teams: [
      {
        id: 110,
        name: `Geonosians`,
        videoThumb: '5AeDDhXuz5A?t=113&si=s3u9vmF2kSczOBSn',
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
      {
        id: 111,
        name: `Light Side`,
        videoThumb: '5AeDDhXuz5A?t=204&si=a1Eux91EskcKSkAV',
        feats: [
          { name: 'Tenacity Down', qty: 10 },
        ],
        toons: [          
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'Arc Trooper' },
          { image: 'rex', name: 'Rex' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'fives', name: 'Fives' }
        ]
      }
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Tenacity Down', current: 50, target: 50 },
      { name: 'Morgan', current: 2, target: 10 },
      { name: 'Decay', current: 20, target: 50 },
      { name: 'Gungans', current: 16, target: 35 }
    ]
  },

  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Challenge Path', current: 50, target: 250 },      
      { name: 'Nightsisters', current: 4, target: 20 },
      { name: 'Stagger', current: 30, target: 100 },
      { name: 'Imperial Remnants', current: 2, target: 20 },
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master QGJ', current: 2, target: 15 },
    ]
  }
];

export {
  day1
}