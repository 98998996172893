import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import AddToon from './addToon';
import Toon from '../toon';
import ToonDefense from './toonDefense';
import ToonOffense from './toonOffense';
import './_style.scss';

const Zone = props => {

  const [numToons, setNumber] = useState([1, 2]);

  const onSearchChange = (value) => {
    props.onUpdate && props.onUpdate(value, props.zone);
  }

  useEffect(() => {
    let arrVal = props.zone !== 'offense' && [...Array(props.max)].map((_, i) => i)
    setNumber(arrVal);
  }, [props.max, props.zone]);

  return (
    <div className={classNames('sw-zone', `sw-zone--${props.zone}`, props.zone !== 'offense' && 'col-12 col-xl-6')}>

      <div className={classNames('sw-zone__header', `sw-zone__header--${props.zone}`)}>
        <h3 className={classNames('sw-zone__title', `sw-zone__title--${props.zone}`)}>
          {props.zone !== 'offense' &&
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className={classNames('sw-zone__title-icon', `sw-zone__title-icon--${props.zone}`)}>
              <path d="M2,10h3c0.6,0,1,0.4,1,1v3c0,0.6-0.4,1-1,1H2c-0.6,0-1-0.4-1-1v-3C1,10.4,1.4,10,2,10z M11,1h3c0.6,0,1,0.4,1,1v3 c0,0.6-0.4,1-1,1h-3c-0.6,0-1-0.4-1-1V2C10,1.4,10.4,1,11,1z M11,10c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1v-3 c0-0.6-0.4-1-1-1H11z M11,0C9.9,0,9,0.9,9,2v3c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2H11z M2,9c-1.1,0-2,0.9-2,2v3 c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2H2z M9,11c0-1.1,0.9-2,2-2h3c1.1,0,2,0.9,2,2v3c0,1.1-0.9,2-2,2h-3 c-1.1,0-2-0.9-2-2V11z M0,2c0-1.1,0.9-2,2-2h3c1.1,0,2,0.9,2,2v3c0,1.1-0.9,2-2,2H2C0.9,7,0,6.1,0,5V2z" />
            </svg>
          }
          {props.title}
        </h3>
        <AddToon
          max={props.max} toons={props.toons} onSearchChange={onSearchChange}
          zone={props.zone} options={props.options}
          addToonLabel={props.zone === 'offense' && 'Add Units'}
          addToonTheme={props.zone === 'offense' && 'secondary'}
          title={`Add ${props.title} ${props.zone !== 'offense' ? '' : 'Defense'}`}
        />
      </div>
      {props.zone === 'offense' && <div className="text-dark mt-2 d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d44444" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>
        <div className="pl-1 font-weight-bold text-small">Drag &amp; Drop offense onto defense units</div>
      </div>}

      <div className={classNames('sw-zone__toons', props.zone === 'offense' && 'sw-zone__toons--offense')}>
        {props.zone === 'offense' && props.toons && props.toons.length > 0 ? props.toons.map((t, i) =>
          <ToonOffense
            name={t.name} assigned={t.assigned} onRemove={props.onRemove}
            image={t.base_id} onDrop={props.onDrop} key={i} ggImage={t.ggImage}
          />
        )
          : props.zone !== 'offense' && numToons.map((d, i) =>
            <ToonDefense
              zone={props.zone} onRemove={props.onRemove}
              data={props.toons[i]} onBannersAdd={props.onBannersAdd} key={i}
              format={props.format}
            />
          )}
        {props.zone === 'offense' && props.toons.length === 0 ?
          <Toon />
          :
          props.zone === 'offense' && <div className="my-auto flex-shrink-0">
            <button type="button" onClick={props.clearOffense} className="btn btn-sm btn-outline-danger">Clear Offense</button>
          </div>
        }
      </div>
      {props.zone === 'offense' && props.toons && props.toons.length > 0 &&
        <div className="sw-zone__scroll">
          <span className="btn btn-light">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
            </svg>
            Drag to Scroll
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
            </svg>
          </span>
        </div>
      }
    </div>
  );
}

export default Zone;