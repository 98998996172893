const dsCm = [
    { name: 'Phase 1', desc: 'Recap of all the Phase 1 missions', id: 'CMcisKDHVKE' },
    { name: 'Phase 2', desc: 'Recap of all the Phase 2 missions', id: 'X78qjfAcu7I' },
    { name: 'Phase 3', desc: 'Recap of all the Phase 3 missions', id: '82NUjd-mufs' },
    { name: 'Phase 4', desc: 'Recap of all the Phase 4 missions', id: 'cHK1ru7McsU' },
    { name: 'Phase 4', desc: '8 Squads for full clear in Phase 4', id: 's1X1QaALnhY' }
];

const dsSm = [
    { name: 'Phase 4 - Wat SM', desc: 'Is this the best team?', id: 'iCMY5LFYlUE' },
    { name: 'Phase 4 - Wat SM + Dooku CM', desc: 'How to defeat both Wat & Dooku missions', id: 'oMgdC-lhEoo' },
    { name: 'Phase 4 - Dooku Combat Mission', desc: 'Defeat Shaak Ti or Clone variant in wave 1', id: '1H7iRxTrZEw' },
    { name: 'Phase 3 - Wat Special Mission', desc: 'Earn Wat shards with Geos', id: 'GzSdAtiUJFQ' },
    { name: 'Phase 2 - Acklay Special Mission', desc: 'Get some GET2', id: '0vEJ4wXiZo8' },
    { name: 'Phase 1 - Nute Special Mission', desc: 'Easy win with Wat', id: '5xnmQmzabcE' },
];

const dsFm = [
    { name: 'Fleet Missions Guide', desc: '4 fleets that can full clear', id: 'xI4Y_M8nqH4' },
    { name: '"Old School" Fleet Missions', desc: `With Hound's Tooth and SF Tie Fighter`, id: 'uRgV65Qx7Bs' }    
];

const lsPhases = [
    { name: 'Phase 1', desc: 'Recap of all the Phase 1 missions', id: 'qz7dvi3t8fo' },
    { name: 'Phase 2', desc: 'Recap of all the Phase 2 missions', id: '_-AStGU9gzM' },
    { name: 'Phase 3', desc: 'Recap of all the Phase 3 missions', id: 'tSFLP1pUVMg' },
    { name: 'Phase 4', desc: 'Recap of all the Phase 4 missions', id: 'p-MBsV00WC4' }
];

const lsFleet = [
    { name: 'Profundity', desc: 'All Phases vs BH & Geos', id: 'qqvKNlJ7v7U' },
    { name: 'Raddus', desc: 'All Phases vs BH, Droids & Geos', id: 'kIB2VQcby5M' },
    { name: 'P2 Fleet Special Mission', desc: 'Clear special mission with Negotiator', id: 'rbytGEAlHjc' },
    { name: 'P3 Fleet Special Mission', desc: 'Earn GET2 by defeating droid fleet', id: 'JjGAmwAOPP8' },
    { name: 'P3 Fleet Combat Mission', desc: 'Beat combat mission with Home One', id: 'MEJvFz5kEU8' },
];

const lsFactions = [
    { name: '2022 Reek KAM Mission Guide', desc: 'Bad Batch vs 501st Shaak Ti Clones', id: 'Nyx6GfepgnE' },
    { name: 'Reek Special Mission', desc: 'Earn KAM shards by defeating reek', id: '7S-MU_CXUgM' },
    { name: 'Bad Batch', desc: 'Get max waves by using Padme, Shaak Ti or Rey lead', id: 'd3K8rfMhoKE' },
    { name: 'Dash', desc: 'Get max waves in all phases with Dash Scoundrels', id: 'nT9RwyvH2S8' },
    { name: 'Commander Luke Skywalker', desc: 'Get max waves in all phases with CLS Rebels', id: 'Y8BmEfZFgzg' },
    { name: 'Mon Mothma', desc: 'Get max waves in all phases with Rebel Fighters', id: '7Bqap_adJI4' },
    { name: 'RJT + Droids', desc: 'Get max waves in all phases with RJT, Droids and Hero Finn', id: 'wblO3M4fTro' },
    { name: 'Padme', desc: 'Get max waves in phase 1, 2 & 4 with Padme + 501st or CAT', id: 'kkoDr8z8Z0s' },
    { name: 'General Skywalker', desc: 'Get max waves phase 2 & 4 with GAS + Ahsoka & GAS + 501st', id: 'pDuxPgUs1_w' },
    { name: 'Jedi Knight Luke', desc: 'Get max waves in all phases with JKL', id: '8DH8RjN12CU' },
    { name: 'Jedi Master Kenobi', desc: 'Get max waves in all phases with JMK', id: 'hVlNYy1AY8s' },
    { name: 'Jedi Master Luke', desc: 'Get max waves in all phases with JML', id: 'Ne8uf9tcBG8' },
    { name: 'Rey', desc: 'Get max waves in all phases with Rey', id: 'eciM7s_zZ-I' }
];

export {
    dsCm,
    dsSm,
    dsFm,
    lsPhases,
    lsFleet,
    lsFactions
}