import React from 'react'
import SwTypeAhead from '../../components/typeahead'
import Toon from '../../components/toon'

const UnitSearch = (props) => {
  const { list, ticker, onChange, placeholder, emptyLabel, labelKey, id, selected, isSelect, clearButton } = props

  const renderOption = (option) => (
    <div>
      <Toon
        name={`${option.name}${ticker ? ` (${ticker.filter(v => v.counter === option.value).length})` : ''}`}
        image={option.value ? option.value : true}
        ggImage={option.ggImage}
        listItem
      />
    </div>
  )

  return (
    <SwTypeAhead
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Search or select"}
      emptyLabel={emptyLabel ? emptyLabel : "No counters found"}
      options={list}
      selected={selected}
      labelKey={labelKey ? labelKey : "name"}
      id={id ? id : "selectLeader"}
      renderOption={renderOption}
      isSelect={isSelect}
      clearButton={clearButton}
    />
  );
}

export default UnitSearch