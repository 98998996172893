import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import SubMenu from '../../components/submenu';
import Section from '../../components/section';
import { lsFleet, lsPhases, lsFactions } from './data/videos';
import TbMenu from '../rote-tb/menu';

const menuLinks = [
  { label: 'Squads' },
  { label: 'Fleet' },
  { label: 'Playthrough' }
];

const GeoTb = () => {

  const [activeTab, selectTab] = useState(0);

  useEffect(() => {
    document.title = 'Geo Teritorry Battle: Light Side - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
    A library of videos to help you conquer the Light Side of Geo Territory Battle.
    </div>
  </div>;

  return (
    <Page menu={<TbMenu />} title="Geonosis TB: Light Side" intro={intro}>

      <SubMenu links={menuLinks} active={activeTab} onClick={selectTab} theme='light' />

      {activeTab === 0 && <Section title="Squad Guides" videos={lsFactions} />}
      {activeTab === 1 && <Section videos={lsFleet} title="Fleet Guides" />}
      {activeTab === 2 && <Section videos={lsPhases} title="Full Phase Playthrough" />}

    </Page>
  );
}

export default GeoTb;
