import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';

import './_style.scss';

const Menu = (props) => {

  const [active, toggleMenu] = useState(false);

  return (
    <div className="sw-menu">
      <nav className="navbar navbar-expand-lg navbar-dark bg-info">
        <NavLink className="navbar-brand" to="/">
          <img src="/images/layout/logo.png" className="sw-menu__logo" alt="SWGOH 4-LIFE" />
        </NavLink>
        <button className={classNames('navbar-toggler sw-menu__toggler', active && 'sw-menu__toggler--active')} type="button" onClick={() => toggleMenu(!active)}>
          <span className="navbar-toggler-icon" />
        </button>

        <div className={classNames('collapse navbar-collapse', active && 'show')}>
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0 flex-lg-column">
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/assault-battles/">
                <span className="sw-menu__label">Assault Battles</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/conquest/">
                <span className="sw-menu__label">Conquest</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/counters/">
                <span className="sw-menu__label">Counters</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/datacrons/">
                <span className="sw-menu__label">Datacrons</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/gac/">
                <span className="sw-menu__label">Grand Arena</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/journey-guides/">
                <span className="sw-menu__label">Journey Guide</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/omicrons/">
                <span className="sw-menu__label">Omicrons</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/proving-grounds/">
                <span className="sw-menu__label">Proving Grounds</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/raids/">
                <span className="sw-menu__label">Raids</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link sw-menu__link" to="/territory-battles/">
                <span className="sw-menu__label">Territory Battles</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Menu);