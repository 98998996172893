import React from 'react';
import OmicronsCell from './cell';

const OmicronsHeader = (props) => {
  const { sort, order, onSort, type } = props

  const offense = type === '5v5' ? '5v5_off_diff' : '3v3_off_diff'
  const defense = type === '5v5' ? '5v5_def_diff' : '3v3_def_diff'

  return (
    <div className='sw-omi-header'>
      <div className='sw-omi-header__meta'>
        Data updated on 23 July 2024
      </div>
      <div className='sw-omi-header__data'>
        <OmicronsCell isHeader onSort={() => onSort('count')} isSort={sort === 'count'} order={order} value='Total Count' />
        <OmicronsCell isHeader onSort={() => onSort('ratio')} isSort={sort === 'ratio'} order={order} value='Popularity' />
        <OmicronsCell isHeader onSort={() => onSort(offense)} isSort={sort === 'off_diff'} order={order} value='Offense Impact' />
        <OmicronsCell isHeader onSort={() => onSort(defense)} isSort={sort === 'def_diff'} order={order} value='Defense Impact' />
      </div>
    </div>
  );
}

export default OmicronsHeader;