import React from 'react';

const ConquestCountersSets = props => {

  return (
    <div className='mb-5'>      
      <h4 className="h3 mb-3 text-center d-flex justify-content-center align-items-center">
        <img src="/images/conquest/data-disk.png" alt="" className='d-block' />
        <span className='pt-1 pl-1'>{props.set.name} Data Disks</span>
      </h4>
      <div className="sw-conquest-sets">
        {props.set.teams && props.set.teams.map((d, i) =>
          d.name && <span className='badge badge-info rounded-pill px-3 py-2 m-1' key={i}>{d.name}</span>
        )}
      </div>
    </div>
  );
}

export default ConquestCountersSets;