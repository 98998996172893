import React from 'react';
import './_style.scss';

const Video = props => {
  return (
    <div className="sw-video">
      <a 
        href={props.id ? `https://youtu.be/${props.id}` : props.url} target="_blank" 
        className="sw-video__link" rel="noopener noreferrer"
      >
        <div className="sw-video__mask sw-video-mask">
          <img src={props.id ? `https://i.ytimg.com/vi/${props.id}/maxresdefault.jpg` : `/images/thumbs/${props.image}.jpg`} alt="" className="sw-video__image" />
        </div>
        <div className="sw-video__name">{props.name}</div>
        <div className="sw-video__desc">{props.desc}</div>
      </a>
    </div>
  );
}

export default Video;