import React, { useEffect } from 'react';

import Page from '../../components/page';
import Thumb from '../../components/thumb';

const Home = () => {

  useEffect(() => {
    document.title = 'SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      <p>Welcome to my Star Wars Galaxy of Heroes video guide hub.</p>
      <p>For any feedback or ideas please join my Discord Server. If you find this tool useful and would like to support me, any help via Patreon is highly appreciated. This will help me with keeping this tool running and growing for years to come!</p>
      <p className="mb-0">Also, why not subscribe to my YouTube channel so you can get notified whenever I upload a new video.</p>
    </div>
  </div>;

  return (
    <Page title="Welcome to SWGOH 4-Life" intro={intro} showSocial>
      
    <div className="sw-feats">
      <h2 className="h1 mb-5 mb-md-6 sw-heading">Conquest</h2>
      <div className="row">
        <div className="col-12 col-sm-10 col-md-6 pb-5">
          <Thumb
            to="/conquest/" name="Video Guides" image="conquest-guides"
            desc="Your first stop to conquering the Conquest"            
          />
        </div>
        <div className="col-12 col-sm-10 col-md-6 pb-5">
          <Thumb
            to="/conquest/plan/" name="Daily Plan" image="conquest-plan"
            desc="Increase your efficency by following the Conquest Daily Plan"            
          />
        </div>
      </div>
    </div>
      
      <div className="sw-feats">
        <h2 className="h1 mb-5 mb-md-6 sw-heading">Grand Arena</h2>
        <div className="row">
          <div className="col-12 col-sm-10 col-md-6 pb-5">
            <Thumb
              to="/counters/5v5/" name="Counters" image="gac"
              desc="5v5, 3v3 and Ships"            
            />
          </div>
          <div className="col-12 col-sm-10 col-md-6 pb-5">
            <Thumb
              to="/gac/" name="Attack Phase Planner" image="gac-plan"
              desc="Planning tool to help you organise your attack"            
            />
          </div>
        </div>
      </div>
      
      <div className="sw-feats">
        <h2 className="h1 mb-5 mb-md-6 sw-heading">Guild Events</h2>
        <div className="row">
          <div className="col-12 col-sm-10 col-md-6 pb-5">
            <Thumb
              to="/territory-battles/" name="Territory Battles" image="tb"
              desc="ROTE and Geo LS + DS"            
            />
          </div>
          <div className="col-12 col-sm-10 col-md-6 pb-5">
            <Thumb
              to="/raids/" name="Raids" image="raids"
              desc="Challenge Rancor and Heroic Sith"            
            />
          </div>
        </div>
      </div>
      
      <div className="sw-feats">
        <h2 className="h1 mb-5 mb-md-6 sw-heading">Player Events</h2>
        <div className="row">
          <div className="col-12 col-sm-10 col-md-6 pb-5">
            <Thumb
              to="/assault-battles/" name="Assault Battles" image="abs"
              desc="Challenge Tier 3 - 2 - 1"            
            />
          </div>
          <div className="col-12 col-sm-10 col-md-6 pb-5">
            <Thumb
              to="/journey-guides/" name="Journey Guide" image="gls"
              desc="Galactic Legends and Solo Journeys"            
            />
          </div>
        </div>
      </div>

    </Page>
  );
}

export default Home;
