export const set17Bonus = [
  {
    id: 'ds-hp-resist',
    label: 'Recover HP on Resist',
    full: `Whenever Dark Side allies resist a debuff they recover 2% Health and Protection.`
  },
  {
    id: 'ds-buff-special',
    label: 'Gain Buffs on Special',
    full: `Whenever Dark Side allies use a Special ability, they gain Evasion Up, Health Steal Up, and Health Up for 2 turns.`
  },
  {
    id: 'ds-dispel',
    label: 'Dispel Debuffs',
    full: `At the beginning of their turn, Dark Side allies have +25% chance to dispel all Debuffs on themself.`
  },
  {
    id: 'ds-evasion',
    label: 'Accuracy and Evasion',
    full: `Whenever Dark Side allies are evaded by an enemy, that ally gains Accuracy Up for 2 turns, and they inflict Evasion Down for 2 turns on that enemy, which can't be evaded.`
  },
  {
    id: 'ds-tm-defeat',
    label: 'TM on Defeat',
    full: `Dark Side allies gain +10% Turn Meter whenever an ally or enemy is defeated.`
  },
  {
    id: 'ls-speed',
    label: 'Speed Up',
    full: `Whenever Light Side allies use a Basic ability, they gain Speed Up for 2 turns.`
  },
  {
    id: 'ls-revive',
    label: 'Tank Revive',
    full: `The first time a Light Side Tank ally is defeated, revive them with 100% Health, reset their cooldowns, and they gain a bonus turn. Limit once per battle.`
  },
  {
    id: 'ls-special-tm',
    label: 'Gain TM on Special',
    full: `Whenever Light Side allies use a Special ability they gain 15% Turn Meter.`
  },
  {
    id: 'ls-hp-buff',
    label: 'Recover HP on Buff',
    full: `Whenever Light Side allies gain a buff, they recover 2% Health and Protection.`
  },
  {
    id: 'ls-on-defet',
    label: 'Speed + Offense on Defeat',
    full: `The first time each Light Side ally is defeated, remaining Light Side allies gain 15% Offense and 20 Speed (stacking) for the rest of the encounter.`
  },
  {
    id: 'rogueone-stats',
    parent: 'ls',
    label: 'Stat Boost',
    full: `At the start of battle all Rogue One allies gain 100% Max Health and Max Protection, 60% Offense, and 20 Speed, and if Jyn Erso is an ally, she assists whenever another Rogue One ally uses an ability. If all allies were Rogue One at the start of battle, whenever an enemy attacks out of turn, Expose them for 1 turn, which can't be evaded or resisted, and all Rogue One allies gain Speed Up for 1 turn and recover 30% Health and Protection.`
  },
  {
    id: 'rogueone-revive',
    parent: 'ls',
    label: 'Bonus on Revive',
    full: `Whenever Rogue One allies are revived, they recover 25% Protection, gain 25% Turn Meter, and reduce their cooldowns by 1.`
  },
  {
    id: 'rogueone-reduction',
    parent: 'ls',
    label: 'Damage Reduction',
    full: `At the start of each turn, if non-Galactic Legend Rogue One allies have 50% or more Turn Meter, damage they receive is reduced by 75% until the end of the turn.`
  },
  {
    id: 'rogueone-bonus-turn',
    parent: 'ls',
    label: 'Bonus Turn',
    full: `Whenever a Rogue One ally defeats an enemy, they take a bonus turn.`
  },
  {
    id: 'rogueone-blind',
    parent: 'ls',
    label: 'Blind',
    full: `Whenever a Rogue One ally deals damage to an enemy and no other enemy is Blinded, inflict Blind for 2 turns on the target enemy, which can't be resisted.`
  },
  {
    id: 'nightsister-max-protection',
    parent: 'ds',
    label: 'Reduce Max Protection',
    full: `Whenever an enemy is inflicted with Defense Down, reduce their Max Protection by 5% (stacking, max 50%). Whenever a Nightsister ally gains Foresight or Stealth, all Nightsister allies gain 5% Turn Meter and Critical Damage (stacking) until the end of encounter. Whenever an enemy attacks out of turn, all Nightsister allies recover 40% Health and Protection, gain Retribution for 1 turn, and 15% Turn Meter.`
  },
  {
    id: 'nightsister-bonus-damage',
    parent: 'ds',
    label: 'Bonus Damage',
    full: `Whenever Nightsister allies inflict a debuff on an enemy during their turn, they deal bonus damage equal to 10% of that enemy's Max Health.`
  },
  {
    id: 'nightsister-offense',
    parent: 'ds',
    label: 'Stacking Offense',
    full: `Whenever Nightsister allies critically hit an enemy during their turn, that ally gains 10% Offense (stacking) for 2 turns.`
  },
  {
    id: 'nightsister-reduction',
    parent: 'ds',
    label: 'Damage Reduction',
    full: `At the start of each turn, if non-Galactic Legend Nightsister allies have 50% or more Turn Meter, damage they receive is reduced by 75% until the end of the turn.`
  },
  {
    id: 'nightsister-riposte',
    parent: 'ds',
    label: 'Riposte',
    full: `Whenever Nightsister allies use a Special Ability on their turn, they gain Riposte for 2 turns.`
  },
  {
    id: 'imperialremnant-alert',
    parent: 'ds',
    label: 'Alert',
    full: `Whenever an allied Imperial Remnant uses a Special ability, they gain Alert for 2 turns. Whenever an enemy is inflicted with a stack of Blight, all Imperial Remnant allies gain 10% Turn Meter, and 5% Critical Chance and Critical Damage (stacking) until the end of encounter.`
  },
  {
    id: 'imperialremnant-bonus-damage',
    parent: 'ds',
    label: 'Bonus Damage',
    full: `Whenever Imperial Remnant allies Stagger or Stun on an enemy, they deal bonus damage equal to 35% of that enemy's Max Health, which can't be evaded.`
  },
  {
    id: 'imperialremnant-crit-avoid',
    parent: 'ds',
    label: 'Critical Avoidance',
    full: `Whenever a Imperial Remnant ally is critically hit, they gain 100% Critical Avoidance and Critical Chance for 2 turns.`
  },
  {
    id: 'imperialremnant-offense',
    parent: 'ds',
    label: 'Stacking Offense',
    full: `Whenever a debuff on an enemy expires, Imperial Remnant allies gain 2% Offense, doubled if that debuff was dispelled, until the end of battle.`
  },
  {
    id: 'imperialremnant-bonus-turn',
    parent: 'ds',
    label: 'Bonus Turn',
    full: `Whenever a Imperial Remnant ally falls below 100% Health, they gain a bonus turn. Limit once per character per battle.`
  },
  {
    id: 'jedi-retaliate',
    parent: 'ls',
    label: 'Retaliate',
    full: `At the start of the encounter and at the end of their turn, Jedi allies gain 1 stack of Retaliate (max 3) for 3 turns, which can't be copied, dispelled, or prevented.`
  },
  {
    id: 'jedi-dispel',
    parent: 'ls',
    label: 'Dispel Debuffs',
    full: `Whenever Jedi allies start their turn with at least 2 debuffs that can be dispelled, they have a 65% chance to dispel all debuffs on themselves.`
  },
  {
    id: 'jedi-buffs-stun',
    parent: 'ls',
    label: 'Buffs on Stun',
    full: `Whenever Jedi allies Stun an enemy, they gain Speed Up and Offense Up for 2 turns and inflict Expose for 2 turns at the end of that turn, which can't be evaded.`
  },
  {
    id: 'jedi-stun-special',
    parent: 'ls',
    label: 'Stun on Special',
    full: `Whenever Jedi allies use a Special ability during their turn, they Stun target enemy for 1 turn, which can't be evaded or resisted.`
  },
  {
    id: 'jedi-tm-buff',
    parent: 'ls',
    label: 'TM on Buff',
    full: `Whenever Jedi allies grant a buff to another ally, that ally gains 10% Turn Meter (limit once per turn per ally).`
  },
  {
    id: 'unit-asajj',
    parent: 'nightsister',
    label: 'Asajj Ventress',
    full: `Whenever an enemy is Stunned, Asajj Ventress takes a bonus turn.`
  },
  {
    id: 'unit-enoch',
    parent: 'imperialremnant',
    label: 'Captain Enoch',
    full: `At the start of battle and if Captain Enoch is in the Leader slot, all other Imperial Remnant allies are immune to critical hits until he is defeated, and he gains 50% Max Health and Protection. Whenever Captain Enoch is critically hit, Tank allies Taunt for 1 turn.`
  },
  {
    id: 'unit-cassian',
    parent: 'rogueone',
    label: 'Cassian Andor',
    full: `Cassian Andor has +5% Max Health and Max Protection per Relic Amplifier level, and damage they receive is decreased by 15%.`
  },
  {
    id: 'unit-dark-trooper',
    parent: 'imperialremnant',
    label: 'Dark Trooper',
    full: `Whenever Dark Trooper critically hits an enemy, Dark Trooper gains 10% Critical Damage, Defense Penetration, and Offense (stacking, max 200%) until the end of battle.`
  },
  {
    id: 'unit-peridea',
    parent: 'imperialremnant',
    label: 'Death Trooper (Peridea)',
    full: `Whenever Death Trooper (Peridea) critically hits an enemy, Death Trooper (Peridea) gains Damage Immunity for 1 turn, which can't be copied, 25% Turn Meter, and Blinds that enemy for 1 turn, which can't be evaded or resisted. If the enemy was Target Locked, Death Trooper (Peridea) also gains Protection Up (100%, stacking), Retribution, and Speed Up for 2 turns.`
  },
  {
    id: 'unit-eeth',
    parent: 'jedi',
    label: 'Eeth Koth',
    full: `At the start of battle, Galactic Republic Jedi allies gain 100% Offense and 20 Speed, and are immune to Buff Immunity. Eeth gains 100% Max Health and Protection. If the Leader is a Galactic Republic Jedi, whenever they take damage, Eeth Taunts and gains Foresight until he evades. Whenever Eeth evades an attack, he gains 10% Turn Meter and all Galactic Republic Jedi allies recover 20% Health and Protection. Whenever Eeth uses a Special ability, he gains 10% Turn Meter, Blinds all enemies for 2 turns, which can't be evaded, and all Galactic Republic Jedi allies recover 20% Health and Protection and gain Protection Up (50%) for 2 turns. Whenever Eeth uses a Basic ability, Daze target enemy for 2 turns, which can't be evaded. Whenever a Galactic Republic Jedi ally gains Foresight, all Galactic Republic Jedi allies gain 20% Turn Meter and recover 20% Health and Protection.`
  },
  {
    id: 'unit-k2',
    parent: 'rogueone',
    label: 'K-2SO',
    full: `Whenever K-2SO loses Taunt, remove 50% Turn Meter from all enemies.`
  },
  {
    id: 'unit-luthen',
    parent: 'rogueone',
    label: 'Luthen Rael',
    full: `Whenever Luthen summons a Rebel Trooper: - Axis Bonus: Whenever an ally takes a turn, enemies lose a stack of Explosive Trap. Whenever Explosive Trap detonates, inflict all enemies with Protection Disruption for 2 turns, which can't be copied, dispelled, or prevented. - Curator Bonus: Whenever a Rebel Trooper ally is summoned, all Rebel Figher allies gain Riposte until the Rebel Trooper has been defeated. Whenever an ally attacks out of turn, they deal 35% more damage.`
  },
  {
    id: 'unit-master-qgj',
    parent: 'jedi',
    label: 'Master Qui-Gon',
    full: `Whenever Master Qui-Gon attacks out turn, they gain a stack of Heal Over Time and Protection Over Time for 2 turns and they inflict target enemy with Protection Disruption for 1 turn.`
  },
  {
    id: 'unit-gideon',
    parent: 'imperialremnant',
    label: 'Moff Gideon',
    full: `Whenever Moff Gideon is inflicted with a debuff or gains a buff, he gains a stack of Insight and Stealths for 1 turn. Whenever he gains 4 stacks of Insight, he gains 100% Turn Meter. Whenever an Imperial Remnant ally is inflicted with a debuff, they recover 10% Health and Protection. Whenever an enemy is inflicted with Armor Shred, Blind all enemies for 1 turn, which can't be evaded or resisted. Whenever he uses Subversive Volley, he deals bonus Massive Damage to all enemies, which can't be evaded.`
  },
  {
    id: 'unit-morgan',
    parent: 'nightsister',
    label: 'Morgan Elsbeth',
    full: `Whenever another ally uses an ability on their turn, Morgan Elsbeth assists (limit once per turn) dealing 50% more damage.`
  },
  {
    id: 'unit-spirit',
    parent: 'nightsister',
    label: 'Nightsister Spirit',
    full: `Whenever Nightsister Spirit gains Foresight, they also gain +20% Turn Meter and Protection Up (20%).`
  },
  {
    id: 'unit-zombie',
    parent: 'nightsister',
    label: 'Nightsister Zombie',
    full: `Whenever Nightsister Zombie Taunts, she gains 10% Defense (stacking) until the end of encounter, and all Nightsister allies gain Offense Up and Tenacity Up for 2 turns. Whenever Nightsister Zombie is defeated, inflict all enemies with Healing Immunity and Buff Immunity for 2 turns, which can't be evaded.`
  },
  {
    id: 'unit-pao',
    parent: 'rogueone',
    label: 'Pao',
    full: `At the start of battle, all allies gain 100% Max Health and Max Protection and are immune to Healing Immunity. Whenever Pao assists, he deals bonus massive damage, which can't be evaded, and all allies recover 10% Health. Whenever Pao uses Special ability, he Shocks all enemies for 2 turns, which can't be evaded. Whenever Pao uses a Basic ability on his turn, he Stealths and gains Defense Penetration Up for 1 turn and inflicts target enemy with Offense Down for 1 turn, which can't be evaded.`
  },
  {
    id: 'unit-plo',
    parent: 'jedi',
    label: 'Plo Koon',
    full: `At the start of Battle, Plo gains 100% Defense and Max Health and Protection. Whenever Plo Koon uses his Basic ability, he deals 25% bonus True damage based on his Max Health, which can't be evaded. Whenever another ally uses an ability while buffed on their turn, Plo assists. Whenever Plo uses a Special ability, Stun all enemies for 1 turn, which can't be evaded. Whenever Plo gains Defense Up, he also gains 5% Max Health (stacking) until the end of encounter. Whenever Defense Up expires, he Taunts and gains Damage Immunity for 1 turn and inflicts Ability Block to all enemies for 2 turns, which can't be evaded.`
  },
  {
    id: 'unit-shaak-ti',
    parent: 'jedi',
    label: 'Shaak Ti',
    full: `Whenever Shaak Ti attacks out of turn, they recover 20% Health and Protection and all other allies with Retribution assist.`
  },
  {
    id: 'unit-stormtrooper',
    parent: 'imperialremnant',
    label: 'Stormtrooper',
    full: `Whenever Stormtrooper loses Taunt, remove 50% Turn Meter from all enemies.`
  },
]