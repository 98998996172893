import React, { useState, useEffect } from 'react';

import Page from '../../components/page';
import ConquestMenu from './menu';
import { enemies } from './data/enemies';
import ConquestEnemy from '../../components/conquest/enemy';
import ConquestCounters from '../../components/conquest/counters';
import SwTypeAhead from '../../components/typeahead';
import { list } from './data/enemies';
import Toon from '../../components/toon';

const intro = <div><p>Below you can select or search for enemy leader to find speed range for enemies as well as suggested counters.</p><p>The speed ranges are rough guidelines only as we have no clear indicator how speeds are manipulated. In the beginning of the sector teams will start in the lower part of the range and progress towards the top end of the range in the second half of each sector.</p><p>Suggested counters are grouped by data disk sets.</p></div>;

const renderOption = (option) => (
  <div>
    <Toon name={option.name} image={option.value} listItem />
  </div>
);

const Search = props => <div className="row">
  <div className="col-12 mb-5">
    <SwTypeAhead
      onChange={props.selectLeader} placeholder="Select the enemy leader"
      emptyLabel="No counters found"
      options={list} labelKey="name" id="selectLeader"
      renderOption={renderOption}
    />
  </div>
</div>;

const ConquestEnemies = (props) => {
  const [leaderName, selectName] = useState(null);

  useEffect(() => {
    const leaderName = props.match.params.id && enemies.find(e => e.image === props.match.params.id);
    selectName(leaderName);
    document.title = `${props.match.params.id ? leaderName && leaderName.title : 'Enemy'} Conquest Speed and Counter Guide - SWGOH-4 Life`;
  }, [props.match.params.id]);

  const findLeader = (v) => {
    v && props.history.push(`/conquest/enemies/${v[0].value}/`);
  }

  return (
    <Page
      prepend={leaderName && <Search selectLeader={findLeader} />}
      append={!leaderName && <Search selectLeader={findLeader} />}
      title={leaderName ? leaderName.title : 'Conquest Enemies'}
      intro={leaderName ?
        <ConquestEnemy
          modifiers={leaderName.modifiers} toons={leaderName.toons}
          min={leaderName.min} max={leaderName.max}
          prepared={leaderName.prepared}
        />
        : intro
      }
      menu={<ConquestMenu />}
    >
      {leaderName &&
        <ConquestCounters
          toons={leaderName.teams ? leaderName.teams : leaderName.counters}
          hasVideo={leaderName.teams} title={leaderName.title}
        />
      }
    </Page>
  );
}

export default ConquestEnemies;
